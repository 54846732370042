import React, { Component } from "react";
import { Link } from "react-router-dom";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";
import Themes from "../../../Assets/Styles/Themes";
import { FlexVerticalCenter, FlexCenter } from "../../../Assets/Styles/Style";
import Success from "../../../Assets/Images/icon-submit-success.svg";

class AllDone extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Content>
          <div>
            <img src={Success} alt="allDone-CheckMark" />
          </div>
          <h1>All Done!</h1>
          <h4>We will contact you shortly. </h4>
        </Content>
      </div>
    );
  }
}

const Content = styled.div`
  margin-top: 20px;
  text-align: center;
  img {
    width: 121px;
    height: auto;
  }
  ${FlexVerticalCenter};
  h1 {
    font-weight: ${Themes.fontWeight.bold};
    margin: 8px auto;
  }
`;
export default AllDone;
