import ServiceBase from "./ServiceBase";
import APIState from "../APIState";

/**
 * KnowledgeBase Service for the Application.
 * */
export default class KnowledgeBaseService extends ServiceBase {
  /**
   * Creates the KnowledgeBase Service
   *
   * @param {APIState} state State Object
   */
  constructor(state) {
    super(state);
  }

  /**
   * Gets the Filters in the KnowledgeBase.
   */
  async GetFilters() {
    const req = this.CreateRequest(`/knowledgebase/filters`);
    const response = await this.Send(req);
    return response.json();
  }

  /**
   * Gets the Entries in the KnowledgeBase.
   */
  async GetEntries(filters = {}, page = 0, pageSize = 15, isAdmin = false) {
    filters["page"] = page;
    filters["size"] = pageSize;
    const options = { queries: filters };

    if (isAdmin) {
      options["admin"] = "true";
    }

    const req = this.CreateRequest(`/knowledgebase`, options);
    const response = await this.Send(req);
    return response.json();
  }

  /**
   * Get an Entry by ID.
   *
   * @param {string} id
   * @param {bool} isAdmin Is the Request for Admin? If so, ignore Show Filtering.
   */
  async GetEntry(id, isAdmin = false) {
    const req = this.CreateRequest(
      `/knowledgebase/${id}`,
      isAdmin ? { queries: { admin: "true" } } : {}
    );
    const response = await this.Send(req);
    return response.json();
  }

  /**
   * Get the Thumbnail Url for an Entry.
   * @param {string} id Entry Id.
   */
  GetThumbUrl(id) {
    return `/api/knowledgebase/${id}/thumb`;
  }

  /**
   * Get the Thumbnail Url for an Entry.
   * @param {string} id Entry Id.
   */
  GetAttachmentUrl(id) {
    return `/api/knowledgebase/${id}/attachment`;
  }

  IsAttachmentType(type) {
    switch (type) {
      case "ebook":
      case "infographic":
      case "template":
        return true;

      default:
        return false;
    }
  }
}
