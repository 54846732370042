import React, { Component } from "react";
import Slider from "react-slick";
import Themes from "../../../../Assets/Styles/Themes";
import styled from "styled-components";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

/** Component */
import SliderCard from "./sliderCard";
/** Images */
import NextArrowImg from "../../../../Assets/Images/arrow-right.svg";
import PrevArrowImg from "../../../../Assets/Images/arrow-left.svg";
/** Slider Custom CSS */
import "./index.css";
/** Data */
import testimonials from "./sliderData.js";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowNext src={NextArrowImg} onClick={onClick} className={className} />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowPrev src={PrevArrowImg} onClick={onClick} className={className} />
  );
}

const ArrowNext = styled.img`
  display: block;
  width: 20px;
  height: auto;
  right: -35px;
`;
const ArrowPrev = styled.img`
  display: block;
  width: 20px;
  height: auto;
  left: -35px;
`;

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }
      ]
    };
    function SliderList(props) {
      const sliderlistNames = props.sliderlistName;
      const sliderCards = sliderlistNames.map((sliderlist, i) => (
        <SliderCard
          key={i}
          profile={sliderlist.image}
          name={sliderlist.name}
          companyName={sliderlist.companyName}
          text={sliderlist.text}
        />
      ));
      return <Slider {...settings}>{sliderCards}</Slider>;
    }

    return (
      <VisibilitySensor>
        <SliderList sliderlistName={testimonials} />
      </VisibilitySensor>
    );
  }
}

export default TestimonialSlider;
