import React, {Component} from "react";
import {Link} from "react-router-dom";
import Fade from "react-reveal/Fade";
/** Style **/
import styled, {ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  BigShadow,
  FlexCenter,
  FlexHorizontalCenter,
  ButtonStyle
} from "../../Assets/Styles/Style";
/** Components **/
import BigBlueCtaButton from "../../Components/Button/BigBlueCtaButton";
import BigGreenCtaButton from "../../Components/Button/BigGreenCtaButton";

/** Images **/
import Logo from "../../Assets/Images/logo-white.svg";
import BgSmall from "../../Assets/Images/home-hero-mobile.jpg";
import BgBig from "../../Assets/Images/home-hero.jpg";
import Icon01 from "../../Assets/Images/membership.svg";
import Icon02 from "../../Assets/Images/outsourcing.svg";
import Icon03 from "../../Assets/Images/HR.svg";

/* Modals*/
import FinancialReliefModel from "../../Components/Modals/financialReliefModal";

class Section01 extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false
    };
  }
  handleClose() {
    this.setState({show: false});
  }

  handleShow() {
    this.setState({show: true});
  }
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <FinancialReliefModel close={this.handleClose} show={this.state.show} />
          <Fade>
            <Container>
              <TitleDiv>
                <LogoAndTitle>
                  <LogoDiv src={Logo} />
                  <Title>
                    HR Sense has been working with
                    <br />
                    business like yours,
                    <br />
                    solving problems like yours
                  </Title>
                </LogoAndTitle>
                <Link to="/healthcheck">
                  <BigBlueCtaButton text="Business Health Check" />
                </Link>
                <div onClick={this.handleShow}>
                  <BigGreenCtaButton text="Business Financial Relief" />
                </div>
              </TitleDiv>
            </Container>
          </Fade>
          <CardContainer>
            <Fade bottom>
              <Card>
                <Icon src={Icon01} />
                <SubTitle>Memberships</SubTitle>
                <TextDiv>
                  <Text>
                    12 Month Membership – Monthly Payments – 24/7 HR Support – over
                    200 downloadable policy’s, Templates and Forms
                  </Text>
                </TextDiv>
                <LearnMoreBtn onClick={this.props.click}>Learn More ></LearnMoreBtn>
              </Card>
              <Card>
                <Icon src={Icon02} />
                <SubTitle>Outsourcing</SubTitle>
                <TextDiv>
                  <Text>
                    Outsource your non core activities – Customised Investment
                    Options – Peace of Mind Guaranteed
                  </Text>
                </TextDiv>
                <LearnMoreBtn onClick={this.props.click}>Learn More ></LearnMoreBtn>
              </Card>
              <Card>
                <Icon src={Icon03} />
                <SubTitle>HR Consulting</SubTitle>
                <TextDiv>
                  <Text>
                    Outsource your HR Project Based Work or as you need to for adhoc
                    work
                  </Text>
                </TextDiv>
                <LearnMoreBtn onClick={this.props.click}>Learn More ></LearnMoreBtn>
              </Card>
            </Fade>
          </CardContainer>
        </div>
      </ThemeProvider>
    );
  }
}

const Container = styled.div`
    margin:0;
    background-image:url('${BgSmall}');
    height:90vh;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
    position: relative;
    ${FlexCenter};
      @media ${Themes.breakpoints.sm} {
        background: url('${BgBig}');
        background-position:center top;
        background-repeat:no-repeat;
        background-size:cover;
        height:70vh;
    }
`;
const TitleDiv = styled.div`
  margin: 0 8%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${Themes.breakpoints.md} {
    transform: translateY(-15%);
  }
`;
const LogoAndTitle = styled.div`
  margin-bottom: 12px;
  @media ${Themes.breakpoints.md} {
    ${FlexHorizontalCenter};
  }
`;
const LogoDiv = styled.img`
  display: block;
  width: 190px;
  height: auto;
  margin: 0 auto 11px auto;
  @media ${Themes.breakpoints.md} {
    margin-right: 30px;
  }
  @media ${Themes.breakpoints.xl} {
    width: 280px;
  }
`;
const Title = styled.div`
  color: white;
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.semiBold};
  text-align: center;
  @media ${Themes.breakpoints.sm} {
    font-size: ${Themes.fontsize.h3};
  }
  @media ${Themes.breakpoints.md} {
    text-align: left;
  }
  @media ${Themes.breakpoints.xl} {
    font-size: ${Themes.fontsize.h2};
  }
`;
const CardContainer = styled.div`
  margin: 17px auto;
  @media ${Themes.breakpoints.sm} {
    margin: 0 auto !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    transform: translateY(-30%);
    max-width: 720px;
  }
  @media ${Themes.breakpoints.md} {
    transform: translateY(-50%);
    max-width: 1000px;
  }
  @media ${Themes.breakpoints.lg} {
    max-width: 1100px;
  }
  @media ${Themes.breakpoints.xl} {
    max-width: 1366px;
  }
`;
const Card = styled.div`
  ${BigShadow};
  ${FlexCenter};
  text-align: center;
  flex-direction: column;
  margin: 0 5% 12px 5%;
  ${FlexCenter};
  padding: 10px;
  background-color: white;
  @media ${Themes.breakpoints.sm} {
    margin: 0;
    padding: 0;
    height: 270px;
  }
  @media ${Themes.breakpoints.xl} {
    height: 280px;
  }
`;
const Icon = styled.img`
  width: 45px;
  display: inline-block;
  margin: 0 auto;
  @media ${Themes.breakpoints.md} {
    width: 62px;
  }
`;
const SubTitle = styled.div`
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.blue};
  @media ${Themes.breakpoints.md} {
    font-size: ${Themes.fontsize.h4};
  }
`;
const TextDiv = styled.div`
  margin: 9px 3% 13px 3%;
  max-width: 450px;
  @media ${Themes.breakpoints.md} {
    margin: 9px 7% 13px 7%;
    ${FlexCenter};
    height: 27%;
  }
`;
const Text = styled.p`
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.regular};
  color: ${Themes.colors.grey};
  line-height: 20px;
  margin-bottom: 0;
  @media ${Themes.breakpoints.xl} {
    font-size: ${Themes.fontsize.p2};
    line-height: 25px;
  }
`;
const LearnMoreBtn = styled.a`
  ${ButtonStyle};
  background: linear-gradient(${Themes.colors.lightBlue}, ${Themes.colors.blue});
  width: 150px;
  height: 35px;
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.regular};
  margin: 0 auto;
  padding-top: 6px;
  padding-bottom: 6px;
`;
export default Section01;
