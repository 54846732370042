import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  ContainerStyle,
  FlexCenter,
  TitleStyle,
  Paragraph,
  ButtonStyle,
  Line,
  PricingTableCommonStyle,
  ShortLine,
  PricingLearnMore,
  FlexVerticalCenter,
  FlexHorizontalCenter
} from "../../Assets/Styles/Style";
/** Image **/
import Padlock from "../../Assets/Images/padlock.svg";
import Checkmark from "../../Assets/Images/checkmark.svg";
import Basic from "../../Assets/Images/basic.svg";
import Essential from "../../Assets/Images/essential.svg";
import Works from "../../Assets/Images/works.svg";
import Out from "../../Assets/Images/outsourcing-white.svg";
import HR from "../../Assets/Images/HR-white.svg";
/** Modals */
import ScrollingLink from "../../Components/ScrollingLink";
import App from "../../App";

class Section03 extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Container>
            <ScrollTarget />
            <Fade>
              <TitleDiv>
                <h1>How Can We Provide?</h1>
                <Line />
                <Description>
                  You decide on how we can support you best. From project to
                  hourly based support models, through to annual HR support
                  subscriptions. Whichever method you choose we have the best
                  approach to providing your HR solutions no matter the type of
                  challenge or size of your business.
                </Description>
              </TitleDiv>
            </Fade>
            <Fade bottom cascade>
              <PricingContainer>
                {/* 1. first div start */}
                <FreeDiv>
                  <FreeInnerDiv>
                    <div>
                      <FreeTitle>FREE</FreeTitle>
                      <BlueShortLine />
                    </div>
                    <FreeDivBody>
                      <div>
                        <FreeIcon src={Padlock} />
                        <FreeSubTitle>Sign up now to unlock:</FreeSubTitle>
                      </div>
                      <FreeList>
                        <FreeListItem>
                          <FreeListCheck src={Checkmark} />
                          <FreeListText>
                            Access to the <span>Knowledge base</span>
                          </FreeListText>
                        </FreeListItem>
                        <FreeListItem>
                          <FreeListCheck src={Checkmark} />
                          <FreeListText>
                            <span>Front of line</span> support queue
                          </FreeListText>
                        </FreeListItem>
                        <FreeListItem>
                          <FreeListCheck src={Checkmark} />
                          <FreeListText>
                            Industry specific <span>News Letters</span>
                          </FreeListText>
                        </FreeListItem>
                      </FreeList>
                    </FreeDivBody>
                    <FreeButton onClick={() => App.Current.RequestSignUp()}>Sign Up</FreeButton>
                  </FreeInnerDiv>
                </FreeDiv>
                {/* 1. first div end */}
                {/* 2. second div start */}
                <MembershipDiv>
                  <div>
                    <h3>Membership</h3>
                    <ShortLine />
                  </div>
                  <MembershipList>
                    <MembershipListItem>
                      <MembershipIcon src={Basic} />
                      <h5>The Basic</h5>
                    </MembershipListItem>
                    <MembershipListItem>
                      <MembershipIcon src={Essential} />
                      <h5>The Essentials</h5>
                    </MembershipListItem>
                    <MembershipListItem>
                      <MembershipIcon src={Works} />
                      <h5>The Works</h5>
                    </MembershipListItem>
                  </MembershipList>
                  <ScrollingLink to="/ourservices"> 
                    <PricingLearnMore>Learn More ></PricingLearnMore>
                  </ScrollingLink>
                </MembershipDiv>
                {/* 2. second div end */}
                {/* 3. third div start */}
                <RowGrid>
                  <OutsourcingDiv>
                    <OutsourcingAndHRDivTitleDiv>
                      <img src={Out} />
                      <div>
                        <h4>Outsourcing</h4>
                        <p>HR Project Based</p>
                      </div>
                    </OutsourcingAndHRDivTitleDiv>
                    <ShortLine />
                    <OutsourcingAndHRDivTitleText>
                      Wording as per your email on{" "}
                      <span>Fixed Project Costing</span>
                    </OutsourcingAndHRDivTitleText>
                    <Link to="/ourservices#outsourcing">
                      <PricingLearnMore>Learn More ></PricingLearnMore>
                    </Link>
                  </OutsourcingDiv>
                  <HRDiv>
                    <OutsourcingAndHRDivTitleDiv>
                      <img src={HR} />
                      <div>
                        <h4>HR Consulting</h4>
                        <p>Hourly Hire</p>
                      </div>
                    </OutsourcingAndHRDivTitleDiv>
                    <ShortLine />
                    <OutsourcingAndHRDivTitleText>
                      We are available to support you on any HR Initiatives by
                      the hour
                    </OutsourcingAndHRDivTitleText>
                    <Link to="/ourservices#hr">
                      <PricingLearnMore>Learn More ></PricingLearnMore>
                    </Link>
                  </HRDiv>
                </RowGrid>
                {/* 3. third div end */}
              </PricingContainer>
            </Fade>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

const Container = styled.div`
  padding: 0 3%;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto 45px auto;
    @media ${Themes.breakpoints.lg} {
    padding: 0;
    margin:0 auto 140px auto;
  }
  @media ${Themes.breakpoints.xl} {
    max-width: 1200px;
  }
`;
const ScrollTarget = styled.div`
  height: 65px;
    @media ${Themes.breakpoints.lg} {
    height:140px;
  }
`;
const TitleDiv = styled.div`
  h1 {
    ${TitleStyle};
  }
`;
const Description = styled.div`
  display: none;
  ${Paragraph};
    @media ${Themes.breakpoints.md} {
    display:block;
    max-width:900px;
    margin:0 auto;
  }
`;
const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
    @media ${Themes.breakpoints.md} {
    ${FlexHorizontalCenter};
    margin-top:30px;
    display:grid;
    grid-template-columns:36% 31% 31%;
    // grid-template-columns:60%
    grid-gap:10px;
  }
    @media ${Themes.breakpoints.lg} {
    margin:60px auto 0 auto;
    grid-template-columns:36% 31% 31%;
  }
`;
const FreeDiv = styled.div`
  ${PricingTableCommonStyle};
  background-image: linear-gradient(
    ${Themes.colors.lightBlue},
    ${Themes.colors.blue}
  );
  padding: 14px;
    @media ${Themes.breakpoints.md} {
    grid-row: 1 / span 2;
    margin:0;
    height:100%;
  }
    @media ${Themes.breakpoints.lg} {
    display:flex;
    flex-direction:row;
    padding:16px;
  }
`;
const FreeInnerDiv = styled.div`
  ${PricingTableCommonStyle};
  background-color: white;
  border-radius: 18px;
  width: 100%;
  height: 100%;
  padding: 53px 0 18px 0;
  ${FlexCenter};
`;
const FreeTitle = styled.h3`
  color: ${Themes.colors.blue} !important;
  margin: 0;
`;
const BlueShortLine = styled(ShortLine)`
  background-color: ${Themes.colors.blue};
    @media ${Themes.breakpoints.lg} {
    margin-bottom:47px;
  }
`;
const FreeDivBody = styled.div`
    @media ${Themes.breakpoints.sm} {
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-gap:50px;
    margin: 25px auto;
  }
    @media ${Themes.breakpoints.md} {
    display:unset;
    margin:unset;
  }
`;
const FreeIcon = styled.img`
  width: 42px;
  margin-bottom: 11px;
`;
const FreeSubTitle = styled.div`
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.grey};
`;
const FreeList = styled.div`
  margin: 40px auto;
    @media ${Themes.breakpoints.sm} {
    margin: 0px auto;
  }
    @media ${Themes.breakpoints.md} {
    margin: 40px auto;
  }
`;
const FreeListItem = styled.div`
  font-size: ${Themes.fontsize.p3};
  text-align: left;
  margin-bottom: 10px;
`;
const FreeListCheck = styled.img`
  width: 20px;
  margin-right: 13px;
  @media ${Themes.breakpoints.xs} {
    margin-right:7px;
  }
`;
const FreeListText = styled.p`
  display: inline-block;
  color: ${Themes.colors.grey};
  span {
    font-weight: ${Themes.fontWeight.bold};
  }
    @media ${Themes.breakpoints.lg} {
        font-size:${Themes.fontsize.p2};
    }
`;
const FreeButton = styled.button`
  ${ButtonStyle};
  background-color: ${Themes.colors.pink};
  width: 250px;
  height: 75px;
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.bold};
`;

/** Membership Div Start **/
const MembershipDiv = styled.div`
  ${PricingTableCommonStyle};
  ${FlexVerticalCenter};
    @media ${Themes.breakpoints.md} {
    height:100%;
  }
`;
const MembershipList = styled.div`
  h5 {
    font-size: ${Themes.fontsize.h5};
    font-weight: ${Themes.fontWeight.bold};
    color: white;
    margin-top: 8px;
  }
  margin-bottom: 30px;
    @media ${Themes.breakpoints.sm} {
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-gap:40px;
  }
    @media ${Themes.breakpoints.md} {
    display:unset;
  }
`;
const MembershipListItem = styled.div`
  margin-top: 37px;
`;
const MembershipIcon = styled.img`
  width: 45px;
  height: auto;
`;
/** Membership Div End **/

const OutsourcingAndHRDivTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    display: inline-block;
    margin-right: 1em;
    width: 62px;
  }
  div {
    text-align: left;
    p {
      margin-bottom: 0;
      font-size: ${Themes.fontsize.p2};
      font-weight: ${Themes.fontWeight.extraLight};
    }
  }
`;
const OutsourcingAndHRDivTitleText = styled.p`
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.medium};
  text-align: center;
  margin: 0 40px 33px 40px;
  span {
    font-weight: ${Themes.fontWeight.extraLight};
  }
`;
const OutsourcingDiv = styled.div`
  ${PricingTableCommonStyle};
    @media ${Themes.breakpoints.lg} {
        margin:0 auto;
    }
`;
const HRDiv = styled.div`
  ${PricingTableCommonStyle};
`;
const RowGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
`;
export default Section03;
