import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { ThemeProvider } from 'styled-components';

import Themes from '../../Assets/Styles/Themes';
import { TitleStyle, Paragraph } from '../../Assets/Styles/Style';
/** Components **/
import BigBlueCtaButton from '../../Components/Button/BigBlueCtaButton';
/** Image **/
/** Modals */
import FreeConsultingModal from '../../Components/Modals/freeConsultingModal';

class Section05 extends Component{
    constructor(props, context) {
        super(props, context);
    
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    
        this.state = {
          show: false
        };
      }
      handleClose() {
        this.setState({ show: false });
      }
    
      handleShow() {
        this.setState({ show: true });
      }
    render(){
        return(
            <ThemeProvider theme={Themes}>
            <div>
                <FreeConsultingModal close={this.handleClose} show={this.state.show} />
                <Container>
                    <Fade bottom>
                    <Title>Request your free consult</Title>
                    <Div>
                        <Description>
                        <span>Get one hour <b>FREE</b></span> with an experienced HR Sense 
                        team member to discuss your immediate business concerns
                        </Description>
                        <div onClick={this.handleShow} >
                            <BigBlueCtaButton text="Request Free Consult" />
                        </div>
                    </Div>
                    </Fade>
                </Container>
            </div>
            </ThemeProvider>
        )
    }
}

const Container = styled.div`
    margin:0 auto;
    max-width:905px;
    padding:50px 10% 60px 10%;
    text-align:center;
    @media ${Themes.breakpoints.lg} {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        padding:150px 0;
    }
`
const Title = styled.div`
    ${TitleStyle}
    margin:0 auto 20px auto;
    width:200px;
    line-height:35px;
    @media ${Themes.breakpoints.lg} {
        text-align:left;
        margin:0;
        width:300px;
    }  
`
const Div = styled.div`
    display:flex;
    flex-direction:column;
    align-items:left;
    margin:0 auto;
    @media ${Themes.breakpoints.md} {
        max-width:490px; 
    }
    @media ${Themes.breakpoints.lg} {
        margin:0;
        button{
            margin-left:-5px;
        }
    }
`
const Description = styled.div`
    ${Paragraph};
    margin-bottom:15px;
    span{
        color:${Themes.colors.blue};
        font-weight:${Themes.fontWeight.medium};
        b{
            font-weight:${Themes.fontWeight.black};
        }
    }
    @media ${Themes.breakpoints.lg} {
        text-align:left;
        font-size:${Themes.fontsize.h4};
        line-height:30px;
    } 
`

export default Section05;