import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import App from "../../App";
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import KnowledgeBaseHeader from "../../Components/KnowledgeBase/KnowledgeBaseHeader";

import {
  ContainerStyle,
  FlexHorizontalJustify,
  ButtonStyle,
  FlexVerticalCenter
} from "../../Assets/Styles/Style";

/** Component */
import TopicsPanel from "./filter/topicsPanel";
import KnowledgeBaseEntry from "../../Components/KnowledgeBase/KnowledgeBaseEntry";
import CategoryPanel from "./filter/categoryPanel";
import TypesFilterPanel from "./filter/TypesFilterPanel";
import FormHandler from "../../Components/Form/FormHandler";
import FormSearch from "../../Components/Form/FormSearch";
import Paginator from "../../Components/Paginator";

class KnowledgeBaseScreen extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      entries: [],
      categories: [],
      topics: [],
      types: [],
      page: 0
    };

    this.ReqIndex = 0;
    this.RespIndex = 0;

    App.Current.SetPageName("Knowledge Base");
  }

  render() {
    const pageInfo = this.state.entries;
    const entries = pageInfo.data;

    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <KnowledgeBaseHeader />
          <FilterContainer>
            <FormHandler onChange={this.onFilterChange}>
              <div>
                <CategoryPanel categories={this.state.categories} />
                <TopicsPanel topics={this.state.topics} />
                <TypesFilterPanel types={this.state.types} />
              </div>
              <FilterFooter>
                <FormSearch
                  name="search"
                  value=""
                  placeholder="Search for something…"
                  class="kb__search"
                />
                <ClearFilterDiv>
                  <button type="reset">Clear Filter</button>
                </ClearFilterDiv>
              </FilterFooter>
            </FormHandler>
          </FilterContainer>

          {/* Entries */}
          <EntriesContainer>
            <EntryDiv>
              {entries &&
                entries.length > 0 &&
                entries.map(entry => (
                  <KnowledgeBaseEntry key={entry.id} entry={entry} />
                ))}
            </EntryDiv>
            <Paginator
              style={{ marginTop: "20px" }}
              page={pageInfo.page}
              size={pageInfo.size}
              total={pageInfo.total}
              onPageChange={this.onPageChange}
            />
          </EntriesContainer>
        </Fade>
      </ThemeProvider>
    );
  }

  componentDidMount = () => {
    this.loadInitalData();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.location.pathname.toLowerCase() === "/knowledgebase"
    ) {
      this.loadInitalData();
    }
  }

  /**
   * Reload the Knowledgebase Entries.
   */
  loadData = () => {
    App.Current.API.KnowledgeBase.GetEntries(
      this.state.filters,
      this.state.page,
      16
    ).then(entries => {
      this.setState({
        entries: entries
      });

      this.RespIndex++;
    });
  };

  /**
   * Load Filters, and Initial Knowledgebase Data.
   */
  loadInitalData() {
    this.loadData();

    App.Current.API.KnowledgeBase.GetFilters().then(filters =>
      this.setState({
        categories: filters.categories,
        topics: filters.topics,
        types: filters.types
      })
    );
  }

  /**
   * Change the Page.
   */
  onPageChange = page => {
    this.setState({ page: page }, this.loadData);
  };

  onFilterChange = filter => {
    this.setState(
      {
        filters: filter,
        page: 0
      },
      this.loadData
    );
  };
}

export default withRouter(KnowledgeBaseScreen);

const EntriesContainer = styled.div`
  ${ContainerStyle};
  ${FlexVerticalCenter};
  padding: 20px 5% 47px 5%;
  @media ${Themes.breakpoints.xl} {
    padding: 20px 0% 76px 0%;
  }
`;
const EntryDiv = styled.div`
  display: grid;
  grid-gap: 28.6px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  @media ${Themes.breakpoints.sm} {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const FilterFooter = styled.div`
  ${FlexVerticalCenter};
  align-items: center;
  @media ${Themes.breakpoints.sm} {
    ${FlexHorizontalJustify};
  }
  margin-top: 20px;
  @media ${Themes.breakpoints.lg} {
    margin-top: 104px;
  }
`;
const ClearFilterDiv = styled.div`
  button {
    ${ButtonStyle};
    background-color: ${Themes.colors.blue};
    padding: 7px 20px;
    font-size: ${Themes.fontsize.p2};
    font-weight: ${Themes.fontWeight.bold};
    height: 45px;
  }
`;
const FilterContainer = styled.div`
  ${ContainerStyle};
  padding: 30px 5% 0px 5%;
  @media ${Themes.breakpoints.lg} {
    padding: 87px 5% 0px 5%;
  }
  @media ${Themes.breakpoints.xl} {
    padding: 87px 0% 0px 0%;
  }
`;
