import {
    LabelModal,
    InputFieldModal,
    InputSectionStyle,
    FlexHorizontalCenter,
    ErrorStyle,
    InputFieldBase
  } from "../../Assets/Styles/Style";
import Themes from "../../Assets/Styles/Themes";

import styled from "styled-components";

export const Input = styled.input`
  margin-right: 10px !important;
`;

export const InputField = styled.input`
  ${InputFieldModal};

  @media ${Themes.breakpoints.lg} {
    width:536px;
  }
`;

export const Select = styled.select`
  ${InputFieldModal};

  @media ${Themes.breakpoints.lg} {
    width:379px;
  }
`;

export const InputDiv = styled.div`
  ${(props) => props.horizontal ? FlexHorizontalCenter : ""};
  /* to left align with title on Mobile*/
  &.healthcheck-form__input {
    margin-left: 50px;
    @media ${Themes.breakpoints.sm} {
      margin-left: 0;
      justify-content: flex-end;
    }
  }
`;

export const InputSection = styled.div`
  ${(props) => props.hasLabel ? `
    @media ${Themes.breakpoints.sm} {
      display:grid;
      grid-template-columns:1fr 2fr;
      grid-column-gap:15px;
    }` 
    : ""}

  input:not([type=radio]):not([type=checkbox]), select, textarea {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  margin:15px 0;
  &.healthcheck-form__input-container {
    margin:15px 0 30px 0 !important;
  }
  /* knowledge base search input */
  &.kb__search {
    width:100%;
    @media ${Themes.breakpoints.sm} {
        width:400px;
    }
  }
  &.admin__create-kb-entry {
    margin:20px 0;
    input {
      background-color: white !important;
    }
  }
`;

export const Label = styled.label`
  ${LabelModal};
  margin-bottom: 14px;

  @media ${Themes.breakpoints.md} {
    margin-bottom:14px;
  }
  
  @media ${Themes.breakpoints.lg} {
    width:100px;
    margin-right:70px;
  }
`;

export const InputLabel = styled.label`
  font-family: "Open Sans", sans-serif;
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.medium};
  margin: 0 30px 0 0px;

  @media ${Themes.breakpoints.lg} {
    margin-right:40px;
  }
  margin-bottom: 6px !important;
`;

export const PrimaryLabel = styled.div`
  ${LabelModal};
  margin-bottom: 14px;
  max-width: 510px;
  display: flex;
  margin-top:6px; 
  hyphens: auto;
  div {
    display: grid;
    grid-template-columns: 50px auto;
    span {
      /* margin-right: 15px; */
    }
  }
  @media ${Themes.breakpoints.md} {
    margin-bottom: 6px !important;
  }
`;

export const InputTextArea = styled.textarea`
  ${InputFieldModal};
  height:162px;
  resize: none;
`;

export const Limiter = styled.div`
  ${(props) => props.overLimit ? ErrorStyle : ""}
  text-align: right;
`;

export const FormPickerWrapper = styled.div`
  .fe-picker__control {
    ${InputFieldBase};
    background-color:white !important;
  }

  .fe-picker__single-value{
    color: ${Themes.colors.grey};
  }

  .fe-picker__menu {
    z-index: 999;
  }
`;