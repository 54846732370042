import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import App from "../../App";

export default class ErrorScreen extends Component {
  constructor(props){
    super(props);

    let message = 'An Error has occurred';
    switch(parseInt(this.props.errorcode)){
      case 404:
        message = "Page not Found";
        break;

      case 401:
        message = "You don't have access to this page";
        break;
    }

    this.state = {
      message: message
    };

    App.Current.SetPageName(message);
  }
  
  render() {
    return (
      <Fade>
        <div style={{ height: 'calc(100vh - 465px)', textAlign: 'center', alignItems: 'center', display: 'flex' }}>
          <div style={{ margin: '0 auto' }}>
            <h1 style={{ fontSize: '68px' }}>{this.props.errorcode}</h1>
            <h2>{this.state.message}</h2>
          </div>
        </div>
      </Fade>
    );
  }
}