import React, { Component } from "react";
import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";

/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  BigShadow,
  TitleStyle,
  Paragraph,
  ButtonStyle,
  Line,
  ClearBoth
} from "../../Assets/Styles/Style";
/** Image **/
import Section02ImageDesktop from "../../Assets/Images/Section02Image-Desktop.jpg";
import Section02ImageMobile from "../../Assets/Images/Section02Image-Mobile.jpg";
import { Item } from "semantic-ui-react";
import App from "../../App";
import Counter from "../../Components/Counter";

class Section02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startZero: 0,
      visible: true,
      counterVisible: false
    };
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <Section>
            <ImageDivMobile />
            <Container>
              <ImageDivDesktop />
              <ContentsDiv>
                <Contents01>
                  <Title>What Can We Provide?</Title>
                  <StyledLine />
                  <ClearBoth />
                  <Description>
                    HR Sense provides access to an experienced team of HR
                    Professionals with a combined industry knowledge of over
                    fifty years in providing tailored HR Solutions to small to
                    medium enterprise - In Choosing to become a member you will
                    have access to live support coupled with access to our
                    Knowledge portal containing informative articles, templates,
                    videos and more.
                  </Description>
                </Contents01>
                <Contents02>
                  <SubTitle>Knowledge Base</SubTitle>
                  <Description>
                    Exclusive content only available to our HR Sense members
                    containing up to date, relevant informaiton for essential HR
                    related business information.
                  </Description>
                    <NumbersDiv>
                      <Numbers>
                        <Counter to={this.props.stats.blogs} />
                        <NumbersCategory>Blogs</NumbersCategory>
                      </Numbers>
                      <Numbers>
                        <Counter to={this.props.stats.eBooks} />
                        <NumbersCategory>E-books</NumbersCategory>
                      </Numbers>
                      <Numbers>
                        <Counter to={this.props.stats.videos} />
                        <NumbersCategory>Videos</NumbersCategory>
                      </Numbers>
                    </NumbersDiv>
                  <Button to="/knowledgebase">And 100's More ></Button>
                </Contents02>
              </ContentsDiv>
            </Container>
          </Section>
        </Fade>
      </ThemeProvider>
    );
  }
}

const Section = styled.div`
  background-color: ${Themes.colors.veryLightBlueGrey};
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
    @media ${Themes.breakpoints.lg} {
        padding-bottom:82px;
    }
`;
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
    @media ${Themes.breakpoints.lg} {
    display:flex;
    flex-direction:row;
  }
  @media ${Themes.breakpoints.xl} {
    max-width: 1366px;
  }
`;
const ImageDivMobile = styled.div`
    background-image:url('${Section02ImageMobile}');
    background-position:top left;
    background-size:cover;
    width:100%;
    height:300px;
    ${BigShadow};
      @media ${Themes.breakpoints.md} {
        background-position:0 -100px;
    }
      @media ${Themes.breakpoints.lg} {
        display:none;
    }
`;
const ImageDivDesktop = styled.div`
  display: none;
  @media ${Themes.breakpoints.lg} {
      display:block;
      width:500px;
      height:500px;
      background-image:url('${Section02ImageDesktop}');
      background-position:top left;
      background-size:cover;
      ${BigShadow};
      transform:translate(-10%, -5%);
      border-radius:25px;
  }
  @media ${Themes.breakpoints.xl} {
    width:730px;
    height:730px;
    transform:translate(-12%, -5%);
  }
`;
const ContentsDiv = styled.div`
  margin: 40px 5% 0 5%;
  max-width: 647px;
  text-align: center;
    @media ${Themes.breakpoints.lg} {
        text-align:left;
        margin:90px 0 0 0;
    }
`;
const Contents01 = styled.div`
  margin-bottom: 25px;
    @media ${Themes.breakpoints.lg} {
        margin-bottom:60px;
    }
`;
const Title = styled.h1`
  ${TitleStyle};
`;
const StyledLine = styled(Line)`
    @media ${Themes.breakpoints.lg} {
        float:left;
    }
`;
const Description = styled.div`
  ${Paragraph};
`;
const Contents02 = styled.div``;
const SubTitle = styled.div`
  font-size: ${Themes.fontsize.h4};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.blue};
  margin-bottom: 15px;
`;
const NumbersDiv = styled.div`
  margin: 0 auto;
    @media ${Themes.breakpoints.sm} {
        display:flex;
        flex-direction:row;
        justify-content:space-between; 
    }
`;
const Numbers = styled.div``;
const Number = styled.span`
  font-size: 70px;
  font-weight: ${Themes.fontWeight.extraLight};
  color: ${Themes.colors.blue};
  margin-right: 10px;
  @media ${Themes.breakpoints.xl} {
        font-size:90px;
    }
`;
const NumbersCategory = styled.span`
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.bold};
`;
const Button = styled(Link)`
  margin: 30px auto 0 auto;
    @media ${Themes.breakpoints.lg} {
    margin: 40px auto 0 auto;
  }
  ${ButtonStyle};
  background-color: ${Themes.colors.pink};
  text-decoration: none;
  color: white;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 170px;
    @media ${Themes.breakpoints.lg} {
    float:right;
  }
  &:link, &:hover, &:active, &:visited {
    text-decoration: none;
    color: white;
  }
`;

export default Section02;
