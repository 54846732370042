import React, {Component} from "react";
import Fade from "react-reveal/Fade";
import {SplitNonEmpty, Metadata, SubStringWords} from "../../Utilities";
import App from "../../App";
import {ScrollToTop} from "../../Utilities";
import ErrorScreen from "../Error";
import ArticleView from "./component/ArticleView";
import KnowledgeBaseHeader from "../../Components/KnowledgeBase/KnowledgeBaseHeader";
import styled, {ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";

export default class ArticleScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      article: null,
      authenticated: App.Current.CurrentUser ? true : false,
      found: true
    };

    App.Current.OnLoggedIn(() => {
      this.setState({
        authenticated: true
      });

      this.getArticleInfo();
    });

    App.Current.OnLoggedOut(() => {
      let summary = this.state.article.content;
      if (summary.length > 100) {
        summary = SubStringWords(summary, 100);
        summary += "...";
      }

      this.setState({
        authenticated: false,
        article: {
          ...this.state.article,
          content: null,
          summary: summary,
          youTubeId: null
        }
      });
    });

    this.getArticleInfo();
  }

  render() {
    return (
      <div>
        {this.state.found && (
          <Fade>
            <KnowledgeBaseHeader />
            <Container>
              <ArticleView
                article={this.state.article}
                authenticated={this.state.authenticated}
              />
            </Container>
          </Fade>
        )}
        {!this.state.found && <ErrorScreen errorcode="404" />}
      </div>
    );
  }

  componentDidMount() {
    ScrollToTop();
  }

  componentDidUpdate() {
    ScrollToTop();
    const currentId = this.getArticleId();
    if (currentId != this.state.id) {
      if (this.state.id) {
        this.setState({
          id: currentId
        });
      }

      // Prevent Loop.
      if (this.state.found) {
        this.getArticleInfo();
      }
    }
  }

  getArticleId() {
    let id = null;

    // Look at the Address.
    const addressParts = SplitNonEmpty(window.location.pathname, "/");

    // Address has ID.
    if (addressParts.length == 2) {
      id = addressParts[1];
    }

    return id;
  }

  /**
   * Gets the Article Information.
   */
  getArticleInfo() {
    const id = this.getArticleId();

    // Populate model with Step data if an ID exists.
    if (id) {
      App.Current.API.KnowledgeBase.GetEntry(id)
        .then(entry => {
          if (!entry) {
            this.setState({found: false});
            return;
          }

          // Set Page Metadata
          try {
            App.Current.SetPageName(entry.title);
            Metadata("og:type", "article");
            // Description.
            let description = entry.summary;
            if (!description) {
              description = entry.content;
            }
            Metadata("description", description);
            Metadata("og:description", description);

            const img = App.Current.API.KnowledgeBase.GetThumbUrl(entry.id);
            Metadata("og:image", img);
            Metadata("og:url", `https://hrsense.com.au/knowledgebase/${entry.slug}`);
          } catch (e) {
            console.log(e);
          }

          // Set State.
          this.setState({article: entry, id: id});
        })
        .catch(e => this.setState({found: false}));
    }
  }
}

const Container = styled.div`
  padding: 30px 0% 47px 0%;
  @media ${Themes.breakpoints.md} {
    padding: 87px 0% 76px 0%;
  }
`;
