import React, {Component} from "react";
import PropTypes from "prop-types";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {TextShadow, ButtonStyle, GreenGradient} from "../../Assets/Styles/Style";
/** Images **/

class BigGreenCtaButton extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Button>
          <p>{this.props.text}</p>
        </Button>
      </ThemeProvider>
    );
  }
}

const Button = styled.button`
  ${ButtonStyle};
  ${GreenGradient};
  height: auto;
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.bold};
  margin: 20px 0 auto;
  padding: 12px 20px;
  letter-spacing: 1px;
  width: 100%;
  p {
    margin: 0;
    padding: 0;
    ${TextShadow};
    text-align: center;
  }

  @media ${Themes.breakpoints.md} {
    width: 440px;
    font-size: ${Themes.fontsize.h4};
  }
`;

export default BigGreenCtaButton;
