import React, { Component } from "react";
import { Link } from "react-router-dom";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../Assets/Styles/Themes";
// import "./rightSideDrawer.css";
import {
  FlexHorizontalCenter,
  SmallShadow
} from "../../../../Assets/Styles/Style";

/** Images **/
import logoutIcon from "../../../../Assets/Images/admin/logout.svg";
import App from "../../../../App";

class RightSideDrawer extends Component {
  constructor(props) {
    super(props);

    const user = App.Current.CurrentUser;

    this.state = {
      name: `${user.firstname} ${user.lastname}`
    };
  }

  render() {
    let drawerClasses = "";
    if (this.props.show) {
      drawerClasses = "rightSideOpen";
    }
    return (
      <ThemeProvider theme={Themes}>
        <Div>
          <Nav className={drawerClasses}>
            <MenuDiv>
              <ul>
                <li>
                  <Username>{this.state.name}</Username>
                </li>
                {/*<li>
                  <Link to="/admin">
                    <span>Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin">
                    <span>Account Settings</span>
                  </Link>
                </li>*/}
                <li onClick={this.logout}>
                  <MenuIconDiv>
                    <MenuIcon src={logoutIcon} />
                  </MenuIconDiv>
                  <span>Logout</span>
                </li>
              </ul>
            </MenuDiv>
          </Nav>
        </Div>
      </ThemeProvider>
    );
  }
}
const Div = styled.div`
  .rightSideOpen {
    transform: translateX(0);
    @media ${Themes.breakpoints.sm} {
      transform: translateX(100%);
    }
  }
`;
const Nav = styled.nav`
  ${SmallShadow};
  background-color: white;
  font-size: ${Themes.fontsize.p3};
  color: ${Themes.colors.grey};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 248px;

  height: 100%;

  position: fixed; //don't delete
  top: 0;
  right: 0;
  z-index: 200;

  transform: translateX(100%);
  transition: transform 0.3s ease;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;
const MenuDiv = styled.div`
  ul {
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eceeef;
      padding: 15px 5% 15px 30px;
      a {
        color: ${Themes.colors.grey};
        text-decoration: none;
        display: flex;
        ${FlexHorizontalCenter};
      }
      &:hover {
        box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.02);
        a {
          color: ${Themes.colors.blue};
        }
      }
    }
    li:first-child {
      @media ${Themes.breakpoints.sm} {
        display: none;
      }
    }
  }
`;
const Username = styled.span`
  color: ${Themes.colors.blue};
  p {
    color: ${Themes.colors.grey};
    font-size: ${Themes.fontsize.p3};
    font-weight: ${Themes.fontWeight.bold};
  }
`;
const MenuIconDiv = styled.div`
  height: 28px;
  margin-right: 12px;
  ${FlexHorizontalCenter};
`;
const MenuIcon = styled.img`
  width: ${props =>
    props.narrow ? "20px" : "18px" && props.wide ? "26px" : "18px"};
`;
export default RightSideDrawer;
