import React, { Component } from "react";
import { Link } from "react-router-dom";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../Assets/Styles/Themes";
import { FlexCenter, SmallShadow } from "../../../../Assets/Styles/Style";
// import navStyles from "./SideNav.css";

/** Images **/
import LogoWhite from "../../../../Assets/Images/logo-white.svg";
import dashboardIcon from "../../../../Assets/Images/admin/dashboard.svg";
import backIcon from "../../../../Assets/Images/admin/back.svg";
import addIcon from "../../../../Assets/Images/admin/plus-button.svg";

class SideNav extends Component {
  render() {
    let drawerClasses = "";
    if (this.props.show) {
      drawerClasses = "drawerOpen";
    }
    return (
      <ThemeProvider theme={Themes}>
        <Div>
          <Nav className={drawerClasses}>
            <LogoDiv>
              <img src={LogoWhite} alt="HRSense-Logo" className="logoBig" />
            </LogoDiv>
            <MenuDiv>
              <ul>
                {/*<ListItem>
                  <ListInner>
                    <Link to="/admin">
                      <MenuIconDiv>
                        <MenuIcon src={dashboardIcon} alt="Dashboard" />
                      </MenuIconDiv>
                      <span>Dash</span>
                    </Link>
                  </ListInner>
                </ListItem>*/}
                <ListItem>
                  <ListInner>
                    <Link to="/admin/kb/add">
                      <MenuIconDiv>
                        <MenuIcon src={addIcon} alt="Add Post" />
                      </MenuIconDiv>
                      <span>Add KB</span>
                    </Link>
                  </ListInner>
                </ListItem>
                <ListItem>
                  <ListInner>
                    <Link to="/">
                      <MenuIconDiv>
                        <MenuIcon src={backIcon} alt="Back to Website" />
                      </MenuIconDiv>
                      <span>Back to Website</span>
                    </Link>
                  </ListInner>
                </ListItem>
              </ul>
            </MenuDiv>
          </Nav>
        </Div>
      </ThemeProvider>
    );
  }
}
const Div = styled.div`
  .drawerOpen {
    transform: translateX(0);
  }
`;
const Nav = styled.nav`
  ${SmallShadow};
  background-color: ${Themes.colors.darkBlue};
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.regular};
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;
  @media ${Themes.breakpoints.sm} {
    padding-top: 58px;
  }
  width: 248px;

  height: 100%;

  position: fixed; //don't delete
  top: 0;
  left: 0;
  z-index: 200;

  transform: translateX(-100%);
  transition: transform 0.3s ease;
  @media ${Themes.breakpoints.sm} {
    transform: translateX(0);
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const LogoDiv = styled.div`
  ${FlexCenter};
  height: 80px;
  img {
    width: 160px;
  }
`;
const MenuDiv = styled.div`
  margin: 40px 0 40px 14px;
  ul {
    padding-left: 0;
  }
  @media ${Themes.breakpoints.sm} {
    margin: 87px 0 0 14px;
  }
`;
const ListItem = styled.div`
  height: 45px;
  @media ${Themes.breakpoints.sm} {
    height: 70px;
  }
  a {
    margin-left: 20px;
    color: white;
    text-decoration: none;
    display: flex;
  }
  &:hover {
    box-shadow: ${props =>
      props.darkHover
        ? "inset 0 0 0 99999px rgba(0, 0, 0, 0.1)"
        : "inset 0 0 0 99999px rgba(255, 255, 255, 0.1)"};
  }
`;
const ListInner = styled.div`
  margin-right: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  display: flex;
  align-items: center;
`;
const MenuIconDiv = styled.div`
  height: 28px;
  width: 28px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
const MenuIcon = styled.img`
  width: ${props => (props.wide ? "28px" : "22px")};
`;
export default SideNav;
