import React, { Component } from "react";
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  ContainerStyle,
  TitleStyle,
  Line,
  Paragraph,
  FlexVerticalCenter,
  FlexHorizontalCenter
} from "../../Assets/Styles/Style";
/** Component */
import ToggleBtn from "../../Components/Toggle/responsiveToggleBtn";
import { ServiceList } from "./component/ServiceList";
/** Image **/
import HRImg from "../../Assets/Images/HR.svg";
import recruitmentImg from "../../Assets/Images/recruitment.svg";
import fairworkImg from "../../Assets/Images/fairwork.svg";
import safetyImg from "../../Assets/Images/safety.svg";
import accountImg from "../../Assets/Images/account.svg";

/** Data */
import {
  GeneralHR,
  Safety,
  Accounts,
  Fairwork,
  Recruitment
} from "./data/ServiceData";
import DisplayHandler from "../../Components/DisplayHandler";

export default class Section04 extends Component {
  constructor() {
    super();
    this.state = {
      show: [false, false, false, false, false]
    };
  }
  showHandler(num) {
    this.setState(prevState => {
      const newItems = [...prevState.show];
      newItems[num] = !newItems[num];
      return { show: newItems };
    });
  }
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Fade>
            <Section>
              <Container>
                <TitleDiv>
                  <h1>Consultancy and Outsourcing</h1>
                  <Line />
                  <p>
                    Our experienced team can be available to you through
                    outsourcing or one-off projects.
                    <br />
                    The HR Sense team has experience in all areas of your
                    business needs covering the following main areas.
                  </p>
                </TitleDiv>
                <Contents>
                  <Services>
                    <GridItem01>
                      <img src={HRImg} width={"120px"} />
                      <div>
                        <h4>General HR</h4>
                        <ToggleBtn click={() => this.showHandler(0)} show={this.state.show[0]}/>
                      </div>
                    </GridItem01>
                    <GridItem02>
                      {this.state.show[0] && (
                        <DisplayHandler view="mobile">
                          <ServiceList
                            listName={GeneralHR}
                            className="list01"
                          />
                        </DisplayHandler>
                      )}
                      <DisplayHandler view="desktop">
                        <ServiceList listName={GeneralHR} className="list01" />
                      </DisplayHandler>
                    </GridItem02>
                    <GridItem>
                      <img src={fairworkImg} width={"110px"} />
                      <div>
                        <h4>Fair Work Act</h4>
                        <ToggleBtn click={() => this.showHandler(1)} show={this.state.show[1]}/>
                      </div>
                    </GridItem>
                    <GridItem>
                      {this.state.show[1] && (
                        <DisplayHandler view="mobile">
                          <ServiceList listName={Fairwork} />
                        </DisplayHandler>
                      )}
                      <DisplayHandler view="desktop">
                        <ServiceList listName={Fairwork} />
                      </DisplayHandler>
                    </GridItem>
                    <GridItem>
                      <img src={safetyImg} width={"110px"} />
                      <div>
                        <h4>Work, Health & Saftety</h4>
                        <ToggleBtn click={() => this.showHandler(2)} show={this.state.show[2]}/>
                      </div>
                    </GridItem>
                    <GridItem>
                      {this.state.show[2] && (
                        <DisplayHandler view="mobile">
                          <ServiceList listName={Safety} />
                        </DisplayHandler>
                      )}
                      <DisplayHandler view="desktop">
                        <ServiceList listName={Safety} />
                      </DisplayHandler>
                    </GridItem>
                    <GridItem>
                      <img src={recruitmentImg} width={"120px"} />
                      <div>
                        <h4>Recruitment</h4>
                        <ToggleBtn click={() => this.showHandler(3)} show={this.state.show[3]}/>
                      </div>
                    </GridItem>
                    <GridItem>
                      {this.state.show[3] && (
                        <DisplayHandler view="mobile">
                          <ServiceList listName={Recruitment} />
                        </DisplayHandler>
                      )}
                      <DisplayHandler view="desktop">
                        <ServiceList listName={Recruitment} />
                      </DisplayHandler>
                    </GridItem>
                    <GridItem>
                      <img src={accountImg} width={"95px"} />
                      <div>
                        <h4>Accounts Management</h4>
                        <ToggleBtn click={() => this.showHandler(4)} show={this.state.show[4]}/>
                      </div>
                    </GridItem>
                    <GridItem>
                      {this.state.show[4] && (
                        <DisplayHandler view="mobile">
                          <ServiceList listName={Accounts} />
                        </DisplayHandler>
                      )}
                      <DisplayHandler view="desktop">
                        <ServiceList listName={Accounts} />
                      </DisplayHandler>
                    </GridItem>
                  </Services>
                </Contents>
              </Container>
            </Section>
          </Fade>
        </div>
      </ThemeProvider>
    );
  }
}
const Section = styled.section`
  background-color: ${Themes.colors.veryLightBlueGrey};
`;
const Container = styled.div`
  ${ContainerStyle};
  padding: 40px 8% 1px 8%;
    @media ${Themes.breakpoints.sm} {
    padding:87px 3% 40px 3%;
  }
  @media ${Themes.breakpoints.xl} {
    padding:87px 0% 40px 0%;
  }
`;
const TitleDiv = styled.div`
  text-align: center;
  h1 {
    ${TitleStyle};
  }
  p {
    ${Paragraph};
  }
`;
const Contents = styled.div``;

// Services List Start //
const Services = styled.div`
  li {
    font-size: ${Themes.fontsize.p3};
    font-weight: ${Themes.fontWeight.medium};
    margin-bottom: 16px;
  }
  margin-top: 40px;
    @media ${Themes.breakpoints.md} {
    margin-top:80px;
    display: grid;
    grid-template-columns:1fr 2fr 1fr 2fr;
    align-items: start;
    grid-column-gap:4%;
    grid-row-gap:1%;
  }
`;
const GridItem = styled.div`
  ${FlexVerticalCenter};
  margin-bottom: 20px;
  img {
    height: auto;
    margin: 0 auto;
  }
  div {
    ${FlexHorizontalCenter};
    margin: 15px auto 0 auto;
      @media ${Themes.breakpoints.md} {
      margin: 0;
    }
    h4 {
      font-size: ${Themes.fontsize.h5};
      font-weight: ${Themes.fontWeight.bold};
      color: ${Themes.colors.blue};
      text-align: center;
      margin: 0 auto;
        @media ${Themes.breakpoints.md} {
        margin:15px auto 0 auto;
      }
        @media ${Themes.breakpoints.lg} {
        font-size: ${Themes.fontsize.h4};
     }
    }
    div {
      margin-top: 2px;
      margin-left: 7px;
        @media ${Themes.breakpoints.md} {
        display:none;
      }
    }
  }
`;
const GridItem01 = styled(GridItem)`
  grid-column: 1;
  grid-row: 1 / span 2;
`;
const GridItem02 = styled(GridItem)`
  grid-column: 2;
  grid-row: 1 / span 2;
    @media ${Themes.breakpoints.md} {
        margin-bottom:50px;
        margin-right:50px;
    }
`;
// Services List End //
