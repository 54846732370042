import React, { Component } from "react";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import { LinkTextStyle, ContainerStyle } from "../../Assets/Styles/Style";
/** Images **/
import Icon_Youtube from "../../Assets/Images/floating-icon-youtube.svg";
import Icon_Instagram from "../../Assets/Images/floating-icon-instagram.svg";
import Icon_Twitter from "../../Assets/Images/floating-icon-twitter.svg";
import Icon_Facebook from "../../Assets/Images/floating-icon-facebook.svg";
import Icon_Linkedin from "../../Assets/Images/floating-icon-linkedin.svg";
/** SNS URL**/
import { youtube, instagram, twitter, facebook, linkedin } from "./snsUrls";

class SNS_FloatingIconsContainer extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Slide right cascade>
          <FloatingSnsIcons>
            <div>
              <IconLink href={youtube} target="_blank">
                <img src={Icon_Youtube} alt="youtube" />
              </IconLink>
            </div>
            <div>
              <IconLink href={instagram} target="_blank">
                <img src={Icon_Instagram} alt="instagram" />
              </IconLink>
            </div>
            <div>
              <IconLink href={twitter} target="_blank">
                <img src={Icon_Twitter} alt="twitter" />
              </IconLink>
            </div>
            <div>
              <IconLink href={facebook} target="_blank">
                <img src={Icon_Facebook} alt="facebook" />
              </IconLink>
            </div>
            <div>
              <IconLink href={linkedin} target="_blank">
                <img src={Icon_Linkedin} alt="linkedin" />
              </IconLink>
            </div>
          </FloatingSnsIcons>
        </Slide>
      </ThemeProvider>
    );
  }
}

const FloatingSnsIcons = styled.div`
  display: none;
  @media ${Themes.breakpoints.lg} {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 3vw;
    z-index: 1;
    top: 300px;
  }
  @media ${Themes.breakpoints.xl} {
    right: 5vw;
  }
  div {
    ${LinkTextStyle};
    width: 50px;
    @media ${Themes.breakpoints.lg} {
      width: 70px;
    }
    img {
      width: 100%;
    }
  }
`;
const IconLink = styled.a`
  ${LinkTextStyle};
  width: 50px;
  @media ${Themes.breakpoints.lg} {
    width: 70px;
  }
  img {
    width: 100%;
  }
`;

export default SNS_FloatingIconsContainer;
