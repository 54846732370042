import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import ImageMapper from "react-image-mapper";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  Line,
  FlexVerticalCenter,
  ContainerStyle,
  TitleStyle,
  FlexCenter
} from "../../Assets/Styles/Style";
/** Component */
/** Image */
import BusinessKitSm from "../../Assets/Images/OurServices/businessKit_sm.png";
import BusinessKitMd from "../../Assets/Images/OurServices/businessKit_md.png";
import BusinessKit01 from "../../Assets/Images/OurServices/businessKit_01.svg";
import BusinessKit02 from "../../Assets/Images/OurServices/businessKit_02.svg";
import BusinessKit03 from "../../Assets/Images/OurServices/businessKit_03.svg";
import BusinessKit04 from "../../Assets/Images/OurServices/businessKit_04.svg";
import BusinessKit05 from "../../Assets/Images/OurServices/businessKit_05.svg";

export default class section03 extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  getMap(size){
    return {
      name: `map_${size}`,
      areas: [
        {
          name: "1",
          shape: "circle",
          coords: this.getMapCoords(1, size),
          href: "ourservices#workhealthandsafety"
        },
        {
          name: "2",
          shape: "circle",
          coords: this.getMapCoords(2, size),
          href: "ourservices#employeeperformance"
        },
        {
          name: "3",
          shape: "circle",
          coords: this.getMapCoords(3, size),
          href: "ourservices#hrfundamentals"
        },
        {
          name: "4",
          shape: "circle",
          coords: this.getMapCoords(4, size),
          href: "ourservices#debtorsandfinancialhealth"
        },
        {
          name: "5",
          shape: "circle",
          coords: this.getMapCoords(5, size),
          href: "ourservices#wagesandmodernawards"
        }
      ]
    };
  }

  getMapCoords(id, size){
    let coordSet = {};

    switch(parseInt(id)){
      case 1:
        coordSet = {
          small: [76, 75, 75],
          medium: [68, 349, 70],
          large: [88, 448, 89]
        };
        break;

      case 2:
        coordSet = {
          small: [225, 159, 75],
          medium: [151, 150, 70],
          large: [194, 192, 89]
        };
        break;

      case 3:
        coordSet = {
          small: [74, 241, 76],
          medium: [350, 68, 69],
          large: [450, 88, 88]
        };
        break;

      case 4:
        coordSet = {
          small: [225, 329, 77],
          medium: [531, 150, 69],
          large: [682, 192, 89]
        };
        break;

      case 5:
        coordSet = {
          small: [74, 408, 75],
          medium: [632, 349, 69],
          large: [812, 448, 89]
        };
        break;
    }

    return coordSet[size];
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Section id="businessKit">
            <Container className="businessKitImageContainer">
              <BusinessKitDiv>
                <BusinessKit_Title>
                  <Fade bottom cascade>
                    <TitleDiv>
                      <h1>5 Business Kits</h1>
                      <Line />
                    </TitleDiv>
                    <TextDiv>
                      <Text>
                        The following five business kits are available within
                        the membership models above – All Business Kits target
                        the key strategic areas within every organisation and
                        will provide industry best practice advice minimising
                        your exposure to the compliance frameworks expected
                        within every business.
                      </Text>
                      <Line />
                    </TextDiv>
                  </Fade>
                  <Fade bottom cascade>
                    <ImageDiv>
                      <div className="image_sm">
                        <ImageMapper
                          src={BusinessKitSm}
                          map={this.getMap("small")}
                          width={300}
                        />
                      </div>
                      <div className="image_md">
                        <ImageMapper
                          src={BusinessKitMd}
                          map={this.getMap("medium")}
                          width={700}
                        />
                      </div>
                      <div className="image_lg">
                        <ImageMapper
                          src={BusinessKitMd}
                          map={this.getMap("large")}
                          width={900}
                        />
                      </div>
                    </ImageDiv>
                  </Fade>
                </BusinessKit_Title>
              </BusinessKitDiv>
            </Container>
          </Section>
          {/* Business Kit 01 start */}
          <BusinessKit_Contents id="workhealthandsafety">
            <BusinessKit_Contents_Title className="businessKit01">
              <div className="number">01</div>
              <div />
              <div className="icon">
                <img src={BusinessKit01} alt="Work, Health & Safety" />
              </div>
              <div className="title">
                <p>Work, Health & Safety</p>
              </div>
            </BusinessKit_Contents_Title>
            <BusinessKit_Contents_Article>
              <div>
                <ul>
                  <li>
                    A ‘person conducting a business or undertaking’ (or PCBU) is
                    a legal term under Work Health & Safety laws for
                    individuals, businesses or organisations that are conducting
                    business.
                  </li>
                  <br />
                  <li>
                    Health, safety and, overall, wellness of an employee is an
                    important responsibility for all business leaders. Your
                    WorkCover Insurance premiums is one of the largest insurance
                    expenses for your business. Reducing risk, both physical and
                    mental is good for business and your employees.
                  </li>
                  <br />
                  <li>
                    <p>
                      Workplace safety can be a minefield – knowing where to
                      start can be frustrating: from accident records,
                      near–misses, safety training, hygiene compliance. HR Sense
                      gets you started:
                    </p>
                    <ul>
                      <li>
                        <strong>Creating WHS policy</strong>
                      </li>
                      <li>
                        <strong>Accident record keeping</strong>
                      </li>
                      <li>
                        <strong>
                          Working from home/working alone (unsupervised)
                        </strong>
                      </li>
                      <li>
                        <strong>Long term illness/mental health</strong>
                      </li>
                      <li>
                        <strong>Hygiene and First Aid</strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </BusinessKit_Contents_Article>
          </BusinessKit_Contents>
          {/* Business Kit 01 end */}
          <Hr />
          {/* Business Kit 02 start */}
          <BusinessKit_Contents id="employeeperformance">
            <BusinessKit_Contents_Title className="businessKit02">
              <div className="number">02</div>
              <div />
              <div className="icon">
                <img src={BusinessKit02} alt="Employee Performance" />
              </div>
              <div className="title">
                <p>Employee Performance</p>
              </div>
            </BusinessKit_Contents_Title>
            <BusinessKit_Contents_Article>
              <div>
                <ul>
                  <li>
                    Managing performance is universally accepted as the most
                    challenging aspect of people management for Leaders and
                    Managers.
                  </li>
                  <br />
                  <li>
                    HR Sense EP/D&M focuses on best practice for managing
                    performance, incentives to drive motivation, Recruitment
                    –selection and on~boarding, Engagement and Retention.
                  </li>
                </ul>
                <br />
                <div>
                  <span className="subtitle businessKit02">
                    Recruitment and Selection
                  </span>
                  <p>
                    Recruitment and selection of talent is probably the most
                    important decision a business leader makes in building the
                    business. Selecting the right person means employing a team
                    member who will advocate your business, build and represent
                    the values and culture of your business. The wrong person
                    will cost your business money and exhaustive time in
                    performance reviews, and culture clashes and cause tiring
                    and unrelenting recruitment cycles.
                  </p>
                </div>
              </div>
            </BusinessKit_Contents_Article>
          </BusinessKit_Contents>
          {/* Business Kit 02 end */}
          <Hr />
          {/* Business Kit 03 start */}
          <BusinessKit_Contents id="hrfundamentals">
            <BusinessKit_Contents_Title className="businessKit03">
              <div className="number">03</div>
              <div />
              <div className="icon">
                <img src={BusinessKit03} alt="HR Fundamentals" />
              </div>
              <div className="title">
                <p>HR Fundamentals</p>
              </div>
            </BusinessKit_Contents_Title>
            <BusinessKit_Contents_Article>
              <div>
                <p>
                  Having the right policies and procedures in place, and
                  available to your employees, is assurance that you are covered
                  for employee-related issues. Policies outline what is expected
                  and process to follow in the event of any breaches.
                </p>
                <br />
                <div>
                  <span className="subtitle businessKit03">
                    Policies available
                  </span>
                  <ul>
                    <li>Policy – Code of conduct</li>
                    <li>Policy – Workplace behaviour</li>
                    <li>Policy – Occupational health and safety</li>
                    <li>Policy – Employee performance and conduct</li>
                    <li>Policy – Electronic resources and IT use</li>
                    <li>Policy – Mobile phone and company property</li>
                    <li>Policy – Grievance</li>
                    <li>Policy – Social media</li>
                    <li>Policy – Leave</li>
                    <li>Policy – Recruitment</li>
                    <li>Policy – Termination</li>
                    <li>Policy – Drug and alcohol</li>
                    <li>Policy – Mental health and wellbeing</li>
                    <li>Policy – Travel and expense</li>
                    <li>Policy – Corporate social responsibility</li>
                    <li>Policy – Training and development</li>
                    <li>Policy – Dress attire</li>
                    <li>Policy – Company conflict of interest</li>
                    <li>Policy – Attendance</li>
                    <li>Policy – Privacy and security</li>
                  </ul>
                </div>
                <br />
                <fieldset className="supplementary">
                  <legend>Supplementary module</legend>
                  <div>
                    <span className="subtitle businessKit03">
                      Bullying, Harassment and Discrimination
                    </span>
                    <br />
                    <ul>
                      <li>
                        Sometimes employers face work situations where bullying
                        and intimidation, and sometimes, violence, are serious
                        situations that need to be managed sensitively and
                        quickly. Challenging employees and issues can be
                        prevented if you have the right policies and know how to
                        prevent these issues. Effective workplace guides help
                        cover these essentials.
                      </li>
                      <br />
                      <li>
                        <span>Bullying and Harassment</span>
                        <br />
                        <ul>
                          <li>Policies and templates</li>
                          <li>Report form - sample templates</li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        The Essentials Kit - includes policies and templates:
                        <ul>
                          <li>Discrimination</li>
                          <li>Adverse Action</li>
                          <li>Fairness in recruiting</li>
                          <li>Harassment - sexual and physical</li>
                          <li>Bullying</li>
                          <li>Violence in the workplace</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
            </BusinessKit_Contents_Article>
          </BusinessKit_Contents>
          {/* Business Kit 03 end */}
          <Hr />
          {/* Business Kit 04 start */}
          <BusinessKit_Contents id="debtorsandfinancialhealth">
            <BusinessKit_Contents_Title className="businessKit04">
              <div className="number">04</div>
              <div />
              <div className="icon">
                <img src={BusinessKit04} alt="Debtors & Financial Health" />
              </div>
              <div className="title">
                <p>Debtors & Financial Health</p>
              </div>
            </BusinessKit_Contents_Title>
            <BusinessKit_Contents_Article>
              <div>
                <ul>
                  <li>
                    Over 60% of small businesses in Australia fail within the
                    first 3 years… insufficient cashflow is one major cause.
                  </li>
                  <br />
                  <li>
                    Trade Debtors can be managed to increase cashflow and ensure
                    you are paid on time.
                  </li>
                  <br />
                  <li>
                    Facts
                    <ol>
                      <li>
                        The later an invoice becomes the more likely it is that
                        it will never be paid
                      </li>
                      <li>Client relationships become strained</li>
                      <li>Cashflow becomes unmanageable</li>
                      <li>Creditors become weary and supply is jeopardised</li>
                    </ol>
                  </li>
                </ul>
              </div>
            </BusinessKit_Contents_Article>
          </BusinessKit_Contents>
          {/* Business Kit 04 end */}
          <Hr />
          {/* Business Kit 05 start */}
          <BusinessKit_Contents id="wagesandmodernawards">
            <BusinessKit_Contents_Title className="businessKit05">
              <div className="number">05</div>
              <div />
              <div className="icon">
                <img src={BusinessKit05} alt="Wages & Modern Awards" />
              </div>
              <div className="title">
                <p>Wages & Modern Awards</p>
              </div>
            </BusinessKit_Contents_Title>
            <BusinessKit_Contents_Article>
              <div>
                <ul>
                  <li>
                    Understanding minimum wages in Australia is one of the most
                    important elements to maintaining an effective cost
                    structure when running a business.
                  </li>
                  <br />
                  <li>
                    While there are different minimum wages for different job
                    types and awards, each of these stems from one core minimum
                    wage which all businesses in Australia must abide by. The
                    current National Minimum Wage is $18.29 per hour, or $694.90
                    per 38-hour week (before tax). If you employ casual workers,
                    they are covered by this national minimum wage, but they
                    also must receive at least 25% casual loading.
                  </li>
                  <br />
                  <li>
                    However, when it comes to working out what your employees’
                    minimum wages are, you also need to factor in their minimum
                    entitlements under the relevant Award or Agreement. These
                    entitlements are differentiated across a variety of factors
                    such as industry, job type, experience in the role etc and
                    hold potential repercussions if they are not met. So it is
                    important that employers understand minimum entitlements.
                  </li>
                  <li>
                    Employers should also know that if they have employees who
                    are not covered by an Award or Agreement, they will have
                    their wage set by the National Minimum Wage Order.
                  </li>
                </ul>
              </div>
            </BusinessKit_Contents_Article>
          </BusinessKit_Contents>
          {/* Business Kit 05 end */}
        </div>
      </ThemeProvider>
    );
  }
}
const Section = styled.section`
  background-color: ${Themes.colors.veryLightBlueGrey};
  .businessKitImageContainer {
    min-height: 670px;
      @media ${Themes.breakpoints.sm} {
      min-height: 820px;
  }
      @media ${Themes.breakpoints.lg} {
      min-height:1100px;
  }
  }
`;
const Container = styled.div`
  ${ContainerStyle};
  padding: 40px 8%;
    @media ${Themes.breakpoints.md} {
    padding:87px 3% 40px 3%;
  }
  @media ${Themes.breakpoints.xl} {
    padding:87px 0% 40px 0%;
  }
`;
const BusinessKit_Contents = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 8%;
    @media ${Themes.breakpoints.sm} {
    padding: 40px 3%;
  }
    @media ${Themes.breakpoints.md} {
    padding:40px 8%;
    display:grid;
    grid-template-columns: 30% 60%;
    grid-column-gap: 25px;
  }
  @media ${Themes.breakpoints.xl} {
    padding:87px 0%;
  }
  .businessKit01 {
    color: ${Themes.colors.lightBlue};
  }
  .businessKit02 {
    color: #8356a2;
  }
  .businessKit03 {
    color: #bb5a8c;
  }
  .businessKit04 {
    color: #db766f;
  }
  .businessKit05 {
    color: #e3a35b;
  }
  ul {
    margin-top: 10px;
    font-size: ${Themes.fontsize.p3};
    line-height: 25px;
  }
`;
const Hr = styled.hr`
  border-top-width: 2px;
  margin: 10px 8%;
  max-width: 1000px;
    @media ${Themes.breakpoints.sm} {
    margin: 10px 5%;
  }
    @media ${Themes.breakpoints.md} {
    margin: 10px 5%;
  }
    @media ${Themes.breakpoints.lg} {
    margin:10px auto;
  }
  @media ${Themes.breakpoints.xl} {
    margin:10px auto;
    max-width: 1200px;
  }
`;
const BusinessKitDiv = styled.div`
  margin: 0 auto;
  background: ${Themes.colors.veryLightBlueGrey};
`;
const BusinessKit_Title = styled.div`
  ${FlexVerticalCenter};
  img {
    width: 100%;
    margin: 0 auto;
  }
`;
const TitleDiv = styled.div`
  text-align: center;
  h1 {
    ${TitleStyle};
  }
    @media ${Themes.breakpoints.sm} {
    display:none;
  }
`;
const TextDiv = styled.div`
  display: none;
    @media ${Themes.breakpoints.sm} {
      ${FlexVerticalCenter};
      display:block;
    }
`;
const Text = styled.p`
  margin: 0 auto;
  font-size: ${Themes.fontsize.p2};
  text-align: center;
  line-height: 30px;
  font-weight: ${Themes.fontWeight.semiBold};
  max-width: 1000px;
    @media ${Themes.breakpoints.lg} {
      margin:0 auto;
      line-height:40px;
      font-size:${Themes.fontsize.h4};
  }
`;
const ImageDiv = styled.div`
  padding: 1em 0;
  ${FlexVerticalCenter};
  align-items: center;
  .image_sm {
    display: block;
  }
  .image_md {
    display: none;
  }
  .image_lg {
    display: none;
  }
    @media ${Themes.breakpoints.sm} {
    .image_sm {
      display:none;
    }
    .image_md {
      display:block;
    }
  }
    @media ${Themes.breakpoints.lg} {
    .image_md {
      display: none;
    }
    .image_lg {
      display: block !important;
    }
  }
`;
const BusinessKit_Contents_Title = styled.div`
  display: grid;
  grid-template-columns: 20% 10% 20% 50%;
    @media ${Themes.breakpoints.sm} {
    grid-template-columns: 15% 45% 10% 30%;
  }
  max-height: 160px;
  img {
    height: 45px;
  }
  .number {
    ${FlexCenter};
    padding-bottom: 5px;
    font-weight: ${Themes.fontWeight.extraLight};
    font-size: 65px;
      @media ${Themes.breakpoints.sm} {
      margin-left:25px;
    }
      @media ${Themes.breakpoints.md} {
      font-size:80px;
      margin-left:0;
    }
      @media ${Themes.breakpoints.lg} {
      font-size:100px;
      margin-left:0;
    }
  }
  .icon {
    ${FlexCenter};
  }
  .title {
    display: flex;
    align-items: center;
    font-size: ${Themes.fontsize.p2};
    font-weight: ${Themes.fontWeight.bold};
    padding-left: 10px;
      @media ${Themes.breakpoints.md} {
      font-size:${Themes.fontsize.p1};
    }
      @media ${Themes.breakpoints.lg} {
      font-size:${Themes.fontsize.h4};
    }
  }
    @media ${Themes.breakpoints.md} {
      img { height:70px; }
      grid-template-columns: 30% 60%;
      grid-column-gap: 25px; 
      .number {
        height:80px;
        justify-content:unset;
      }
      .icon {
        height:80px;
        justify-content:unset;
        padding-left:10px;
      }
      .title {
        align-items: flex-start;
      }
      div:nth-of-type(1) {
        order: 1;
      }
      div:nth-of-type(2) {
        order: 3;
      }
      div:nth-of-type(3) {
        order: 2;
      }
      div:nth-of-type(4) {
        order: 4;
      }
  }
    @media ${Themes.breakpoints.lg} {
       .number {
        justify-content:center;
      }
  }
`;
const BusinessKit_Contents_Article = styled.div`
  .subtitle {
    font-size: ${Themes.fontsize.p2} !important;
    font-weight: ${Themes.fontWeight.bold} !important;
  }
  font-size: ${Themes.fontsize.p3};
  line-height: 25px;
  .supplementary {
    border: 0.5px solid ${Themes.colors.lightGrey};
    border-radius: 10px;
    padding: 1.5em 1.5em 1.5em 2.5em;
  }
  legend {
    display: block;
    width: auto;
    padding: 0 0.5em;
    margin-bottom: 0px;
    font-size: 15px;
    line-height: inherit;
    color: ${Themes.colors.lightGrey};
    font-weight: bold;
    border: 0;
  }
    @media ${Themes.breakpoints.sm} {
    padding:0 2em;
  }
    @media ${Themes.breakpoints.md} {
    padding:0;
  }
`;
