import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter,
  TextShadow,
  TitleStyle,
  SubTitleStyle,
  ContainerStyle
} from "../../Assets/Styles/Style";

/** Images **/
import BgSmall from "../../Assets/Images/our-services-hero-mobile.png";
import BgBig from "../../Assets/Images/our-services-hero.jpg";

class Section01 extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <ScrollTarget id="membership" />
          <Section>
            <Container>
              <TitleDiv>
                <h1>Our Services</h1>
                <p>
                  You decide on how we can support you best. From project to
                  hourly based support models, <br />
                  through to annual HR support subscriptions. Whichever method
                  you choose we have the best <br />
                  approach to providing your HR solutions no matter the type of
                  challenge or size of your business.
                </p>
              </TitleDiv>
            </Container>
          </Section>
        </Fade>
      </ThemeProvider>
    );
  }
}
const Section = styled.div`
  margin:0;
  background-image:url('${BgSmall}');
  height:250px;
  background-position:center top;
  background-repeat:no-repeat;
  background-size:cover;
  color:white;
  ${FlexCenter};
    @media ${Themes.breakpoints.md} {
      background: url('${BgBig}');
      height:351px;
      background-position:center top;
      background-repeat:no-repeat;
      background-size:cover;
  }
`;
const ScrollTarget = styled.div`
  height:0px;
`
const Container = styled.div`
  ${ContainerStyle};
`;
const TitleDiv = styled.div`
  ${TextShadow};
  h1 {
    ${TitleStyle};
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    display: none;
  }
    @media ${Themes.breakpoints.md} {
    p{
     display:block;
     ${SubTitleStyle};
    }
  }
`;
export default Section01;
