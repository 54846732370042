import React, { Component } from "react";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

/** Image */
import LogoWhite from "../../../Assets/Images/logo-white.svg";
/** Map */
import GoogleMapReact from "google-map-react";
import { FlexHorizontalCenter, FlexCenter } from "../../../Assets/Styles/Style";
import Themes from "../../../Assets/Styles/Themes";
import AsyncElement from "../../../Components/AsyncElement";
import App from "../../../App";

const MapToolTip = () => {
  return (
    <MapToolTipDiv>
      <Content>
        <Logo src={LogoWhite} />
        <Text>
          Suite 2 Level 1/20
          <br />
          Wentworth Street
          <br />
          Parramatta, NSW 2150
          <br />
          Sydney, NSW 2000
          <br />
        </Text>
      </Content>
    </MapToolTipDiv>
  );
};
const MapToolTipDiv = styled.div`
  color: white;
  background: black;
  opacity: 0.75;
  width: 270px;
  padding: 25px 12px;
  @media ${Themes.breakpoints.sm} {
    padding: 30px 15px;
    width: 315px;
  }
  transform: translate(-50%, -50%);
  ${FlexCenter};
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -20px;
    border-width: 20px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
const Content = styled.div`
  ${FlexHorizontalCenter};
`;
const Logo = styled.img`
  width: 96px;
  margin-right: 23px;
`;
const Text = styled.div`
  color: white;
  text-align: left;
  font-size: ${Themes.fontsize.p4};
  line-height: 18px;
  font-family: "Raleway", sans-serif;
  @media ${Themes.breakpoints.sm} {
    font-size: ${Themes.fontsize.p3};
    line-height: 20px;
  }
`;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: -33.81946,
      lng: 151.00572
    },
    zoom: 14
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <MapDiv>
        <AsyncElement
          promise={App.Current.Info}
          then={info => (
            <GoogleMapReact
              bootstrapURLKeys={{ key: info.googleMapsKey }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <MapToolTip lat={-33.81946} lng={151.00572} />
            </GoogleMapReact>
          )}
        />
      </MapDiv>
    );
  }
}

const MapDiv = styled.div`
  height: 250px;
  width: 100%;
  @media ${Themes.breakpoints.sm} {
    height: 500px;
    width: 100%;
  }
`;
export default SimpleMap;
