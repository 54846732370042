export const ToggleList01 = [
    "payroll administration",
    "employee benefits administration",
    "recruitment (some or all)",
    "training and development (some or all)",
    "information technology/human resources information systems",
    "Employee Assistance Programs/counselling",
    "superannuation",
    "retirement planning",
    "exit interviews",
    "outplacement assistance",
    "childcare/eldercare assistance",
    "equal opportunity compliance/reporting",
    "legal advice",
    "legal compliance",
    "employee relocations",
    "incentive programs",
    "administration of culture/climate surveys",
    "occupational health and safety administration",
    "employee rehabilitation/workers compensation"
  ];
  export const ToggleList02 = [
    "Reducing and Controlling Operational Costs",
    "Improving Company Focus",
    "Gaining Access To Specialist Skills not Within Their Organisation",
    "Freeing up of internal Resources for Alternative Projects",
    "The Streamlining or Increased Efficiencies with time-consuming functions",
    "Maximising Use of External Specialist Resources"
  ];
  export const ToggleList03 = [
    "On Call Hourly investment",
    "Fixed Project",
    "Retained Services"
  ];
  export const ToggleList04 = [
    "Attraction / Employee Retention Strategies and Advice",
    "Culture realignment",
    "Independent Staff Satisfaction Surveys",
    "Award interpretation Advice",
    "Change Management Support",
    "Succession Planning",
    "Employee Relations support including matters related to Fair Work Australia.",
    "Facilitation",
    "Grievances, Dispute and Incident Management Advice",
    "Termination of Employment and Redundancies",
    "HR Project Management",
    "HR Project Support",
    "Implementation of HR systems or process",
    "Instructional Design including e-learning",
    "Occupational Health and Safety Advice",
    "Performance Management Systems, Processes and Advice",
    "Development and implementation of HR Policies and Policy Advice",
    "Remuneration and Benefits Advice",
    "Remuneration and Benefits Program Structure and Implementation",
    "Incentive reward program development, forecasting and implementation"
  ];