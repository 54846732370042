import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
import {Route, Switch, Redirect} from "react-router"; // react-router v4

// HR Sense Imports
import PublicLayout from "./Layouts/PublicLayout";
import HRSenseAPI from "./Services/api/HRSenseAPI";
import AdminLayout from "./Layouts/AdminLayout";
import {GlobalStyle} from "./Assets/Styles/Style";

// Extend Formsy
import {ExtendFormsy} from "./Utilities/formsyExtensions";
import {Metadata} from "./Utilities";
ExtendFormsy();

export default class App extends Component {
  constructor() {
    super();
    App.Current = this;

    /**
     * The API for this Application
     * @typedef {HRSenseAPI}
     * */
    this.API = new HRSenseAPI();

    /**
     * App Info Metadata.
     */
    this.Info = this.API.Metadata.GetMetadata();

    // Create browser history to use in the Redux store
    const base = document.getElementsByTagName("base")[0];
    const baseUrl = base ? base.getAttribute("href") : "/";
    this.history = createBrowserHistory({basename: baseUrl});

    // Callback Storage.
    this._SignUpCallbacks = [];
    this._LoginRequestCallbacks = [];
    this._LoggedInCallbacks = [];
    this._LogoutCallbacks = [];

    // Common Info
    this.SiteInfo = {
      phoneNumber: "1300 001 101",
      fullPhoneNumber: "+61 1300 001 101"
    };

    // Check/Prefill login metadata.
    Promise.resolve(this.Info).then(info => {
      Metadata("signedIn", info.signedIn);
      if (info.signedIn) {
        Metadata("admin", info.admin);
        Metadata("email", info.email);
        Metadata("firstname", info.firstname);
        Metadata("lastname", info.lastname);

        this.LoginSuccessful();
      } else {
        // Trigger Logged Out State.
        this.TriggerLogoutState();
      }
    });
  }

  /**
   * @type {App}
   **/
  static Current;

  /**
   * Get the current User
   *
   * @returns {User}
   **/
  get CurrentUser() {
    return this.API.CurrentUser;
  }

  get UserFormData() {
    const user = this.CurrentUser;
    if (user) {
      user.surname = user.lastname;
      return user;
    }
    return null;
  }

  /**
   * Sets the Name of the Page.
   * @param {*} name
   */
  SetPageName(name = null) {
    let title = "HR Sense";
    if (name) {
      title = name + " | " + title;
    }
    document.title = title;
  }

  /**
   * Navigates the router to the provided page.
   *
   * @param {string} page Page to Navigate to
   */
  Navigate(page) {
    this.history.push(page);
  }

  /**
   * Redirects the router to the provided page.
   *
   * @param {string} page Page to Redirect to
   */
  Redirect(page) {
    this.history.replace(page);
  }

  /**
   * Request Sign Up.
   */
  RequestSignUp() {
    this._SignUpCallbacks.forEach(callback => {
      try {
        callback();
      } catch (e) {}
    });
  }

  /**
   * Request Login.
   */
  RequestLogin() {
    this._LoginRequestCallbacks.forEach(callback => {
      try {
        callback();
      } catch (e) {}
    });
  }

  /**
   * Login Successed.
   */
  LoginSuccessful() {
    this._LoggedInCallbacks.forEach(callback => {
      try {
        callback(this.CurrentUser);
      } catch (e) {}
    });
  }

  /**
   * On Sign Up Requested Callback.
   * @param {*} callback
   */
  OnSignUpRequested(callback) {
    this._SignUpCallbacks.push(callback);
  }

  /**
   * On Login Requested Callback.
   * @param {*} callback
   */
  OnLoginRequested(callback) {
    this._LoginRequestCallbacks.push(callback);
  }

  /**
   * On Logged In Callback.
   * @param {*} callback
   */
  OnLoggedIn(callback) {
    this._LoggedInCallbacks.push(callback);
  }

  /**
   * On Logged Out Callback.
   * @param {*} callback
   */
  OnLoggedOut(callback) {
    this._LogoutCallbacks.push(callback);
  }

  /**
   * Logout User and go back to Login Page.
   */
  async Logout() {
    await App.Current.API.Authentication.logout();
    this.TriggerLogoutState();
  }

  TriggerLogoutState() {
    this._LogoutCallbacks.forEach(callback => {
      try {
        callback();
      } catch (e) {}
    });
  }

  render() {
    return (
      <React.Fragment>
        <GlobalStyle />
        <Router history={this.history}>
          <div>
            <Switch>
              <Route path="/admin" component={AdminLayout} />
              <Route path="/" component={PublicLayout} />
            </Switch>
          </div>
        </Router>
      </React.Fragment>
    );
  }
}
