import React, { Component } from "react";
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter,
  TitleStyle,
  Paragraph,
  Line,
  FlexVerticalCenter
} from "../../Assets/Styles/Style";
/** Component */
import RequestButton from "../../Components/Button/RequestButton";
import PricingPanel from "./component/PricingTable";

class Section02 extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Fade>
            <Container>
              <TitleDiv>
                <h1>HR Sense Membership</h1>
                <Line />
                <p>
                  Work and engage with HR Sense in an ongoing basis through a
                  membership style that suits your immediate business
                  requirements. Full details of what you are have access to at
                  each membership tier are shown in the following.
                </p>
              </TitleDiv>
              <Contents>
                <PricingTableDiv>
                  <PricingPanel click={this.props.click}/>
                </PricingTableDiv>
                <Fade bottom>
                  <GetInTouchDiv>
                    <Text>
                      Get in touch with us now if have questions or would like
                      to discuss further which membership tier suits you best.
                    </Text>
                    <div onClick={this.props.requestACall("HR Sense")}>
                      <RequestButton title="Request a call for more information" />
                    </div>
                  </GetInTouchDiv>
                </Fade>
              </Contents>
            </Container>
          </Fade>
        </div>
      </ThemeProvider>
    );
  }
}

const Container = styled.div`
  max-width: 1000px;
  margin:0 auto;
  padding: 30px 3% 50px 3%;
      @media ${Themes.breakpoints.sm} {
      padding:87px 10% 76px 10%;
    }
      @media ${Themes.breakpoints.md} {
      padding:87px 3% 76px 3%;
    } 
    @media ${Themes.breakpoints.xl} {
      padding:87px 3% 76px 3%;
      max-width: 1366px;
    }
`;
const TitleDiv = styled.div`
  text-align: center;
  h1 {
    ${TitleStyle};
  }
  p {
    ${Paragraph};
    max-width: 980px;
    margin: 0 auto;
  }
`;
const Contents = styled.div``;
const PricingTableDiv = styled.div`
  padding: 30px 0% 25px 0%;
  ${FlexCenter};
    @media ${Themes.breakpoints.lg} {
        padding:50px 0 88px 0;
    }
`;
const GetInTouchDiv = styled.div`
  ${FlexVerticalCenter};
`;
const Text = styled.p`
  margin: 0 auto 20px auto;
  font-size: ${Themes.fontsize.p2};
  text-align: center;
  font-weight: ${Themes.fontWeight.semiBold};
  max-width: 780px;
    @media ${Themes.breakpoints.lg} {
        margin:0 auto 35px auto;
        line-height:30px;
        font-size:${Themes.fontsize.h4};
    }
`;
export default Section02;
