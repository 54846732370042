import React, {Component} from "react";
import {Link} from "react-router-dom";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  BigShadow,
  FlexCenter,
  TextShadow,
  TitleStyle,
  SubTitleStyle
} from "../../Assets/Styles/Style";

/** Images **/
import BgSmall from "../../Assets/Images/about-hero-mobile.jpg";
import BgBig from "../../Assets/Images/about-hero.jpg";

class Section01 extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Container>
            <TitleDiv>
              <h1>Privacy Policy</h1>
            </TitleDiv>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

const Container = styled.div`
    margin:0;
    background-image:url('${BgSmall}');
    height:250px;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
    color:white;
    ${FlexCenter};
      @media ${Themes.breakpoints.md} {
        background: url('${BgBig}');
        height:351px;
        background-position:center top;
        background-repeat:no-repeat;
        background-size:cover;
    }
`;
const TitleDiv = styled.div`
  ${TextShadow};
  h1 {
    ${TitleStyle};
    text-align: center;
  }
  p {
    ${SubTitleStyle};
  }
`;
export default Section01;
