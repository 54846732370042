import { withFormsy } from "formsy-react";
import React from "react";

import { ErrorMessage } from "../../Assets/Styles/Style";

// Select
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

import {
  InputDiv,
  InputSection,
  InputContainer,
  PrimaryLabel,
  FormPickerWrapper
} from "./FormStyles";

class FormPicker extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = (this.props.changeValue || this.changeValue).bind(this);

    /**
     * Created Values.
     */
    this.created = [];
  }

  render() {
    // Default to Clearable
    let isClearable = this.props.isClearable;
    if(typeof isClearable == "undefined"){
      isClearable = true;
    }

    let isSearchable = this.props.isSearchable;
    if(typeof isSearchable == "undefined"){
      isSearchable = true;
    }

    // Map Value(s) to Option(s).
    let currentValue = this.props.getValue();
    if (typeof currentValue == "undefined") {
      currentValue = this.props.multi ? [] : null;
    }
    else{
      const options = this.props.options;

      if(this.props.isMulti){
        // Collect Values.
        let values = [];
        currentValue.forEach(val => {
          const value = options.find(opt => val == opt.value);
          if(value){
            values.push(value);
          }
        });
        currentValue = values;
      }
      else{
        currentValue = options.find(opt => opt.value == currentValue);
      }
    }

    return (
      <InputContainer className={this.props.class}>
        <InputSection
          hasLabel={this.props.label}
          className="formentry-inputSection"
        >
          {this.props.label && <PrimaryLabel>{this.props.label}</PrimaryLabel>}
          <InputDiv
            horizontal={this.props.horizontal}
            className={this.props.className}
          >
            <FormPickerWrapper>
              <Selector
                name={this.props.name}
                placeholder={this.props.placeholder}
                isMulti={this.props.isMulti}
                options={this.props.options}
                isEditable={this.props.isEditable}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={this.props.disabled}
                value={currentValue}
                onChange={this.handleChange}
                getOptionLabel={(opt) => opt.label || opt.value}
              />
            </FormPickerWrapper>
          </InputDiv>
        </InputSection>
        {this.props.isFormSubmitted() && this.props.getErrorMessage() ? 
          <ErrorMessage>{this.props.isFormSubmitted() && this.props.getErrorMessage()}</ErrorMessage> : null
        }
      </InputContainer>
    );
  }

  handleChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "create-option":
        let created = newValue;

        if (this.props.isMulti) {
          created = newValue.find(
            opt =>
              opt.__isNew__ &&
              !this.created.find(created => created === opt.value)
          );
        }

        // Update Options
        this.created.push(created.value);

        if (this.props.onCreateOption) {
          this.props.onCreateOption(created.value);
        }
        break;
    }

    let result = null;
    if (this.props.isMulti) {
      result = newValue != null ? newValue.map(val => val.value) : null;
    } else {
      result = newValue != null ? newValue.value : null;
    }

    this.changeValue(result);
  };

  changeValue = value => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    this.props.setValue(value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
}

export default withFormsy(FormPicker);

function Selector(props) {
  if(props.isEditable){
    return (<CreatableSelect 
      {...props}
      classNamePrefix="fe-picker"
      className="fe-picker" />);
  }
  else{
    return (<Select 
      {...props} 
      classNamePrefix="fe-picker"
      className="fe-picker" />);
  }
}