import React from "react";
import Formsy from "formsy-react";

export default class FormHandler extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Formsy
        ref="form"
        onChange={this.props.onChange}
        onValidSubmit={this.props.onSubmit}
        onValid={this.props.onValid}
        onInvalidSubmit={this.props.onInvalid}
      >
        {this.props.children}
      </Formsy>
    );
  }

  componentDidMount() {
    if (this.props.preLoad) {
      this.loadData(this.props.preLoad);
    }
  }

  getData() {
    return this.refs.form.getModel();
  }

  loadData(state) {
    this.refs.form.reset(state);
  }

  reset() {
    this.refs.form.reset();
  }
}
