import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../Assets/Styles/Themes";

export class ServiceList extends Component {
  render() {
    const listName = this.props.listName;
    const list = listName.map((listItem, index) => (
      <li key={index}>{listItem}</li>
    ));
    return (
      <Ul>{list}</Ul>
    );
  }
}
const Ul = styled.ul`
  padding-left: 20px;
  margin: 0 auto;
    @media ${Themes.breakpoints.md} {
  margin: unset;
  }
`;
