import React, { Component } from "react";
import FormEntry from "../../../Components/Form/FormEntry";
import { ValidationErrors } from "../../../Assets/Styles/Style";

export default class AboutYou extends Component {
  render() {
    return (
      <div className="about-you">
        <FormEntry 
          label="First Name"
          name="firstname"
          placeholder="Required"
          validations="hasValue"
          validationError={ValidationErrors.required}
          class="healthcheck-form__input-container"
        />
        <FormEntry 
          label="Surname"
          name="surname"
          placeholder="Required"
          validations="hasValue"
          validationError={ValidationErrors.required}
          class="healthcheck-form__input-container"
        />
        <FormEntry
          label="Email Address"
          name="email"
          placeholder="Required"
          validations={{
            hasValue: true,
            isEmail: true
          }}
          validationErrors={{
            hasValue: ValidationErrors.required,
            isEmail: ValidationErrors.validEmailAddress
          }}
          class="healthcheck-form__input-container"
        />
        <FormEntry 
          label="Phone"
          name="phone"
          placeholder="Required"
          validations={{
            hasValue: true,
            isPhone: true
          }}
          validationErrors={{
            hasValue: ValidationErrors.required,
            isPhone: ValidationErrors.validPhoneNumber
          }}
          class="healthcheck-form__input-container"
        />
      </div>
    );
  }
}