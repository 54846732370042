import React from "react";

export default class AsyncElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result: null
    };
  }

  componentDidMount() {
    Promise.resolve(this.props.promise).then(result =>
      this.setState({ result: result })
    );
  }

  render() {
    if (!this.state.result && !this.props.promise.done) {
      return null;
    } else {
      return this.props.then(this.state.result);
    }
  }
}
