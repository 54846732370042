import React from "react";
import FormEntry from "./FormEntry";
import { ValidationErrors } from "../../Assets/Styles/Style";
import FormPicker from "./FormPicker";

export default class HSRolePicker extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            roleOther: false,
        };
    }

    render(){
        return (
        <div>
            <FormPicker
                ref="rolepicker"
                label="Choose your role"
                placeholder="Choose your role"
                name="role"
                onChange={this.roleStateChanged.bind(this)}
                validations="hasValue"
                validationError={ValidationErrors.selectOption}
                options={[
                { value: "Business Owner" },
                { value: "HR Manager" },
                { value: "HR Administrator" },
                { value: "HR Support" },
                { value: "Office Manager" },
                { value: "CFO" },
                { value: "CEO" },
                { value: "EA/PA" },
                { value: "Other" }
                ]}/>

            {this.state.roleOther &&           
            <FormEntry
                label="Other role"
                name="role"
                placeholder="Required"
                validations="hasValue"
                validationError={ValidationErrors.required}
                />}
        </div>);
    }

    roleStateChanged(val){
        if(val && val.toLowerCase() == "other"){
            this.setState({
            roleOther: true
            });
        }
        else if(this.state.roleOther){
            this.setState({
            roleOther: false
            });
        }
    }
}