import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Section01 from "./section01";
import Section02 from "./section02";
import Section03 from "./section03";
import App from "../../App";

class AboutUsScreen extends Component {
  constructor(props){
    super(props);
    App.Current.SetPageName("About");
  }

  render() {
    return (
      <Fade>
        <Section01 />
        <Section02 />
        <Section03 />
      </Fade>
    );
  }
}

export default AboutUsScreen;
