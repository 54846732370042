import React, { Component } from "react";
import { Modal } from "react-bootstrap";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter, ErrorMessage, ValidationErrors
} from "../../Assets/Styles/Style";
/** Image **/
import lockModal from "../../Assets/Images/lock_modal.svg";
import Checkmark from "../../Assets/Images/checkmark.svg";
import { ModalContent, ModalHeader, ModalSubmitButton, HighlightText, ModalBody, ButtonRow } from "./styles";
import FormHandler from "../Form/FormHandler";
import FormEntry from "../Form/FormEntry";
import App from "../../App";
import SuccessModal from "./SuccessModal";
import ScrollingLink from "../ScrollingLink";

export default class SignUpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      show: false,
      error: null
    };

    App.Current.OnSignUpRequested(() => {
      this.setState({
        show: true
      });
    });
  }

  hide = () => {
    this.setState({
      show: false,
      error: null,
      hasErrors: false,
      submissionError: false,
      submitting: false
    });
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.hide}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
        >
        <SuccessModal
          onClose={this.successAcknowledged.bind(this)}
          show={this.state.success}
        />
        <ModalContent className="sign-up-modal">
          <ModalHeader className="sign-up-modal__header" closeButton>
            <div>
              <img src={lockModal} alt="login" />
              <h5>
                Sign up <HighlightText>for FREE</HighlightText> now to unlock
              </h5>
            </div>
          </ModalHeader>
          <ModalBody>
            <FreeList>
              <div>
              <FreeListItem>
                <FreeListCheck src={Checkmark} />
                <FreeListText>
                  Access to the <span> Knowledge base </span>{" "}
                </FreeListText>
              </FreeListItem>
              <FreeListItem>
                <FreeListCheck src={Checkmark} />
                <FreeListText>
                  <span> Front of line </span> support queue{" "}
                </FreeListText>
              </FreeListItem>
              <FreeListItem>
                <FreeListCheck src={Checkmark} />
                <FreeListText>
                  Industry specific <span> News Letters </span>
                </FreeListText>
              </FreeListItem>
              </div>
            </FreeList>

            <FormHandler 
              ref="form" 
              onSubmit={this.submit.bind(this)} 
              onValid={this.onValid.bind(this)} 
              onInvalid={this.onInvalid.bind(this)}>

              <FormEntry 
                label="First Name"
                name="firstname"
                placeholder="Required"
                validations="hasValue"
                validationError={ValidationErrors.required}
              />

              <FormEntry 
                label="Surname"
                name="surname"
                placeholder="Required"
                validations="hasValue"
                validationError={ValidationErrors.required}
              />

              <FormEntry
                label="Email"
                name="email"
                placeholder="Required"
                validations={{
                  hasValue: true,
                  isEmail: true
                }}
                validationErrors={{
                  hasValue: ValidationErrors.required,
                  isEmail: ValidationErrors.validEmailAddress
                }}
              />
              
              <ButtonRow>
                <ModalSubmitButton disabled={this.state.submitting}>Sign Up</ModalSubmitButton>
                <ModalSubmitButton grey><a href="#" onClick={this.logIn} style={{color:'white'}}>or Sign in</a></ModalSubmitButton>
                {/* <a href="#" onClick={this.backToLogin} className="modal-text">Or Sign in</a> */}
              </ButtonRow>

              {this.getError() ? <ErrorMessage>{this.getError()}</ErrorMessage> : null }
              
            </FormHandler>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  getError = () => {
    if(this.state.hasErrors){
      if(this.state.error){
        return this.state.error;
      }
      else if(this.state.submissionError){
        return "An issue occurred while submitting your request. Try again later.";
      }
      else{
        return "Please fix the errors above to create an account";
      }
    }

    return "";
  }

  logIn = () =>{
    this.setState({
      show: false
    });

    App.Current.RequestLogin();
  }

  onValid(){
    this.setState({
      hasErrors: false
    });
  }

  onInvalid(){
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    try
    {
      this.setState({
        submitting: true
      });

      await App.Current.API.User.SignUp(data);
      this.refs.form.reset();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false,
        error: null
      });
    }
    catch(e){
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false,
        error: e.issue
      });
    }
  }

  successAcknowledged(){
    this.setState({
      success: false
    });
  }
}

const FreeList = styled.div`
    margin-bottom:30px; 
  ${FlexCenter};
`;

const FreeListItem = styled.p`
  font-size: ${Themes.fontsize.p3};
  text-align: left;
  margin:7px auto;
`;
const FreeListCheck = styled.img`
  width: 26px;
  margin-right: 7px;

  @media ${Themes.breakpoints.sm} {
  	margin-right: 13px;
  }
`;
const FreeListText = styled.span`
  display: inline-block;
  color: ${Themes.colors.grey};
  font-size: ${Themes.fontsize.p3};
  margin-bottom: 0px;
  span {
    font-weight: ${Themes.fontWeight.bold};
  }

  @media ${Themes.breakpoints.sm} {
    font-size: ${Themes.fontsize.p2} !important;
  }
`;
