import React, { Component } from "react";
import { Link } from "react-router-dom";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  SmallShadow,
  FlexVerticalCenter,
  FlexHorizontalCenter,
  LinkTextStyle,
  ButtonStyle,
  ClearBoth
} from "../../Assets/Styles/Style";
import Headroom from "react-headroom";
/** Images **/
import Logo from "../../Assets/Images/logo.svg";
import UserIcon from "../../Assets/Images/loginIcon.svg";
import BurgerIcon from "../../Assets/Images/burger.svg";
import phone from "../../Assets/Images/phone.svg";
/** Modals */
import ScrollingLink from "../ScrollingLink";
import SignUpModal from "../Modals/signUpModal";
import App from "../../App";
import LoginModal from "../Modals/loginModal";
import { Metadata } from "../../Utilities";
import AsyncElement from "../AsyncElement";

const HeaderLgHeight = css`
  height: 120px;
`;
class HeaderContainer extends Component {
  constructor(props, context) {
    super(props, context);

    const user = App.Current.CurrentUser;
    this.state = {
      authenticated: user ? true : false,
      admin: user && user.admin
    };

    App.Current.OnLoggedIn(user => {
      this.setState({
        authenticated: true,
        admin: user.admin
      });
    });

    App.Current.OnLoggedOut(() => {
      this.setState({
        authenticated: false,
        admin: false
      });
    });
  }

  render() {
    const siteInfo = App.Current.SiteInfo;

    return (
      <ThemeProvider theme={Themes}>
        <div>
          <LoginModal />
          <SignUpModal />
          <Headroom style={{ zIndex: 1000 }}>
            <Nav>
              <Container>
                <NavLeft>
                  <LogoDiv>
                    <LinkedLogo to="/">
                      <LogoImg src={Logo} />
                    </LinkedLogo>
                  </LogoDiv>
                  <PhoneDiv>
                    <PhoneIcon src={phone} />
                    <PhoneNumber href={`tel:${siteInfo.fullPhoneNumber}`}>
                      {siteInfo.phoneNumber}
                    </PhoneNumber>
                  </PhoneDiv>
                </NavLeft>
                <ClearBoth />
                <NavWide>
                  <WideDiv>
                    <StyledLink to="/">Home</StyledLink>
                    <StyledLink to="/ourservices">Our Services</StyledLink>
                    <StyledLink to="/about">About</StyledLink>
                    <StyledLink to="/knowledgebase">Knowledge Base</StyledLink>
                    <StyledLink to="/contactus">Contact Us</StyledLink>
                    {this.state.admin && (
                      <StyledLink to="/admin">Admin</StyledLink>
                    )}
                    <Login>
                      {!this.state.authenticated && (
                        <LoginDiv onClick={() => App.Current.RequestLogin()}>
                          <LoginIcon src={UserIcon} />
                          <div>Login</div>
                        </LoginDiv>
                      )}
                      {this.state.authenticated && (
                        <LoginDiv onClick={() => App.Current.Logout()}>
                          <LoginIcon src={UserIcon} />
                          <div>Log Out</div>
                        </LoginDiv>
                      )}
                    </Login>
                  </WideDiv>
                </NavWide>
                <NavNarrow>
                  <BurgerDiv>
                    <Burger src={BurgerIcon} onClick={this.burgerToggle} />
                  </BurgerDiv>
                  <NarrowLinks className="narrowLinks">
                    <NarrowStyledLink onClick={this.burgerToggle} to="/">
                      Home
                    </NarrowStyledLink>
                    <NarrowStyledLink
                      onClick={this.burgerToggle}
                      to="/ourservices"
                    >
                      Our Services
                    </NarrowStyledLink>
                    <NarrowStyledLink onClick={this.burgerToggle} to="/about">
                      About
                    </NarrowStyledLink>
                    <NarrowStyledLink
                      onClick={this.burgerToggle}
                      to="/knowledgebase"
                    >
                      Knowledge Base
                    </NarrowStyledLink>
                    <NarrowStyledLink
                      onClick={this.burgerToggle}
                      to="/contactus"
                    >
                      Contact Us
                    </NarrowStyledLink>
                    {this.state.admin && (
                      <NarrowStyledLink onClick={this.burgerToggle} to="/admin">
                        Admin
                      </NarrowStyledLink>
                    )}
                    <Login>
                      {!this.state.authenticated && (
                        <LoginDiv onClick={() => App.Current.RequestLogin()}>
                          <LoginIcon src={UserIcon} />
                          <div>Login</div>
                        </LoginDiv>
                      )}
                      {this.state.authenticated && (
                        <LoginDiv onClick={() => App.Current.Logout()}>
                          <LoginIcon src={UserIcon} />
                          <div>Log Out</div>
                        </LoginDiv>
                      )}
                    </Login>
                  </NarrowLinks>
                </NavNarrow>
              </Container>
            </Nav>
          </Headroom>
        </div>
      </ThemeProvider>
    );
  }
  burgerToggle = () => {
    let linksEl = document.querySelector(".narrowLinks");
    if (linksEl.style.display === "flex") {
      linksEl.style.display = "none";
      linksEl.style.height = "auto";
    } else {
      linksEl.style.display = "flex";
      linksEl.style.justifyContent = "center";
      linksEl.style.flexDirection = "column";
      linksEl.style.height = "auto";
    }
  };
}

const Nav = styled.div`
  ${SmallShadow};
  text-transform: uppercase;
  font-weight: ${Themes.fontWeight.bold};
  padding: 10px 8% 13px 8%;
  background-color: white;
  a:hover,
  a:active {
    color: ${Themes.colors.blue};
  }

  @media ${Themes.breakpoints.lg} {
    ${HeaderLgHeight};
    padding: 0 2%;
  }
`;
const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const NavNarrow = styled.div`
  display: block;
  width: 100%;

  @media ${Themes.breakpoints.lg} {
    display: none;
  }
`;
const NavWide = styled.div`
  display: none;

  @media ${Themes.breakpoints.lg} {
    display: flex;
    ${HeaderLgHeight};
  }
`;
const WideDiv = styled.div`
  ${FlexHorizontalCenter};
`;
/** Login Button **/
const Login = styled.div`
  padding: 0.5em;
  font-size: 16px;

  @media ${Themes.breakpoints.lg} {
    padding: 0;
    margin-left: 10px;
  }
`;
const LoginDiv = styled.button`
  ${ButtonStyle};
  background-color: ${Themes.colors.pink};
  width: auto;
  padding: 0 14px;
  height: 30px !important;
  margin: 0 auto;
`;
const LoginIcon = styled.img`
  width: 21px;
  height: 21px;
  padding-right: 4px;
`;
/** NavLeft **/
const NavLeft = styled.div`
  /* position absolute to fix the div on top when the bugger icon toggled */
  position: absolute;
  ${FlexHorizontalCenter};

  @media ${Themes.breakpoints.lg} {
    ${HeaderLgHeight};
  }
`;
/** Logo **/
const LogoDiv = styled.div`
  ${FlexVerticalCenter};
`;
const LinkedLogo = styled(ScrollingLink)`
  height: 42px;
  display: inline-block;

  @media ${Themes.breakpoints.lg} {
    height: auto;
  }
`;
const LogoImg = styled.img`
  width: 80px;

  @media ${Themes.breakpoints.lg} {
    width: 160px;
  }
`;
/** Phone **/
const PhoneDiv = styled.div`
  ${FlexHorizontalCenter};
  margin-left: 10px;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${Themes.colors.darkBlue};
  }

  @media ${Themes.breakpoints.lg} {
    margin-left: 23px;
    display: flex;
  }
`;
const PhoneIcon = styled.img`
  width: 16px;
  height: auto;
  margin-right: 3px;

  @media ${Themes.breakpoints.lg} {
    width: 28px;
    margin-right: 6px;
  }
`;
const PhoneNumber = styled.a`
  color: ${Themes.colors.darkBlue};
  font-size: 16px;
  width: 100px;
  margin-bottom: 5px;

  @media ${Themes.breakpoints.lg} {
    font-size: 28px;
    ${FlexVerticalCenter};
    width: 200px;
  }
`;
/** Links **/
const StyledLink = styled(ScrollingLink)`
  ${LinkTextStyle};
  padding: 1em;
  cursor: "pointer";

  @media ${Themes.breakpoints.lg} {
    padding: 0 10px;
    ${FlexVerticalCenter}
  }

  display: ${props => (props.admin ? "none" : "block")};
`;
const NarrowLinks = styled.div`
  position: static;
  display: none;
  margin: 3.5em 0 0;
`;
const NarrowStyledLink = styled(ScrollingLink)`
  ${LinkTextStyle};
  cursor: "pointer";
  padding: 0.5em;
  display: ${props => (props.admin ? "none" : "block")};
  text-align: center;
  &:last-child {
    border-bottom: 0px;
  }
`;
const BurgerDiv = styled.div`
  height: 42px;
  float: right;
  display: flex;
  align-items: center;
`;
const Burger = styled.img`
  width: auto;
  height: 20px;
  cursor: pointer;
`;

export default HeaderContainer;
