import React, { Component } from "react";
/** Style **/
import styled from "styled-components";

import Themes from "../../../Assets/Styles/Themes";
import { FlexHorizontalJustify } from "../../../Assets/Styles/Style";

import ToggleBtn from "../../../Components/Toggle/responsiveToggleBtn";
import arrowWhite from "../../../Assets/Images/arrow-white.svg";

export default class TogglePanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false
    };
  }
  toggleShowHandler = () => {
    this.setState(prevState => {
      return { show: !prevState.show };
    });
  };
  render() {
    const listName = this.props.listName;
    const list = listName.map((listItem, index) => (
      <li key={index}>{listItem}</li>
    ));
    let borderClass = "";
    let rotateClass = "";
    if (this.state.show) {
      borderClass = "borderBottomNone";
      rotateClass = "rotate";
    }
    return (
      <ToggleDiv>
        <TitleDiv onClick={this.toggleShowHandler} className={borderClass}>
          <p>{this.props.title}</p>
          <ToggleBtn className={rotateClass} show={this.state.show} color="light" />
        </TitleDiv>
        {this.state.show && (
          <ListDiv>
            <ul>{list}</ul>
          </ListDiv>
        )}
      </ToggleDiv>
    );
  }
}

const ToggleDiv = styled.div`
  border-radius: 10px;
  margin-bottom:14px;
  .borderBottomNone {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rotateClass {
    transform: rotate(45deg);
  }
`;
const TitleDiv = styled.div`
  background: ${Themes.colors.veryLightBlue};
  ${FlexHorizontalJustify};
  color: white;
  font-weight: ${Themes.fontWeight.bold};
  padding: 1em;
    @media ${Themes.breakpoints.sm} {
    padding: 1.5em;
  }
  border-radius:10px;
  p {
    width: 90%;
    font-size: 17px;
    font-weight:${Themes.fontWeight.semiBold};
      @media ${Themes.breakpoints.lg} {
        font-size: 20px;
    }
  }
  cursor: pointer !important;
  &:hover {
    background: ${Themes.colors.lightBlue};
  }
`;
const ListDiv = styled.div`
  padding: 1em;
    @media ${Themes.breakpoints.lg} {
    padding: 1em 1.5em;
  }
  ul {
    padding-left: 20px;
    li {
      margin: 10px 0;
      font-size: 15px;
      font-weight: ${Themes.fontWeight.medium};
        @media ${Themes.breakpoints.lg} {
        font-size: 18px;
      }
    }
  }
  border: 1px solid ${Themes.colors.veryLightBlue};
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
