import React, {Component} from "react";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  BigShadow,
  FlexCenter,
  TextShadow,
  TitleStyle,
  SubTitleStyle,
  ContainerStyle
} from "../../Assets/Styles/Style";

/** Images **/
import BgSmall from "../../Assets/Images/knowledge-base-hero-mobile.jpg";
import BgBig from "../../Assets/Images/knowledge-base-hero.jpg";

export default class KnowledgeBaseHeader extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Section>
          <Container>
            <TitleDiv>
              <h1>Knowledge Base</h1>
              <p>
                Exclusive content only available to our HR Sense <br />
                members containing up to date, relevant information for <br />
                essential HR related business information.
              </p>
            </TitleDiv>
          </Container>
        </Section>
      </ThemeProvider>
    );
  }
}
const Section = styled.div`
  margin:0;
  background-image:url('${BgSmall}');
  height:250px;
  background-position:center top;
  background-repeat:no-repeat;
  background-size:cover;
  color:white;
  ${FlexCenter};

  @media ${Themes.breakpoints.md} {
    background: url('${BgBig}');
    height:351px;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
  }
`;
const Container = styled.div`
  ${ContainerStyle};
`;
const TitleDiv = styled.div`
  ${TextShadow};
  h1 {
    ${TitleStyle};
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    display: none;
  }

  @media ${Themes.breakpoints.md} {
    p {
      display: block;
      ${SubTitleStyle};
    }
  }
`;
