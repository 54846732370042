import React, { Component } from "react";
import Fade from "react-reveal/Fade";

/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter,
  TextShadow,
  TitleStyle,
  SubTitleStyle,
  ContainerStyle,
  SmallShadow,
  BackgroundDefaultStyle,
  FlexVerticalCenter,
  ValidationErrors,
  ButtonStyle,
  BlueGradient
} from "../../Assets/Styles/Style";
/** Images **/
import BgSmall from "../../Assets/Images/contact-hero-mobile.jpg";
import BgBig from "../../Assets/Images/contact-hero.jpg";

/** Map */
import FormHandler from "../../Components/Form/FormHandler";
import FormEntry from "../../Components/Form/FormEntry";
import SetPasswordForm from "./component/form";
import ErrorScreen from "../Error";
import App from "../../App";

export default class SetPassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      validated: false,
      failed: false,
      userinfo: null
    };

    App.Current.SetPageName("Set Your Password");

    const params = new URLSearchParams(window.location.search);
    this.token = params.get('token');
    this.validate();
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
      <div>
      {this.state.validated && <div>
        <Fade>
            <div>
              <Section>
                <Container>
                  <TitleDiv>
                    <h1>Hi {this.state.userinfo.firstname},</h1>
                    <h2>Set Your Password</h2>
                  </TitleDiv>
                  <FormDiv>
                    <SetPasswordForm token={this.token} userinfo={this.state.userinfo} />
                  </FormDiv>
                </Container>
                <Background>&nbsp;</Background>
              </Section>
            </div>
          </Fade>
          </div>}
        {this.state.failed && <ErrorScreen errorcode="401"/>}
      </div>
      </ThemeProvider>
    );
  }

  async validate(){
    try{
      const info = await App.Current.API.User.GetUserFromToken(this.token);
      this.setState({
        validated: true,
        userinfo: info
      });
    }
    catch(e){
      this.setState({ failed: true });
    }
  }
}

const Section = styled.div`
  position: relative;
    @media ${Themes.breakpoints.md} {
    margin-bottom:300px;
  }
`;
const Background = styled.div`
  width: 100%;
  height: 250px;
  background-image: url(${BgSmall});
  ${BackgroundDefaultStyle};
    @media ${Themes.breakpoints.md} {
    background-image:url(${BgBig});
    width:100%;
    height:576px;
  }
  z-index:-1;
  position: absolute;
  top: 0;
`;
const Container = styled.div`
  ${ContainerStyle};
  display: grid;
    @media ${Themes.breakpoints.md} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8%;
        margin:0 10%;
    }
  @media ${Themes.breakpoints.xl} {
        margin:0 auto 120px auto;
    }
`;
const TitleDiv = styled.div`
  /* match to background size */
  width: 100%;
  height: 250px;

  /* Text style */
  ${FlexCenter}
  ${TextShadow}
  color: white;
  h1 {
    ${TitleStyle};
    text-align: center;
  }
  p {
    display: none;
  }
  * {
    margin-right: 10px;
  }
  max-width: 535px;
  margin: 0 auto;
    @media ${Themes.breakpoints.md} {

    // match to background size
    width:100%;
    height:576px;

    // Text style
    flex-direction:column;
    align-items:flex-start;
    h1{
      text-align:left;
      margin-bottom:17px;
    }
    p{
     display:block;
     ${SubTitleStyle};
      text-align:left;
    }
  }
`;
const FormDiv = styled.div`
  ${FlexVerticalCenter};
  width: 100%;
  background-color: white;
  padding: 40px 8%;
    @media ${Themes.breakpoints.md} {
      border-radius:10px;
      ${SmallShadow};
      transform:translateY(176px);
      padding:50px 40px;
  }
    @media ${Themes.breakpoints.lg} {
    padding:54px 49px;
  }
`;