const Themes = {
  colors: {
    blue: "#1955A6",
    lightBlue: "#6086B9",
    veryLightBlue: "#B1C6D3",
    darkBlue: "#162C47",
    pink: "#DE7281",
    black: "#333333",
    grey: "#707070",
    lightGrey: "#B4B4B4",
    veryLightGrey: "#F8F8F8",
    veryLightBlueGrey: "#F7F9FA",
    darkGrey: "#3C3C3C",
    white: "#FFFFFF",
    green: "#217346",
    lightGreen: "#619B7B"
  },
  fontsize: {
    h1: "40px",
    h2: "35px",
    h3: "30px",
    h4: "25px",
    h5: "20px",
    p1: "20px",
    p2: "18px",
    p3: "15px",
    p4: "12px"
  },
  fontWeight: {
    extraLight: "200",
    light: "300",
    regular: "400",
    medium: "500",
    semiBold: "600",
    bold: "700",
    black: "900"
  },
  breakpoints: {
    xs: "(min-width: 575px)",
    sm: "(min-width: 768px)",
    md: "(min-width: 991px)",
    lg: "(min-width: 1199px)",
    xl: "(min-width: 1690px)"
  }
};
export default Themes;
