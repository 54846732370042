import React, { Component } from "react";
import Section01 from "./section01";
import Section02 from "./section02";
import Section03 from "./section03";
import Section04 from "./section04";
import Section05 from "./section05";
import RequestACallModal from "../../Components/Modals/requestACallModal";
import { ScrollToComponent } from "../../Utilities";
import App from "../../App";

class OurServicesScreen extends Component {
  constructor(props){
    super(props);

    this.state = {
      showCallModal: false,
      serviceCallRequest: "HR Sense"
    };

    App.Current.SetPageName("Our Services");
  }

  requestACall(service) {
    return () => {
      this.setState({
        serviceCallRequest: service
      }, () => {
        this.refs.callrequest.Show();
      });
    };
  }

  render() {
    return (
      <div>
        <RequestACallModal ref="callrequest" service={this.state.serviceCallRequest} />
        <Section01 />
        <Section02
          requestACall={this.requestACall.bind(this)}
          click={() =>
            ScrollToComponent(this.Section03, {
              offset: 0,
              align: "top",
              duration: 1000
            })
          }
        />
        <Section03
          className="section03"
          ref={section => {
            this.Section03 = section;
          }}
        />
        <Section04 />
        <Section05 requestACall={this.requestACall.bind(this)} />
      </div>
    );
  }
}

export default OurServicesScreen;
