import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { ProgressBar } from "react-bootstrap";
import App from "../../App";
import Formsy from "formsy-react";

/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  SmallShadow,
  FlexCenter,
  TextShadow, 
  TitleStyle,
  SubTitleStyle,
  ContainerStyle,
  BackgroundDefaultStyle,
  ButtonStyle,
  FlexHorizontalCenter,
  FlexVerticalCenter ,
  ErrorMessage
} from "../../Assets/Styles/Style";
import checkmark from "../../Assets/Images/checkmark-white.svg";

/** Images **/
import BgSmall from "../../Assets/Images/healthcheck-hero-mobile.jpg";
import BgBig from "../../Assets/Images/healthcheck-hero.jpg";
import AboutYou from "./steps/AboutYou";
import AboutYourBusiness from "./steps/AboutYourBusiness";
import PotentialRisks from "./steps/PotentialRisks";
import AllDone from "./steps/AllDone";
import FormHandler from "../../Components/Form/FormHandler";

export default class HealthCheckScreen extends Component {
  constructor(props){
    super(props);

    this.steps = [
      {
        type: 'aboutyou',
        data: {}
      },
      {
        type: 'aboutyourbusiness',
        data: {}
      },
      {
        type: 'potentialrisks',
        data: {}
      }
    ];

    this.state = {
      step: 1,
      hasErrors: false,
      submissionError: false,
      changedPage: false,
      submitting: false
    };

    App.Current.SetPageName("Request a Business Health Check");
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <div>
            <Section>
              <Container>
                <TitleDiv>
                  <h1>Business Healthcheck</h1>
                  <p>Is your business compliant with Fair Work Regulations?<br/>
                      Are you a SME with no HR Department?<br/>
                      Find Out If Your Business is at Risk.</p>
                </TitleDiv>
                <FormContainer>
                  <FormSection>
                    <StepsDiv>
                      <Steps>
                        <StyledProgressBar now={this.calculateProgress()}/>
                        <StepCircleDiv first>
                          <StepCircle visited={this.isVisited(1)}>
                            <span>1</span>
                          </StepCircle>
                          <StepTitle>A bit about you</StepTitle>
                        </StepCircleDiv>
                        <StepCircleDiv second>
                          <StepCircle visited={this.isVisited(2)}>
                            <span>2</span>
                          </StepCircle>
                          <StepTitle>A bit about your business</StepTitle>
                        </StepCircleDiv>
                        <StepCircleDiv third>
                          <StepCircle visited={this.isVisited(3)}>
                            <span>3</span>
                          </StepCircle>
                          <StepTitle>Your potential risks</StepTitle>
                        </StepCircleDiv>
                        <StepCircleDiv fourth>
                          <StepCircle visited={this.isVisited(4)}>
                            <img src={checkmark} alt="checkmark-done" />
                          </StepCircle>
                          <StepTitle last>All Done!</StepTitle>
                        </StepCircleDiv>
                      </Steps>
                    </StepsDiv>

                    <FormDataContainer>
                      <FormHandler 
                        ref="form" 
                        preLoad={App.Current.UserFormData}
                        onSubmit={this.nextStep.bind(this)} 
                        onValid={this.onValid.bind(this)} 
                        onInvalid={this.onInvalid.bind(this)}>

                        {this.getStep()}

                        <ButtonDiv>
                          {this.state.step > 1 && this.state.step < 4 && <Button type="button" onClick={this.previousStep.bind(this)} prev>Previous</Button>}
                          <Button disabled={this.state.submitting}>{this.nextButtonText()}</Button>
                        </ButtonDiv>

                        {this.state.hasErrors ?
                          <ErrorMessage>
                            {this.state.submissionError ? "An issue occurred while submitting your request. Try again later." : "Please fix the errors above to continue"}
                          </ErrorMessage>
                        :null}

                      </FormHandler>
                    </FormDataContainer>
                  </FormSection>
                </FormContainer>
              </Container>
              <Background>&nbsp;</Background>
            </Section>
          </div>
        </Fade>
      </ThemeProvider>
    );
  }

  calculateProgress(){
    switch(this.state.step){
      case 1:
        return 18;

      case 2:
        return 48;

      case 3:
        return 72;

      case 4:
        return 100;
    }

    return 0;
  }

  isVisited(step){
    return this.state.step >= step;
  }

  getStepData(step = null){
    step = step || this.state.step;
    return this.steps[step - 1];
  }

  componentDidUpdate(){
    if(this.state.changedPage && this.state.step < 4){
      const stepData = this.getStepData();
      this.refs.form.loadData(stepData.data);

      this.setState({
        changedPage: false
      });
    }
  }

  previousStep(){
    if(this.state.step > 1){
      this.setState((prevState, props) => {
        const prev = prevState.step - 1;
        const stepData = this.getStepData();
        stepData.data = this.refs.form.getData();

        return {
          step: prev,
          changedPage: true
        };
      });
    }
  }

  nextStep(data) {
    if (this.state.step < 4) {
      const stepData = this.getStepData();
      stepData.data = data;

      const Next = () => {
        this.setState((prevState, props) => {
          const next = prevState.step + 1;
          return {
            step: next,
            changedPage: true,
            hasErrors: false,
            submissionError: false,
            submitting: false
          };
        });
      };

      if(this.state.step == 3){
        this.submit(Next);
      }
      else{
        Next();
      }
    }
    else{
      App.Current.Navigate("/");
    }
  }

  onValid(){
    this.setState({
      hasErrors: false
    });
  }

  onInvalid(){
    this.setState({
      hasErrors: true
    });
  }

  async submit(callback){
    const dataSets = this.steps
      .map(step => step.data);
    const data = Object.assign({}, ...dataSets);

    try{
      this.setState({
        submitting: true
      })

      await App.Current.API.Contact.HealthCheck(data);
      callback();
    }
    catch(e){
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }

  getStep(){
    switch(this.state.step){
      case 1:
        return <AboutYou ref="page" {...this.state} />;

      case 2:
        return <AboutYourBusiness ref="page" {...this.state} />;

      case 3:
        return <PotentialRisks ref="page" {...this.state} />;

      case 4:
        return <AllDone ref="page" {...this.state} />;
    }
  }

  nextButtonText(){
    switch(this.state.step){
      case 3:
        return "Let Us Check Your Health";

      case 4:
        return "Okay";

      default:
        return "Next";
    }
  }
}

export const WideInputs = styled.div`
  .formentry-inputSection{
      @media ${Themes.breakpoints.sm} {
      grid-template-columns: 2fr 1.5fr;
      margin-left:auto; 
      margin-right:0;
    }
  }
`;

const Section = styled.div`
  position:relative;
  width: 100%;
    @media ${Themes.breakpoints.md} {
    margin-bottom:10px;
  }
`;

const FormSection = styled.div`
  ${FlexVerticalCenter};
  width: 100%;
`;

const Background = styled.div`
  width:100%;
  height:250px;
  background-image:url(${BgSmall});
  ${BackgroundDefaultStyle};
      @media ${Themes.breakpoints.md} {
    background-image:url(${BgBig});
    width:100%;
    height:576px;
  }
  z-index:-1;
  position:absolute;
  top:0;
`;
const Container = styled.div`
    ${ContainerStyle};
    @media ${Themes.breakpoints.md} {
        margin:0 10%;
    }
    @media ${Themes.breakpoints.xl} {
        margin:0 auto;
    }
`;
const TitleDiv = styled.div`
  /* match to background size */
  width:100%;
  height:250px;
  padding:0 5%;

  /* Text style */
  ${FlexCenter}
  flex-direction:column;
  ${TextShadow};
  color:white;
  h1{
    ${TitleStyle};
    text-align:center;
  }
  p{
    display:none;
  }
  margin:0 auto;
  @media ${Themes.breakpoints.md} {
    // match to background size
    width:100%;
    height:576px;

    // Text style
    h1{
      margin-bottom:17px;
    }
    p{
     display:block;
     ${SubTitleStyle};
    }
    transform:translateY(-40px);
  }
`;
const FormContainer = styled.div`
    ${FlexCenter};
    margin:0 auto;
    width:100%;
    background-color:white;
    padding:40px 5%;
    max-width:1088px;
    @media ${Themes.breakpoints.md} {
      border-radius:10px;
      ${SmallShadow};
      transform:translateY(-140px);
      padding:50px 40px;
  }
  @media ${Themes.breakpoints.lg} {
    padding:70px 49px 54px 49px;
  }
`;
const StepsDiv = styled.div`
  margin-bottom: 70px;
    @media ${Themes.breakpoints.md} {
    margin-bottom: 120px;    
  }
`;
const Steps = styled.div`
  position: relative;
  max-width: 934px;
  margin: 0 auto;
`;

const StepCircleDiv = styled.div`
    position:absolute;
    top: -12.5px;
    ${props =>
      props.first &&
      css`
        left: 12.5%;
        @media ${Themes.breakpoints.sm} {
            left: 17%;
        }
      `}
    ${props =>
      props.second &&
      css`
        left: 38.5%;
        @media ${Themes.breakpoints.sm} {
            left: 44%;
        }
      `}
    ${props =>
      props.third &&
      css`
        left: 64.25%;
        @media ${Themes.breakpoints.sm} {
          left: 70%;
        }
      `}
    ${props =>
      props.fourth &&
      css`
        right: 0;
      `}
`;
const StepCircle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${props => props.visited ? `${Themes.colors.blue}` : "#ECEEEF"};
  ${FlexCenter};
  span {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: ${Themes.fontsize.h4};
    font-weight: ${Themes.fontWeight.bold};
  }
  img {
    width: 22px;
    height: auto;
    margin: 3px 0 0 1px;
  }
`;
const StepTitle = styled.p`
  text-align: center;
  font-size: ${Themes.fontsize.p4};
  line-height: 14px;
  margin-top: 10px;
  font-weight: ${Themes.fontWeight.bold};
  width: ${props => (props.last ? "30px" : "78px")};
  transform: ${props => (props.last ? "translateX(0)" : "translateX(-22px)")};

  @media ${Themes.breakpoints.sm} {
    font-size: ${Themes.fontsize.p3};
    width: ${props => (props.last ? "30px" : "100px")};
    transform: ${props => (props.last ? "translateX(0)" : "translateX(-32px)")};
    text-align: ${props => (props.last ? "right" : "center")};
    line-height: 18px;
  }
`;
const StyledProgressBar = styled(ProgressBar)`
  height: 6.5px;
  border-radius: 4px;
  margin-right: 8px;
  .progress-bar {
    background-color: ${Themes.colors.blue};
  }
`;

const FormDataContainer = styled.div`
  width: 100%;
  margin:0 auto;
  ${FlexVerticalCenter};
    @media ${Themes.breakpoints.lg} {
        width:812px;
  }
`;

const ButtonDiv = styled.div`
  ${FlexCenter};
  margin: 45px auto 0 auto;
`;
const Button = styled.button`
  ${ButtonStyle};
  padding: 15px 24px;
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.bold};
  margin-right: ${props => (props.prev ? "10px" : 0)};
  background: ${props => (props.prev ? "linear-gradient(#B4B4B4,#969696)" : "linear-gradient(#6086B9,#1955A6)")};
`;
