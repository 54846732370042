import React, { Component } from "react";
import Fade from "react-reveal/Fade";

/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter,
  TextShadow,
  TitleStyle,
  SubTitleStyle,
  ContainerStyle,
  SmallShadow,
  BackgroundDefaultStyle,
  FlexVerticalCenter
} from "../../Assets/Styles/Style";
/** Images **/
import BgSmall from "../../Assets/Images/contact-hero-mobile.jpg";
import BgBig from "../../Assets/Images/contact-hero.jpg";

/** Map */
import SimpleMap from "./component/map";
import ContactForm from "./component/form";
import App from "../../App";

export default class ContactUsScreen extends Component {
  constructor(props){
    super(props);
    App.Current.SetPageName("Contact Us");
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <div>
            <Section>
              <Container>
                <TitleDiv>
                  <h1>Contact Us</h1>
                  <p>
                    Contact us for advice, to set up a meeting request or make a
                    general enquiry and one of our highly skilled team members
                    will be in touch with you as soon as possible
                  </p>
                </TitleDiv>
                <FormDiv>
                  <ContactForm />
                </FormDiv>
                </Container>
              <Background>&nbsp;</Background>
            </Section>
            <SimpleMap />
          </div>
        </Fade>
      </ThemeProvider>
    );
  }
}

const Section = styled.div`
  position: relative;
    @media ${Themes.breakpoints.md} {
    margin-bottom:300px;
  }
`;
const Background = styled.div`
  width: 100%;
  height: 250px;
  background-image: url(${BgSmall});
  ${BackgroundDefaultStyle};
    @media ${Themes.breakpoints.md} {
    background-image:url(${BgBig});
    width:100%;
    height:576px;
  }
  z-index:-1;
  position: absolute;
  top: 0;
`;
const Container = styled.div`
  ${ContainerStyle};
  display: grid;
    @media ${Themes.breakpoints.md} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8%;
        margin:0 10%;
    }
  @media ${Themes.breakpoints.xl} {
        margin:0 auto 120px auto;
    }
`;
const TitleDiv = styled.div`
  /* match to background size */
  width: 100%;
  height: 250px;

  /* Text style */
  ${FlexCenter}
  ${TextShadow}
  color: white;
  h1 {
    ${TitleStyle};
    text-align: center;
  }
  p {
    display: none;
  }
  max-width: 535px;
  margin: 0 auto;
    @media ${Themes.breakpoints.md} {

    // match to background size
    width:100%;
    height:576px;

    // Text style
    flex-direction:column;
    align-items:flex-start;
    h1{
      text-align:left;
      margin-bottom:17px;
    }
    p{
     display:block;
     ${SubTitleStyle};
      text-align:left;
    }
  }
`;
const FormDiv = styled.div`
  ${FlexVerticalCenter};
  width: 100%;
  background-color: white;
  padding: 40px 8%;
    @media ${Themes.breakpoints.md} {
      border-radius:10px;
      ${SmallShadow};
      transform:translateY(176px);
      padding:50px 40px;
  }
    @media ${Themes.breakpoints.lg} {
    padding:54px 49px;
  }
`;

const FollowUs = styled.div`
  border-bottom: 1px solid white;
  color: white;
  h5 {
    font-weight: ${Themes.fontWeight.bold};
    span {
      font-weight: ${Themes.fontWeight.light};
    }
    ${TextShadow};
  }
`;