import React, {Component} from "react";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../../Assets/Styles/Themes";
import {ErrorMessage, SubmitButton} from "../../../Assets/Styles/Style";
import {ValidationErrors} from "../../../Assets/Styles/Style";
import FormHandler from "../../../Components/Form/FormHandler";
import FormEntry from "../../../Components/Form/FormEntry";
import App from "../../../App";
import SuccessModal from "../../../Components/Modals/SuccessModal";
import HSRolePicker from "../../../Components/Form/HSRolePicker";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false
    };
  }

  render() {
    return (
      <FormContainer>
        <SuccessModal
          show={this.state.success}
          onClose={this.successAcknowledged.bind(this)}
        />

        <FormHandler
          ref="form"
          preLoad={App.Current.UserFormData}
          onSubmit={this.submit.bind(this)}
          onValid={this.onValid.bind(this)}
          onInvalid={this.onInvalid.bind(this)}>
          <FormEntry
            label="First Name"
            name="firstname"
            placeholder="Required"
            validations="hasValue"
            validationError={ValidationErrors.required}
          />

          <FormEntry
            label="Surname"
            name="surname"
            placeholder="Required"
            validations="hasValue"
            validationError={ValidationErrors.required}
          />

          <FormEntry
            label="Email"
            name="email"
            placeholder="Required"
            validations={{
              hasValue: true,
              isEmail: true
            }}
            validationErrors={{
              hasValue: ValidationErrors.required,
              isEmail: ValidationErrors.validEmailAddress
            }}
          />

          <FormEntry
            label="Phone"
            name="phone"
            placeholder="Required"
            validations={{
              hasValue: true,
              isPhone: true
            }}
            validationErrors={{
              hasValue: ValidationErrors.required,
              isPhone: ValidationErrors.validPhoneNumber
            }}
          />

          <FormEntry
            label="Company"
            name="company"
            placeholder="Required"
            validations="hasValue"
            validationError={ValidationErrors.required}
          />

          <HSRolePicker />

          <FormEntry
            label="Message"
            name="message"
            type="textarea"
            placeholder="Required"
            characterLimit="300"
            validations={{
              hasValue: true,
              maxLength: 300
            }}
            validationErrors={{
              hasValue: ValidationErrors.required,
              maxLength: "This field has a maximum of 300 characters only"
            }}
          />

          <SubmitButton disabled={this.state.submitting}>
            Send Us a Message
          </SubmitButton>

          {this.state.hasErrors ? (
            <ErrorMessage>
              {this.state.submissionError
                ? "An issue occurred while submitting your request. Try again later."
                : "Please fix the errors above to continue"}
            </ErrorMessage>
          ) : null}
        </FormHandler>
      </FormContainer>
    );
  }

  onValid() {
    this.setState({
      hasErrors: false
    });
  }

  onInvalid() {
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    try {
      this.setState({
        submitting: true
      });

      data["location"] = "Sydney";
      await App.Current.API.Contact.ContactUs(data);
      this.refs.form.reset();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false
      });
    } catch (e) {
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }

  successAcknowledged() {
    this.setState({
      success: false
    });
  }
}

const FormContainer = styled.div`
  width: 100%;
`;
