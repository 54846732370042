import React, { Component } from "react";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../../Assets/Styles/Themes";
import {
  FlexCenter,
  TextShadow,
  BigShadow,
  LabelModal,
  InputFieldModal,
  ButtonStyle,
  BlueGradient
} from "../../../Assets/Styles/Style";
import App from "../../../App";

export default class KBLoginScreen extends Component {
  render() {
    return (
      <Container thumb={this.props.thumb}>
        <Header>
          <h3>
            <b>Login</b> or <b>Sign Up</b> to access this content.
          </h3>
        </Header>
        <Footer>
          <ButtonSubmit
            type="button"
            onClick={() => App.Current.RequestLogin()}
          >
            Login
          </ButtonSubmit>
          <ButtonSubmit
            type="button"
            onClick={() => App.Current.RequestSignUp()}
            grey
          >
            Sign Up
          </ButtonSubmit>
        </Footer>
      </Container>
    );
  }
}
const Container = styled.div`
  ${FlexCenter};
  flex-direction: column;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  @media ${Themes.breakpoints.md} {
    height: 500px;
  }
`;
const Header = styled.div`
  h3 {
    color: white;
    ${TextShadow};
    text-align: center;
  }
  margin: 1em;
  width: auto !important;
  @media ${Themes.breakpoints.md} {
    margin-bottom: 2em;
  }
`;
const Body = styled.div``;
const Form = styled.form``;
const InputSection = styled.div`
  display: grid;
  @media ${Themes.breakpoints.sm} {
    grid-template-columns: 1fr 2fr;
    margin-top: 0px;
    padding: 0 10%;
  }
  @media ${Themes.breakpoints.md} {
    padding: 0 20%;
  }
  label {
    ${LabelModal};
    margin-bottom: 0px;
    line-height: 40px;
    @media ${Themes.breakpoints.sm} {
      margin-top: 0px;
      margin-bottom: 12px;
    }
  }
  input {
    ${InputFieldModal};
  }
`;

const Footer = styled.div`
  ${FlexCenter};
  @media ${Themes.breakpoints.sm} {
    padding-top: 0;
  }
  border: none !important;
`;

const ButtonSubmit = styled.button`
  ${ButtonStyle};
  background: ${props =>
    props.grey
      ? "linear-gradient(#B4B4B4,#969696)"
      : "linear-gradient(#6086B9,#1955A6)"};
  height: auto;
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.bold};
  margin: 10px 5px;
  padding: 12px 30px;
  p {
    margin: 0;
    padding: 0;
    ${TextShadow};
    text-align: center;
  }
`;
