import React, { Component } from "react";
/** Style **/
import styled from "styled-components";
import arrowBlue from "../../Assets/Images/arrow-blue.svg";
import arrowWhite from "../../Assets/Images/arrow-white.svg";
import Themes from "../../Assets/Styles/Themes";

export default class ToggleBtn extends Component {
  render() {
    if (!this.props.show) {
      return <Button onClick={this.props.click} color={this.props.color}/>;
    } 
    return <FlipedButton onClick={this.props.click} color={this.props.color}/>;
  }
}
const Button = styled.div`
  background-image: ${props => props.color === 'light' ? `url(${arrowWhite})` : `url(${arrowBlue})`};
  width: 20px;
  height: 10px;
  margin-top:2px;
  margin-left:7px;
  background-position: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  @media ${Themes.breakpoints.lg} {
    display:none;
  }
`;

const FlipedButton = styled(Button)`
    transform: rotate(180deg);
`
