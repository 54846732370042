import React, {Component} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import AdminNavMenu from "../Screens/Admin/AdminNavMenu";
import ErrorScreen from "../Screens/Error";
import EditPost from "../Screens/Admin/scene/EditPost";
import App from "../App";
import {Metadata} from "../Utilities";

export default class AdminLayout extends Component {
  constructor(props) {
    super(props);

    const user = App.Current.CurrentUser;
    let name = null;
    if (user) {
      name = `${user.firstname} ${user.lastname}`;
    }

    this.state = {
      user: user,
      name: name
    };

    App.Current.OnLoggedIn(() => {
      const user = App.Current.CurrentUser;
      this.setState({
        user: user,
        name: `${user.firstname} ${user.lastname}`
      });
    });

    App.Current.OnLoggedOut(() => {
      this.setState({
        user: null,
        name: null
      });

      App.Current.RequestLogin();
    });
  }

  render() {
    const meta = Metadata("signedIn");
    // Sign in Status not loaded yet.
    if (typeof meta === "undefined") {
      return null;
    }
    if (!this.state.user) {
      return <ErrorScreen errorcode="401" />;
    }

    return (
      <>
        <AdminNavMenu>
          <Switch>
            <Route
              exact
              path="/admin"
              render={() => <Redirect to="/admin/kb/add" />}
            />
            <Route exact path="/admin/kb/add" component={EditPost} />
            <Route exact path="/admin/kb/edit/*" component={EditPost} />

            {/* Not Found */}
            <Route
              exact
              path="/admin/*"
              render={props => <ErrorScreen {...props} errorcode="404" />}
            />
          </Switch>
        </AdminNavMenu>
      </>
    );
  }
}
