import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Themes from "../../Assets/Styles/Themes";

//show and hide handler for responsive design
export default class DisplayHandler extends Component {
  render() {
    let className = "";
    if (this.props.view === "desktop") {
      className = "desktop";
    } else if (this.props.view === "mobile") {
      className = "mobile";
    }
    return (
      <Div>
        <div className={className}>{this.props.children}</div>
      </Div>
    );
  }
}

const Div = styled.div`
  .mobile {
    @media ${Themes.breakpoints.lg} {
      display:none;
    }
  }
  
  .desktop {
    display: none;
    @media ${Themes.breakpoints.lg} {
      display:block;
    }
  }
`;
