import React, { Component } from 'react';
import { Link } from 'react-router-dom';
/** Style **/
import styled, { css, ThemeProvider } from 'styled-components';

import Themes from '../../Assets/Styles/Themes';
import { ContainerStyle, BigShadow, FlexCenter, TitleStyle, Paragraph, ButtonStyle, Line, ClearBoth} from '../../Assets/Styles/Style';
/** Image **/
import Section02ImageDesktop from '../../Assets/Images/Section02Image-Desktop.jpg';
import Section02ImageMobile from '../../Assets/Images/Section02Image-Mobile.jpg';

class Section02 extends Component{
    render(){
        return(
            <ThemeProvider theme={Themes}>
                <Container>
                    <TitleDiv>
                        <h1>About Us</h1>
                        <Line />
                    </TitleDiv>
                    <Description>
                        <p>
                        HR Sense is a nationally recognised HR Solutions Provider with extensive experience in Human Resources. 
                        We understand that not all SME’s may have access to a full-time HR resource. 
                        For this reason, we offer specialised HR support on a needs-by-need basis. 
                        <br /><br />
                        Our experience with small to medium sized businesses has educated us on the importance of keeping things compliant, 
                        systemised and cost effective.
                        <br /><br />
                        Our HR Consultancy Services are offered on a flexible basis which comprises of phone, 
                        email and professional onsite support, depending on the urgency and availability of our clients. 
                        <br /><br />                        
                        Our Qualified HR Practitioners can assist you with short and long term projects, depending on the individual 
                        requirements of your business.
                        <br /><br />
                        With HR Sense, virtually any Human Resource function can be outsourced.
                        </p>
                    </Description>
                </Container>
            </ThemeProvider>
        )
    }
}

const Container = styled.div`
    ${ContainerStyle};
    padding:30px 8% 47px 8%;
    @media ${Themes.breakpoints.lg} {
        padding:87px 8% 76px 8%;
        display:grid;
        grid-template-columns: 30% 70%;
    }
    @media ${Themes.breakpoints.xl} {
        padding:87px 3% 76px 3%;
    }
`
const TitleDiv = styled.div`
    display:none;
    @media ${Themes.breakpoints.lg} {
        display:block;
        h1{
            ${TitleStyle};
            margin-top:0;
            margin-bottom:0;
        }
    }
    hr{
        margin-left:0;
    }
`
const Description = styled.div`
    p{
        ${Paragraph}
        text-align:center;
        @media ${Themes.breakpoints.lg} {
            text-align:left;
    }
    }
`
export default Section02;