import React, { Component } from 'react';
/** Style */
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../../Assets/Styles/Themes";
// import './ArrowToggle.css';
/** Image */
import ArrowIcon from "../../../../../Assets/Images/arrow-blue.svg"

class ArrowToggle extends Component {
    render(){
        return(
            <ThemeProvider theme={Themes}>
                <Button onClick={this.props.click}>
                    <div />
                </Button>
            </ThemeProvider>
        )
    }
}

const Button = styled.button`
    width:50px;
    height:50px;
    border: none;
    display: none;
    background-color: white;
    div{
        width: 14px;
        height: 8px;
        background-image: url(${ArrowIcon});
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media ${Themes.breakpoints.sm} {
        display: block;
        margin-left: 10px;
    }
`
 
export default ArrowToggle;