import React, {Component} from "react";
import PropTypes from "prop-types";
/** Style **/
import styled, {ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  ContainerStyle,
  FlexCenter,
  TitleStyle,
  Line,
  SmallShadow
} from "../../Assets/Styles/Style";

/** Component */
import ProfilePanel from "./components/profilePanel";

const HRSenseProfiles = [
  {
    image: require("../../Assets/Images/Grant.jpg"),
    name: "Grant Styles",
    role: "Director",
    linkedin: "https://www.linkedin.com/in/grantstyles/"
  },
  {
     image: require("../../Assets/Images/Poppy1.jpg"),
     name: "Poppy Galouzis",
     role: "Operations Manager",
     linkedin: "https://www.linkedin.com/in/poppy-galouzis-871b26205",
  },
  {
     image: require("../../Assets/Images/michael_img.jpg"),
     name: "Michael Robert",
     role: "HR Specialist & Contract Recruitment",
     linkedin: "https://www.linkedin.com/in/michael-robert-449406183",
   },
   {
    image: require("../../Assets/Images/Joey.jpg"),
    name: "Joseph Bechara",
    role: "Contract Recruitment Specialist",
  //  linkedin: "https://www.linkedin.com/in/tyler-nicholas-4a901a125/",
  },
  {
     image: require("../../Assets/Images/Mackinlay.jpg"),
     name: "Anelle Mackinlay",
     role: "HR Specialist",
     linkedin: "https://www.linkedin.com/in/anellemackinlay/",
   },
   {
    image: require("../../Assets/Images/Chantal.jpg"),
    name: "Chantal Ganesh",
    role: "HR Specialist",
    linkedin: "http://linkedin.com/in/chantal-ganesh-526389b",
  },
  {
    image: require("../../Assets/Images/Tanya.jpg"),
    name: "Tanya Ledger",
    role: "HR Specialist & Contract Recruitment",
    linkedin: "http://linkedin.com/in/tanya-ledger",
  },
  {
    image: require("../../Assets/Images/stacie_img.jpg"),
    name: "Stacie Kirkham",
    role: "Permanent Recruitment Specialist",
    linkedin: "https://linkedin.com/feed",
  }
];

class Section03 extends Component {
  render() {
    function TeamMembers(props) {
      const Data = props.data;
      const TeamMembersList = Data.map((item, index) => (
        <ProfilePanel
          key={index}
          imageUri={item.image}
          name={item.name}
          role={item.role}
          linkedin={item.linkedin}
        />
      ));
      return <ProfileDiv>{TeamMembersList}</ProfileDiv>;
    }
    TeamMembers.propTypes = {
      data: PropTypes.array
    };
    return (
      <ThemeProvider theme={Themes}>
        <Section>
          <Container>
            <TitleDiv>
              <h1>Our Team</h1>
              <Line />
            </TitleDiv>
            <TeamMembers data={HRSenseProfiles} />
          </Container>
        </Section>
      </ThemeProvider>
    );
  }
}
const Section = styled.section`
  background-color: ${Themes.colors.veryLightBlueGrey};
`;
const Container = styled.div`
  ${ContainerStyle};
  padding: 42px 8% 120px 8%;
  @media ${Themes.breakpoints.lg} {
    padding: 87px 8% 76px 8%;
    display: grid;
    grid-template-columns: 30% 70%;
  }
  @media ${Themes.breakpoints.xl} {
    padding: 87px 3% 76px 3%;
  }
`;
const TitleDiv = styled.div`
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    ${TitleStyle};
    text-align: center;
  }
  @media ${Themes.breakpoints.lg} {
    hr {
      margin-left: 0;
    }
    h1 {
      text-align: left;
    }
  }
`;
const ProfileDiv = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media ${Themes.breakpoints.sm} {
    grid-template-columns: auto auto;
  }
  @media ${Themes.breakpoints.md} {
    grid-template-columns: auto auto auto;
  }
  @media ${Themes.breakpoints.xl} {
    transform: translateX(-20px);
  }
`;
export default Section03;
