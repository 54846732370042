import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { isNumber } from "util";
import "./index.css";

export default class Paginator extends React.Component {
  changePage = page => {
    this.props.onPageChange && this.props.onPageChange(page);
  };

  render() {
    let { page, size, total } = this.props;

    // Check Page.
    page = isNumber(page) ? page : 0;

    // Determine how many pages there are.
    let pages = isNumber(total) && isNumber(size) ? Math.ceil(total / size) : 0;

    // Don't render too many buttons if there are a lot of pages.
    let minPage = page - 3;
    let maxPage = page + 3;

    let hasMinButton = false;
    let hasMaxButton = false;

    // Range includes Minimum page?
    if (minPage <= 0) {
      minPage = 0;
      hasMinButton = true;
    }

    // Range includes Maximum page?
    if (maxPage >= pages) {
      maxPage = pages;
      hasMaxButton = true;
    }

    const pageButtons = [];
    let minButton = null;
    let maxButton = null;

    // Create First Page button, if it isn't in the range.
    if (!hasMinButton) {
      minButton = (
        <div className="button-grouping">
          <ButtonGroup>
            <Button onClick={() => this.changePage(0)}>1</Button>
          </ButtonGroup>
          <span>...</span>
        </div>
      );
    }

    // Create a Page button for all Pages in the Specified Range.
    for (let pageIndex = minPage; pageIndex < maxPage; pageIndex++) {
      pageButtons.push(
        <Button
          key={pageIndex}
          active={pageIndex == page}
          onClick={() => this.changePage(pageIndex)}
        >
          {pageIndex + 1}
        </Button>
      );
    }

    // Create Final Page button, if it isn't in the range.
    if (!hasMaxButton) {
      maxButton = (
        <div className="button-grouping">
          <span>...</span>
          <ButtonGroup>
            <Button onClick={() => this.changePage(pages - 1)}>{pages}</Button>
          </ButtonGroup>
        </div>
      );
    }

    return (
      <div
        style={this.props.style}
        className={this.props.className || "pagination-container"}
      >
        {!hasMinButton ? minButton : null}
        <ButtonGroup>{pageButtons}</ButtonGroup>
        {!hasMaxButton ? maxButton : null}
      </div>
    );
  }
}
