import styled, { css } from 'styled-components';

import Themes from '../../../Assets/Styles/Themes';
import { 
  ButtonStyle, 
  BlueGradient, 
  GreyGradient,
  TextShadow,
  FlexVerticalCenter,
  FlexCenter
} from '../../../Assets/Styles/Style';
import { Modal } from "react-bootstrap";

export const ButtonCloseStyle = css`
  position: absolute;
  top: 34px;
  right: 27.8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
    @media ${Themes.breakpoints.lg} {
    top: 33px;
    right: 33px;
  }
`;

export const ModalSubmitButton = styled.button`
  ${ButtonStyle};
  background: ${props => (props.grey ? "linear-gradient(#B4B4B4,#969696)" : "linear-gradient(#6086B9,#1955A6)")};
  height: auto;
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.bold};
  margin:5px;
  padding: 12px 30px;
  p {
    margin: 0;
    padding: 0;
    ${TextShadow};
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  margin: 20px 20px 30px 20px;
    @media ${Themes.breakpoints.sm} {
      margin: 20px 60px 40px 60px;
  }
  .flex-center {
    ${FlexCenter}
  }
  p.flex-center {
    text-align:center;
  }
  .flex-center-column {
    ${FlexCenter}
    flex-direction: column;
  }
  /* general modal text */
  .modal-text {
    font-size:18px;
    color: ${Themes.colors.lightBlue};
    font-weight: ${Themes.fontWeight.semiBold};
  }
  /* Login modal */
  .forgot-pw-submitted__message {
    font-size:18px;
    margin-bottom:30px;
    text-align: center;
  }
  .log-in-modal__body {
    input {
      /* margin-bottom: 15px !important; */
    }
  }
  /* override InputContainer margin-bottom: 22px; */
  .log-in-modal__form-entry {
    margin-bottom: 15px !important;
  }
  /* Sign Up modal */
  .sign-up-modal__header{
    padding-bottom: 10px !important;
      @media ${Themes.breakpoints.md} {
      padding-bottom: 20px !important;
    }
  }
  .sign-up-modal__header h5{
    margin-bottom: 0 !important;
  }
`;

export const ModalHeader = styled(Modal.Header)`
  border: none !important;
  padding: 30px 0px;
    @media ${Themes.breakpoints.md} {
    padding: 40px 0px 30px 0 !important;
  }
  .close {
    font-size: 45px;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  div {
    ${FlexVerticalCenter};
    align-items: center !important;
  }
  img {
    width: 70px;
      @media ${Themes.breakpoints.sm} {
      width: 85px;
    }
  }
  h5 {
    color: ${Themes.colors.grey};
    font-size: ${Themes.fontsize.h4};
    font-weight: ${Themes.fontWeight.bold};
    line-height: 30px;
    text-align: center;
    /* margin-top: 7.5px;
    margin-bottom: 0px; */
    margin: 8px 0;
      @media ${Themes.breakpoints.sm} {
      margin-top: 20px;
      width:70%;
      margin-right:auto;
      margin-left:auto;
    }
      @media ${Themes.breakpoints.lg} {
      font-size:${Themes.fontsize.h3};
      margin-top: 12px;
      line-height: 36px;
    }
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 0 auto;
    @media ${Themes.breakpoints.md} {
    width: 70%;
  }
`;

export const ButtonRow = styled.div`
  ${FlexCenter};
  flex-wrap:wrap;
  margin-top:30px;
  /*   @media ${Themes.breakpoints.md} {
      margin-top:50px;
  } */
`

export const HighlightText = styled.span`
  color: ${Themes.colors.blue};
`;

export const ModalSmallLink = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.ligthBlue};
`