import React, {Component} from "react";
import {Link} from "react-router-dom";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {LinkTextStyle} from "../../Assets/Styles/Style";
/** Images **/
import LogoWhite from "../../Assets/Images/logo-white.svg";
import phone from "../../Assets/Images/phone_white.svg";
import Icon_Youtube from "../../Assets/Images/icon_white_youtube.svg";
import Icon_Instagram from "../../Assets/Images/icon_white_instagram.svg";
import Icon_Twitter from "../../Assets/Images/icon_white_twitter.svg";
import Icon_Facebook from "../../Assets/Images/icon_white_facebook.svg";
import Icon_Linkedin from "../../Assets/Images/icon_white_linkedin.svg";
import LogoToolbox from "../../Assets/Images/logo-toolbox.png";
import ScrollingLink from "../ScrollingLink";
import App from "../../App";
import AsyncElement from "../AsyncElement";

class FooterContainer extends Component {
  render() {
    const siteInfo = App.Current.SiteInfo;

    return (
      <ThemeProvider theme={Themes}>
        <Footer>
          <Container>
            <Top>
              <LogoAndPhone>
                <HRSenseLogo to="/">
                  <LogoImg src={LogoWhite} />
                </HRSenseLogo>
                <PhoneDiv>
                  <PhoneIcon src={phone} />
                  <PhoneNumber href={`tel:${siteInfo.fullPhoneNumber}`}>
                    {siteInfo.phoneNumber}
                  </PhoneNumber>
                </PhoneDiv>
              </LogoAndPhone>
              <SitemapAndContactUs>
                <SiteMapDiv>
                  <Title>SITE MAP</Title>
                  <StyledLink to="/">Home</StyledLink>
                  <StyledLink to="/ourservices">Our Services</StyledLink>
                  <StyledLink to="/about">About</StyledLink>
                  <StyledLink to="/knowledgebase">Knowledge Base</StyledLink>
                  <StyledLink to="/contactus">Contact Us</StyledLink>
                  <StyledLink to="/privacypolicy">Privacy Policy</StyledLink>
                </SiteMapDiv>
                <ContactUs>
                  <Title>CONTACT US</Title>
                  <SnsIcons>
                    <IconLink
                      href="https://www.youtube.com/channel/UCEU0DruXZt_QPOLzNTIBiPQ"
                      target="_blank">
                      <IconYoutube src={Icon_Youtube} />
                    </IconLink>
                    <IconLink
                      href="https://www.instagram.com/WhiteCollarBlueAU/"
                      target="_blank">
                      <IconInsta src={Icon_Instagram} />
                    </IconLink>
                    <IconLink
                      href="https://twitter.com/WhiteCollarBlu?lang=en"
                      target="_blank">
                      <IconTwitter src={Icon_Twitter} />
                    </IconLink>
                    <IconLink
                      href="https://www.facebook.com/WhiteCollarBlue/"
                      target="_blank">
                      <IconFacebook src={Icon_Facebook} />
                    </IconLink>
                    <IconLink
                      href="https://www.linkedin.com/company/whitecollarblue-pty-ltd/"
                      target="_blank">
                      <IconLinkedin src={Icon_Linkedin} />
                    </IconLink>
                  </SnsIcons>
                  <AddressLink>
                    <Text>Suite 2 Level 1/20 Wentworth Street</Text>
                    <Text>Parramatta, NSW 2150</Text>
                    <Text>Sydney, NSW 2000</Text>
                  </AddressLink>
                </ContactUs>
              </SitemapAndContactUs>
            </Top>
            <Bottom>
              <BottomLeft>
                <CompanyName>&copy; HR Sense Ltd 2019</CompanyName>
              </BottomLeft>
              <BottomRight>
                <ToolboxLogo href="http://digital.toolbox.net.nz" target="_blank">
                  <TextSiteBy>Site by</TextSiteBy>
                  <LogoImgToolbox src={LogoToolbox} />
                </ToolboxLogo>
              </BottomRight>
            </Bottom>
          </Container>
        </Footer>
      </ThemeProvider>
    );
  }
}

/** Layout **/
const Footer = styled.footer`
  background-color: ${Themes.colors.darkGrey};
  color: ${Themes.colors.white};
  a:link,
  a:hover,
  a:active,
  a:visited {
    color: white !important;
  }
`;

const Container = styled.div`
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Themes.breakpoints.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const LogoAndPhone = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${Themes.breakpoints.md} {
    display: flex;
    flex-direction: row;
  }
`;

const SitemapAndContactUs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 41.7px;

  @media ${Themes.breakpoints.md} {
    display: flex;
    flex-direction: row;
    margin-top: 80.5px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 37.9px;
  padding-bottom: 37.9px;
  font-size: 14px;

  @media ${Themes.breakpoints.sm} {
    font-size: 16px;
  }
  @media ${Themes.breakpoints.md} {
    padding-bottom: 50.2px;
  }
`;

/** Logo **/
const HRSenseLogo = styled(Link)`
  ${LinkTextStyle};
  text-align: center;

  @media ${Themes.breakpoints.md} {
    margin-top: 61.8px;
  }
`;

const LogoImg = styled.img`
  margin-top: 38.8px;
  width: 110px;
  @media ${Themes.breakpoints.md} {
    margin-top: 0px;
  }
`;

/** Phone **/
const PhoneDiv = styled.div`
  margin-top: 8.4px;

  @media ${Themes.breakpoints.md} {
    margin-top: 70px;
    margin-left: 29px;
  }
`;
const PhoneIcon = styled.img`
  width: 23.61px;
  margin-right: 5.2px;
  display: inline-block;
`;
const PhoneNumber = styled.a`
  font-size: 20px;
  font-weight: ${Themes.fontWeight.bold};
  display: inline-block;

  @media ${Themes.breakpoints.md} {
    font-size: 28px;
  }
`;

/** Site Map **/
const SiteMapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Themes.breakpoints.md} {
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-weight: ${Themes.fontWeight.bold};
  margin-bottom: 14.9px;
`;

const StyledLink = styled(ScrollingLink)`
  ${LinkTextStyle};
  color: ${Themes.colors.white};
  font-size: 16px;
  font-weight: ${Themes.fontWeight.regular};
  &:last-child {
    margin-bottom: 40.1px;
  }
  margin-bottom: 6px;
  &:link,
  hover,
  active,
  visited {
    color: ${Themes.colors.white};
  }
`;

/** Contact Us **/
const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Themes.breakpoints.md} {
    align-items: flex-start;
    margin-left: 57.7px;
  }
`;

/** Icons **/
const SnsIcons = styled.div`
  margin-bottom: 17.6px;
`;

const IconYoutube = styled.img`
  width: 19.39px;
  margin-right: 22.6px;
`;

const IconLink = styled.a`
  ${LinkTextStyle};
`;

const IconInsta = styled.img`
  width: 16px;
  margin-right: 22px;
`;

const IconTwitter = styled.img`
  width: 16px;
  margin-right: 22px;
`;

const IconFacebook = styled.img`
  width: 16px;
  margin-right: 13px;
`;

const IconLinkedin = styled.img`
  width: 36.2px;
`;
/** Icons-END **/

const AddressLink = styled.div`
  font-size: 16px;
  font-weight: ${Themes.fontWeight.regular};
  color: ${Themes.colors.white};
`;

const Text = styled.div`
  text-align: center;

  @media ${Themes.breakpoints.md} {
    text-align: left;
  }
`;

/** Bottom **/
const BottomLeft = styled.div``;

const CompanyName = styled.div`
  font-weight: ${Themes.fontWeight.regular};
`;

const BottomRight = styled.div``;

const ToolboxLogo = styled.a`
  text-decoration: none;
  color: ${Themes.colors.white};
  &:link,
  hover,
  active,
  visited {
    text-decoration: none;
    color: ${Themes.colors.white};
  }
`;

const TextSiteBy = styled.span`
  margin-right: 10px;
  font-weight: ${Themes.fontWeight.regular};
`;

const LogoImgToolbox = styled.img`
  width: 80px;

  @media ${Themes.breakpoints.md} {
    width: 100px;
  }
`;

export default FooterContainer;
