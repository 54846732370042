import React, { Component } from 'react';
/** Style **/
import styled, { ThemeProvider } from 'styled-components';

import Themes from '../../Assets/Styles/Themes';
import { SmallShadow, TextShadow, ButtonStyle } from '../../Assets/Styles/Style';
/** Images **/

export default class RequestButton extends Component {
    render(){
        return(
            <ThemeProvider theme={Themes}>
                    <Button>
                        <p>{this.props.title}</p>
                    </Button>
            </ThemeProvider>
        )
    }
}
const Button = styled.button`
    ${ButtonStyle};
    background-color:${Themes.colors.pink};
    height:auto;
    font-size:${Themes.fontsize.h5};
    font-weight:${Themes.fontWeight.bold};
    margin:0 auto;
    padding:18px 40px;
    p{
        margin:0;
        padding:0;
        ${TextShadow};
        text-align:center;
    }

    @media ${Themes.breakpoints.md} {
        padding:18px 60px;
        font-size:${Themes.fontsize.h4};
    }
`