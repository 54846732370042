import React, { Component } from "react";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter
} from "../../Assets/Styles/Style";
/** Image **/
import iconSubmitSuccess from "../../Assets/Images/icon-submit-success.svg";
import { ModalSubmitButton } from "./styles";

export default class SuccessModal extends Component {
  close = e => {
    this.props.onClose && this.props.onClose(e);
  };
  
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <Backdrop
        onClick={this.close}>
        <Modal>
          <HeaderImg src={iconSubmitSuccess} />
          <Body>
            <Description>Submitted Successfully!</Description>
          </Body>
          <Footer>
            <ModalSubmitButton onClick={this.close}>OK</ModalSubmitButton>
          </Footer>
        </Modal>
      </Backdrop>
    );
  }
}

const Backdrop = styled.div`
  ${FlexCenter};
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
`;

const Modal = styled.div`
    padding: 10% 0;
    display: flex;
    ${FlexCenter};
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 350px;
    border-radius: 10px;
    background-color: white;
`;

const HeaderImg = styled.img`
  width: 121.27px;
`;

const Body = styled.div`
  ${FlexCenter};
  flex-direction: column;
  margin: 10px 0;
`;

const Description = styled.div`
  font-size: ${Themes.fontsize.h4};
  font-weight: ${Themes.fontWeight.bold};
  color: #1755a6;
  text-align: center;
  line-height: 130%;
  width: 250px;
`;

const Footer = styled.div`
  ${FlexCenter};
`;
