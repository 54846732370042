import {withFormsy} from "formsy-react";
import React from "react";
import styled from "styled-components";

import {ErrorMessage, Thumbnail} from "../../Assets/Styles/Style";

import {
  InputSection,
  InputContainer,
  InputField,
  InputTextArea,
  PrimaryLabel,
  InputDiv,
  Limiter
} from "./FormStyles";
import {GetFileType} from "../../Utilities";

class FormFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);

    this.state = {
      existing: props.existing
    };

    this.oldVal = null;
    this.updating = false;
  }

  render() {
    const value = this.props.getValue();
    if (!value && this.refs.file) {
      this.refs.file.value = "";
    }

    return (
      <InputContainer>
        <InputSection hasLabel={this.props.label} className="formentry-inputSection">
          <PrimaryLabel>{this.props.label}</PrimaryLabel>
          <InputDiv>
            {this.props.preContent}
            {this.props.preview && this.state.existing && (
              <PreviewDiv>
                <Thumbnail thumb={this.state.existing} />
              </PreviewDiv>
            )}
            <input
              ref="file"
              type="file"
              id={this.props.name}
              name={this.props.name}
              accept={this.props.fileTypes && this.props.fileTypes.join(",")}
              disabled={this.props.disabled}
              onChange={this.handleFile}
            />
            {this.props.afterContent}
          </InputDiv>
        </InputSection>
        {this.props.isFormSubmitted() && this.props.getErrorMessage() ? (
          <ErrorMessage>
            {this.props.isFormSubmitted() && this.props.getErrorMessage()}
          </ErrorMessage>
        ) : null}
      </InputContainer>
    );
  }

  componentDidUpdate(prevState, prevProps) {
    const newVal = this.props.getValue();
    if (this.oldVal != newVal) {
      if (this.props.preview) {
        let existing = this.state.existing;

        this.setState(
          {
            existing: newVal ? URL.createObjectURL(newVal) : null
          },
          () => {
            if (existing) {
              URL.revokeObjectURL(existing);
            }
          }
        );
      }

      if (!newVal && this.refs.file) {
        this.refs.file.value = "";
      }

      this.oldVal = newVal;
    }
  }

  changeValue(file) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const val = file;

    this.props.setValue(val);
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }

  handleFile = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      if (
        this.props.fileTypes &&
        !this.props.fileTypes.includes("." + GetFileType(file.name))
      ) {
        alert(`The file must be one of ${this.props.fileTypes.join(", ")}`);
        return;
      }

      if (this.props.maxFileSize && file.size > this.props.maxFileSize) {
        alert("The provided file is too large");
        return;
      }

      this.changeValue(file);
    }
  };
}

export default withFormsy(FormFileUpload);

const PreviewDiv = styled.div`
  margin-bottom: 10px;
`;
