import ServiceBase from "./ServiceBase";
import APIState from "../APIState";
import APIAuthError from "../error/APIAuthError";

/**
 * Contact Service for the Application.
 * */
export default class ContactService extends ServiceBase {
  /**
   * Creates the Contact Service
   *
   * @param {APIState} state State Object
   */
  constructor(state) {
    super(state);
  }

  async ContactUs(request) {
    const req = this.CreateFormRequest(`/contact/us`, request);
    await this.Send(req);
  }

  async HealthCheck(request) {
    const req = this.CreateFormRequest(`/contact/healthcheck`, request);
    await this.Send(req);
  }

  async RequestConsult(request) {
    const req = this.CreateFormRequest(`/contact/consult`, request);
    await this.Send(req);
  }

  async RequestCall(request) {
    const req = this.CreateFormRequest(`/contact/call`, request);
    await this.Send(req);
  }
}
