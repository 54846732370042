import React from "react";
import FormChoice, { RadioChoice } from "./FormChoice";

export default class FormTrueFalse extends React.Component {
  render(){
    return (
      <FormChoice 
        horizontal={true}
        name={this.props.name}
        label={this.props.label}
        validations={this.props.validations}
        validationError={this.props.validationError}
        validationErrors={this.props.validationErrors}
        changeValue={this.changeValue}
        class={this.props.class}
        inputDivClassName={this.props.inputDivClassName}
        >
          <RadioChoice label={this.props.trueLabel || "Yes"} value={true} />
          <RadioChoice label={this.props.falseLabel || "No"} value={false} />
        </FormChoice>
    );
  }
}