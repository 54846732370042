import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import HeaderContainer from "../Components/HeaderContainer";
import FooterContainer from "../Components/FooterContainer";
import HomeScreen from "../Screens/Home";
import OurServicesScreen from "../Screens/OurServices";
import KnowledgeBaseScreen from "../Screens/KnowledgeBase";
import ArticleScreen from "../Screens/Article";
import AboutUsScreen from "../Screens/AboutUs";
import ContactUsScreen from "../Screens/ContactUs";
import PrivacyPolicyScreen from "../Screens/PrivacyPolicy";
import HealthCheckScreen from "../Screens/HealthCheck";
import FloatingSnsIcons from "../Components/Sns";
import {ScrollToTop} from "../Utilities";
import SetPassword from "../Screens/SetPassword";
import ErrorScreen from "../Screens/Error";

class PublicLayout extends Component {
  componentDidMount() {
    ScrollToTop();
  }

  render() {
    return (
      <div>
        <HeaderContainer />
        <FloatingSnsIcons />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/ourservices" component={OurServicesScreen} />
          <Route exact path="/about" component={AboutUsScreen} />
          <Route exact path="/knowledgebase" component={KnowledgeBaseScreen} />
          <Route exact path="/knowledgebase/*" component={ArticleScreen} />
          <Route exact path="/contactus" component={ContactUsScreen} />
          <Route exact path="/privacypolicy" component={PrivacyPolicyScreen} />
          <Route exact path="/healthcheck" component={HealthCheckScreen} />

          {/* Auth */}
          <Route exact path="/setpassword" component={SetPassword} />

          {/* Not Found */}
          <Route
            exact
            path="*"
            render={props => <ErrorScreen {...props} errorcode="404" />}
          />
        </Switch>
        <FooterContainer />
      </div>
    );
  }
}

export default PublicLayout;
