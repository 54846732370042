import React, {Component} from "react";
import {Modal} from "react-bootstrap";
/** Style **/
import styled, {css, ThemeProvider} from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  LabelModal,
  InputFieldModal,
  FlexVerticalCenter,
  Paragraph,
  ErrorMessage,
  ValidationErrors
} from "../../Assets/Styles/Style";
/** Image **/
import reliefModal from "../../Assets/Images/excel-file.svg";
import FormHandler from "../Form/FormHandler";
import App from "../../App";
import FormEntry from "../Form/FormEntry";
import {
  ModalSubmitButton,
  ModalHeader,
  ModalContent,
  HighlightText,
  ModalBody
} from "./styles";
import SuccessModal from "./SuccessModal";
import financialReliefFile from "../../Assets/file/Business_Financial_Relief_Calculator.xlsx";

export default class FreeConsultingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: false,
      submissionError: false,
      submitting: false,
      success: false
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg">
        <SuccessModal
          onClose={this.successAcknowledged.bind(this)}
          show={this.state.success}
        />

        <ModalContent>
          <ModalHeader closeButton>
            <div>
              <img src={reliefModal} alt="relief" />
              <h5>Business Financial Relief</h5>
            </div>
          </ModalHeader>
          <ModalBody>
            <Description>
              <div>
                Are you worried about your business? Find out what financial relief
                your business is eligible for?
              </div>
              <div></div>
              <HighlightText>
                Download our <b>FREE</b> Financial Relief eligibility calculator
              </HighlightText>
            </Description>

            <div className="flex-center">
              {/* </div><a target="_blank" href="../../Assets/Images/excel-file.svg"> */}
              <a
                href={financialReliefFile}
                download="Business_Financial_Relief_Calculator.xlsx">
                <ModalSubmitButton disabled={this.state.submitting}>
                  Download
                </ModalSubmitButton>
              </a>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  onValid() {
    this.setState({
      hasErrors: false
    });
  }

  onInvalid() {
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    try {
      this.setState({
        submitting: true
      });

      await App.Current.API.Contact.RequestConsult(data);
      this.refs.form.reset();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false
      });
    } catch (e) {
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }

  successAcknowledged() {
    this.setState({
      success: false
    });
  }
}

const Description = styled.div`
  ${Paragraph};
  font-size: ${Themes.fontsize.p1};
  b {
    font-weight: ${Themes.fontWeight.black};
  }
  text-align: center;

  @media ${Themes.breakpoints.lg} {
    font-size: ${Themes.fontsize.h5};
  }

  margin-top: -35px;
  margin-bottom: 40px;
`;
