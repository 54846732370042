import React, { Component } from "react";
import PropTypes from "prop-types";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";
import Themes from "../../Assets/Styles/Themes";
import { FlexCenter } from "../../Assets/Styles/Style";
import shareImg from "../../Assets/Images/knowledgeBase/share-icon.svg";
import heart from "../../Assets/Images/knowledgeBase/Heart.svg";

export default class KBRoundButton extends Component {
  render() {
    const Symbol = this.getSymbol();

    return (
      <Button {...this.props}>
        {Symbol && <img src={Symbol} alt={this.props.content} />}
        <span>{this.props.content}</span>
      </Button>
    );
  }

  getSymbol() {
    switch (this.props.content) {
      case "Favourite":
        return heart;

      case "Share":
        return shareImg;

      default:
        return null;
    }
  }
}
const Button = styled.div`
  border-radius: 50px;
  box-shadow: none;
  font-size: 15px;
  font-weight: ${Themes.fontWeight.semiBold};
  padding: 7px 10px;
  img {
    width: 16px;
    margin-right: 5px;
  }
  cursor: pointer;

  ${props =>
    props.hollow
      ? `
    color: ${Themes.colors.darkGrey};
    border: 1px solid ${Themes.colors.darkGrey};
  `
      : `
    color: white;
    background-color: ${Themes.colors.blue};
  `}
`;
