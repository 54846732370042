import styled, {css, createGlobalStyle} from "styled-components";

import Themes from "./Themes";

export const GlobalStyle = createGlobalStyle`
  html {
    height:100%;
  }
  body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color:#707070;
    min-height: 100vh;
  }
  button, a {
    outline:none;
    cursor:pointer;
  }
  a:link,
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
  }
  h1,h2,h3,h4,h5,h6,p{
    margin:0;
    padding:0;
  }
  h1{
    font-size:${Themes.fontsize.h1}
    font-weight${Themes.fontWeight.bold}
  }
  h4{
    font-size:${Themes.fontsize.h4}
  }
  h5{
    font-size:${Themes.fontsize.h5};
    line-height: 35px;
  }
  @media screen and (min-width: 768px){
      .modal-dialog {
        position: absolute !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
`;

export const ContainerStyle = css`
  max-width: 1200px;
  margin: 0 auto;

  @media ${Themes.breakpoints.xl} {
    max-width: 1366px;
  }
`;
export const SmallShadow = css`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;
export const TextShadow = css`
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;
export const BigShadow = css`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;
export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexVerticalCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const FlexHorizontalCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const FlexHorizontalJustify = css`
  ${FlexHorizontalCenter};
  justify-content: space-between;
`;
export const BackgroundDefaultStyle = css`
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const LinkTextStyle = css`
  text-decoration: none;
  color: ${Themes.colors.lightGrey};
  font-size: 16px;
`;
export const ButtonStyle = css`
  border: none;
  border-radius: 50px;
  outline: none;
  color: white;
  cursor: pointer;
  ${FlexCenter};
  &:hover {
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); */
    color: white;
    box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
  }
`;
export const BlueGradient = css`
  background: linear-gradient(${Themes.colors.lightBlue}, ${Themes.colors.blue});
  background: -webkit-linear-gradient(
    ${Themes.colors.lightBlue},
    ${Themes.colors.blue}
  );
  background-repeat: no-repeat;
  background-size: cover;
`;
export const GreenGradient = css`
  background: linear-gradient(${Themes.colors.lightGreen}, ${Themes.colors.green});
  background: -webkit-linear-gradient(
    ${Themes.colors.lightGreen},
    ${Themes.colors.green}
  );
  background-repeat: no-repeat;
  background-size: cover;
`;
export const GreyGradient = css`
  background: llinear-gradient(#b4b4b4, #969696);
  background: -webkit-linear-gradient(#b4b4b4, #969696);
  background-repeat: no-repeat;
  background-size: cover;
`;
export const TitleStyle = css`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${Themes.fontsize.h3};
  font-weight: ${Themes.fontWeight.bold};

  @media ${Themes.breakpoints.lg} {
    font-size: ${Themes.fontsize.h1};
    line-height: 47px;
  }
`;
export const SubTitleStyle = css`
  margin: 0;
  font-size: ${Themes.fontsize.h5};
  text-align: center;

  @media ${Themes.breakpoints.lg} {
    line-height: 30px;
  }
`;
export const SmallTitleStyle = css`
  font-size: ${Themes.fontsize.p2};

  @media ${Themes.breakpoints.lg} {
    font-size: ${Themes.fontsize.p1};
  }
`;
export const Paragraph = css`
  font-size: ${Themes.fontsize.p2};
  line-height: 26px;

  @media ${Themes.breakpoints.lg} {
    line-height: 30px;
  }
`;
export const KnowledgeBaseParagraph = css`
  font-size: ${Themes.fontsize.p3};
  line-height: 24px;

  @media ${Themes.breakpoints.lg} {
    font-size: ${Themes.fontsize.p2};
    line-height: 30px;
  }
`;
export const Line = styled.hr`
  width: 130px;
  height: 5px;
  background-color: ${Themes.colors.pink};
  margin-top: 20px;
  margin-bottom: 20px;

  @media ${Themes.breakpoints.sm} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
export const ClearBoth = styled.div`
  clear: both;
`;
/** Pricing Table **/
export const PricingTableCommonStyle = css`
  background-color: ${Themes.colors.lightBlue};
  ${SmallShadow};
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 20px 0;
  color: white;
  h3 {
    font-size: ${Themes.fontsize.h3};
    font-weight: ${Themes.fontWeight.bold};
  }
  h4 {
    font-size: ${Themes.fontsize.h4};
    font-weight: ${Themes.fontWeight.bold};
    margin: 0;
  }
`;
export const ShortLine = styled.hr`
  width: 38px;
  height: 5px;
  margin: 15px auto;
  background-color: ${props =>
    props.blue ? `${Themes.colors.lightBlue}` : `${Themes.colors.white}`};
`;
export const PricingLearnMore = styled.button`
  ${ButtonStyle};
  color: ${Themes.colors.grey};
  width: 220px;
  height: 64px;
  background-color: white;
  &:hover {
    color: ${Themes.colors.grey};
  }
`;
export const LabelModal = css`
  font-size: ${Themes.fontsize.p3};
  color: ${Themes.colors.lightBlue};
  font-weight: ${Themes.fontWeight.bold};
  margin-bottom: 0;

  @media ${Themes.breakpoints.sm} {
    font-size: ${Themes.fontsize.p2};
  }
`;

export const InputFieldBase = css`
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: ${Themes.colors.veryLightGrey};
  min-height: 40px;
  color: ${Themes.colors.grey};
  font-weight: ${Themes.fontWeight.bold};
  font-size: ${Themes.fontsize.p3};
`;

export const InputFieldModal = css`
  /* width: 100%; */
  ${InputFieldBase}
  padding: 0.5em 1em;
`;

export const InputSectionStyle = css`
  @media ${Themes.breakpoints.lg} {
    ${FlexCenter};
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const SmallButtonStyle = css`
  ${ButtonStyle};
  padding: 15px 30px;
  font-size: ${Themes.fontsize.p2};

  @media ${Themes.breakpoints.sm} {
    padding: 21px 32px;
    font-size: ${Themes.fontsize.h5};
  }

  font-weight: ${Themes.fontWeight.bold};
  ${BlueGradient};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ErrorStyle = css`
  font-weight: ${Themes.fontWeight.medium};
  background: #fee9e7;
  border-radius: 50px;
  padding: 4px 15px !important;
  color: red;
  font-size: 14px;
  text-align: center;
  margin: 1em auto;
`;

export const ErrorMessage = styled.div`
  ${ErrorStyle};
`;

export const SubmitButton = styled.button`
  ${ButtonStyle};
  ${BlueGradient}
  height:auto;
  font-size: ${Themes.fontsize.h5};
  font-weight: ${Themes.fontWeight.bold};
  margin: 36px auto 0 auto;
  padding-top: 12px;
  padding-bottom: 12px;
  letter-spacing: 1px;
  width: 100%;

  @media ${Themes.breakpoints.md} {
    width: 387px;
  }
`;

export const Thumbnail = styled.div`
/* The image used */
background-image: url(${props => props.thumb});

/* Add the blur effect */
${props =>
  props.blur
    ? `
      filter: blur(8px);
      -webkit-filter: blur(8px);
    `
    : ""}

/* Center and scale the image nicely */
background-position: center;
background-repeat: no-repeat;
background-size: cover;
height: 100%;
width: 100%;
${props => (props.absolute ? `position: absolute;` : "")}
@media ${Themes.breakpoints.md} {
    height: 500px;
  }
`;

export const ValidationErrors = {
  required: "This is a required field",
  validPhoneNumber: "A valid phone number is required",
  validEmailAddress: "A valid email address is required",
  selectOption: "A selection for this question is required",
  passwordMismatch: "Your password you entered does not match"
};
