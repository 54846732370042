import React, {Component} from "react";
import FormChoice, {CheckChoice} from "../../../Components/Form/FormChoice";
/** Style **/
import styled from "styled-components";
import Themes from "../../../Assets/Styles/Themes";
import {TitleDiv} from "./styles";

/** Image */
import VideoImage from "../../../Assets/Images/knowledgeBase/video.svg";
import NewsArticleImage from "../../../Assets/Images/knowledgeBase/newsArticles.svg";
import TemplatesImage from "../../../Assets/Images/knowledgeBase/templates.svg";
import EbooksImage from "../../../Assets/Images/knowledgeBase/ebooks.svg";
import BlogPostsImage from "../../../Assets/Images/knowledgeBase/blogPost.svg";
import InfographicImage from "../../../Assets/Images/knowledgeBase/infographic.svg";

const Types = {
  video: {
    label: "Video",
    glyph: VideoImage,
    glyphStyle: {width: "25px"}
  },
  article: {
    label: "News Article",
    glyph: NewsArticleImage,
    glyphStyle: {width: "19px"}
  },
  template: {
    label: "Templates",
    glyph: TemplatesImage,
    glyphStyle: {width: "19px"}
  },
  ebook: {
    label: "E-books",
    glyph: EbooksImage,
    glyphStyle: {width: "26px"}
  },
  blogpost: {
    label: "Blog Posts",
    glyph: BlogPostsImage,
    glyphStyle: {width: "25px"}
  },
  infographic: {
    label: "Infographics",
    glyph: InfographicImage,
    glyphStyle: {width: "32px"}
  }
};

class TypesFilterPanel extends Component {
  render() {
    return (
      <PanelDiv>
        <TitleDiv>
          <p>Types</p>
        </TitleDiv>
        <PanelInnerDiv>
          <FormChoice name="type[]" value={[]} isMulti={true} horizontal={true}>
            {this.props.types &&
              this.props.types.map(type => {
                const key = type.id;
                const typeInfo = Types[key];

                return (
                  <CheckChoice
                    key={key}
                    value={key}
                    label={typeInfo.label}
                    beforeContent={
                      <img src={typeInfo.glyph} style={typeInfo.glyphStyle} />
                    }
                    afterContent={
                      <span className="kb-inputs__count">{type.count}</span>
                    }
                  />
                );
              })}
          </FormChoice>
        </PanelInnerDiv>
      </PanelDiv>
    );
  }
}
const PanelDiv = styled.div`
  border-radius: 10px;
  padding: 20px;
  @media ${Themes.breakpoints.lg} {
    padding: 30px 25px 20px 25px;
  }
  background-color: ${Themes.colors.veryLightBlueGrey};
  display: flex;
  flex-wrap: wrap;
  @media ${Themes.breakpoints.md} {
    grid-column: 1 / 3;
  }
`;
const PanelInnerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 2px solid rgba(177, 198, 211, 0.2);
  margin-top: 10px;
  div {
    margin: 10px 30px 10px 0;
    @media ${Themes.breakpoints.sm} {
      margin: 10px 50px 10px 0;
    }
    input {
      margin-right: 10px;
    }
    img {
      margin-right: 8px;
    }
    label {
      margin-right: 10px;
      font-size: 16px !important;
      cursor: pointer;
    }
    .kb-inputs__count {
      color: ${Themes.colors.blue};
      font-weight: ${Themes.fontWeight.bold};
      font-size: 16px !important;
    }
    .choice-option__label {
      margin-right: 5px;
      font-weight: 700;
    }
  }
  div:last-child {
    margin-bottom: 0;
  }

  @media ${Themes.breakpoints.lg} {
    div {
      margin-bottom: 0px;
    }
  }
`;
export default TypesFilterPanel;
