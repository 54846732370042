import { withFormsy } from "formsy-react";
import React from "react";

import { ErrorMessage } from "../../Assets/Styles/Style";

import "./FormChoice.css";

import {
  InputDiv,
  InputSection,
  InputContainer,
  Input,
  InputLabel,
  PrimaryLabel
} from "./FormStyles";

class FormChoice extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = (this.props.changeValue || this.changeValue).bind(this);
  }

  render() {
    let currentValue = this.props.getValue();
    if (typeof currentValue == "undefined") {
      currentValue = this.props.isMulti ? [] : null;
    }

    const updateValue = this.props.isMulti
      ? this.changeValueMulti
      : this.changeValue;

    return (
      <InputContainer className={this.props.class}>
        <InputSection
          hasLabel={this.props.label}
          className="formentry-inputSection"
        >
          {this.props.label && <PrimaryLabel>{this.props.label}</PrimaryLabel>}
          <InputDiv
            horizontal={this.props.horizontal}
            className={this.props.inputDivClassName}
            style={this.props.style}
          >
            {/* Add Logical Props to the Form Option elements */}
            {React.Children.map(this.props.children, child => {
              if (child != null) {
                const isSelected = this.props.isMulti
                  ? currentValue.indexOf(child.props.value) >= 0
                  : child.props.value === currentValue;

                return React.cloneElement(child, {
                  name: this.props.name,
                  updateValue: updateValue,
                  selected: isSelected,
                  disabled: child.props.disabled || this.props.disabled
                });
              }
              return null;
            })}
          </InputDiv>
        </InputSection>
        {this.props.isFormSubmitted() && this.props.getErrorMessage() ? (
          <ErrorMessage>
            {this.props.isFormSubmitted() && this.props.getErrorMessage()}
          </ErrorMessage>
        ) : null}
      </InputContainer>
    );
  }

  changeValueMulti = value => {
    let multiVal = this.props.getValue();

    if (!multiVal || Array.isArray(multiVal)) {
      if (!multiVal) {
        multiVal = [];
      }

      const valIndex = multiVal.indexOf(value);
      if (valIndex >= 0) {
        multiVal.splice(valIndex, 1);
      } else {
        multiVal.push(value);
      }
    } else {
      throw new Error("A Multi Value choice must have an array value");
    }

    this.changeValue(multiVal);
  };

  changeValue = value => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.

    // Set null if Double Set.
    if (!this.props.isMulti && value == this.props.getValue()) {
      value = null;
    }

    this.props.setValue(value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
}

export default withFormsy(FormChoice);

/**
 * A Choice option wrapped component.
 * @param {any} WrappedComponent
 */
export function FormChoiceOption(WrappedComponent) {
  return class WithFormChoice extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

/**
 * The Default Choice Options.
 **/
export const RadioChoice = FormChoiceOption(
  ({
    name,
    label,
    value,
    selected,
    disabled,
    updateValue,
    className,
    beforeContent,
    afterContent
  }) => {
    label = label || value;
    const id = `${name}_${value}`;

    let choiceClasses = "choice-option";
    if (className) {
      choiceClasses += " " + className;
    }

    return (
      <div className={choiceClasses}>
        <InputLabel htmlFor={id}>
          <Input
            type="radio"
            name={name}
            id={id}
            value={value}
            checked={selected}
            disabled={disabled}
            onChange={() => updateValue(value)}
          />
          <span>
            {beforeContent}
            <span className="choice-option__label">{label}</span>
            {afterContent}
          </span>
        </InputLabel>
      </div>
    );
  }
);

/**
 * Checkbox Choice Options.
 **/
export const CheckChoice = FormChoiceOption(
  ({
    name,
    label,
    value,
    selected,
    disabled,
    updateValue,
    className,
    beforeContent,
    afterContent
  }) => {
    label = label || value;
    const id = `${name}_${value}`;

    let choiceClasses = "choice-option";
    if (className) {
      choiceClasses += " " + className;
    }

    return (
      <div className={choiceClasses}>
        <InputLabel htmlFor={id}>
          <Input
            type="checkbox"
            name={name}
            id={id}
            value={value}
            checked={selected}
            disabled={disabled}
            onChange={() => updateValue(value)}
          />

          <span>
            {beforeContent}
            <span className="choice-option__label">{label}</span>
            {afterContent}
          </span>
        </InputLabel>
      </div>
    );
  }
);
