import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";

/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexCenter,
  TitleStyle,
} from "../../Assets/Styles/Style";

/** Components **/
import TestimonialSlider from "./components/slider";

/** Images **/
import BgDesktop from "../../Assets/Images/testimonial-bg.jpg";
import BgMobile from "../../Assets/Images/testimonial-bg-mobile.jpg";
import QuatationMark from "../../Assets/Images/quatation-mark.svg";

class Section04 extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Fade>
          <Section>
            <Container>
              <TitleDiv>
                <Title>What Our Clients Say</Title>
                <img src={QuatationMark} />
              </TitleDiv>
              <VisibilitySensor>
              <TestimonialSlider />
              </VisibilitySensor>
            </Container>
          </Section>
        </Fade>
      </ThemeProvider>
    );
  }
}
const Section = styled.div`
    background-image:url(${BgMobile});
    background-position:top right;
    background-size:cover;
    padding:70px 0 0 0;
      @media ${Themes.breakpoints.sm} {
        height:auto;
        padding:70px 7% 0 7%;
    }
      @media ${Themes.breakpoints.md} {
        background-image:url(${BgDesktop});
        padding-top:100px;
    } 
    @media ${Themes.breakpoints.xl} {
        padding:150px 5% 100px 5%;
    } 
`;
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
const TitleDiv = styled.div`
  ${FlexCenter};
  flex-direction: column;
  img {
    width: 40px;
    height: auto;
  }
  margin-bottom: 50px;
`;
const Title = styled.div`
  ${TitleStyle};
  color: white;
  margin: 0 5% 26px 5%;
  text-align: center;
    @media ${Themes.breakpoints.sm} {
        margin:0 auto 26px auto;
    }
  @media ${Themes.breakpoints.xl} {
        margin-bottom:40px;
    }
`;
export default Section04;
