import React, { Component } from "react";

import FormChoice, { CheckChoice } from "../../../Components/Form/FormChoice";
import ToggleBtn from "../../../Components/Toggle/responsiveToggleBtn";

/** Style **/
import { PanelDiv, TitleDiv, Body } from "./styles";
import DisplayHandler from "../../../Components/DisplayHandler";

export default class CategoryPanel extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }
  showHandler = () => {
    this.setState(prevState => {
      return { show: !prevState.show };
    });
  };

  render() {
    return (
      <div>
        <PanelDiv>
          <TitleDiv>
            <p>Categories</p>
            <ToggleBtn click={this.showHandler} show={this.state.show}/>
          </TitleDiv>
          {this.state.show && (
            <DisplayHandler view="mobile">
              <CollapseContent categories={this.props.categories} extraStyle="kb-filter-responsive" numCols="2"/>
            </DisplayHandler>
          )}
          <DisplayHandler view="desktop">
            <CollapseContent categories={this.props.categories} numCols="3"/>
          </DisplayHandler>
        </PanelDiv>
      </div>
    );
  }
}

class CollapseContent extends Component {
  render() {
    const numRows = Math.ceil(this.props.categories.length / this.props.numCols);
    const style = { gridTemplateRows: `repeat(${numRows}, 1fr)` };
    const extraStyle = this.props.extraStyle;

    return (
      <Body>
        <FormChoice
          name="category[]"
          value={[]}
          isMulti={true}
          inputDivClassName={`grid-wrap ${extraStyle}`}
          style={style}
        >
          {this.props.categories &&
            this.props.categories.map(category => (
              <CheckChoice
                key={category.id}
                value={category.id}
                label={category.name}
                className="kb-inputs"
                afterContent={
                  <span className="kb-inputs__count">{category.count}</span>
                }
              />
            ))}
        </FormChoice>
      </Body>
    );
  }
}
