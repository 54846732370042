import React, { Component } from 'react';
/** Style */
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../Assets/Styles/Themes";
// import './DrawerToggleButton.css';
/** Image */

class drawerToggleButton extends Component {
    render(){
        return(
            <ThemeProvider theme={Themes}>
                <ToggleButton onClick={this.props.click}>
                    <Line />
                    <Line />
                    <Line />
                </ToggleButton>
            </ThemeProvider>
        )
    }
}

const ToggleButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18px;
    width: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    @media ${Themes.breakpoints.sm} {
        display: none;
        &:focus {
            display: none;
        }
    }
    &:focus {
        outline: none;
    }
` 
const Line = styled.div`
    width: 24px;
    height: 2px;
    background:${Themes.colors.grey};
`
export default drawerToggleButton;