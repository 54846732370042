import React, { Component } from "react";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../../Assets/Styles/Themes";
import {
  ButtonStyle, 
  BlueGradient,
  ErrorMessage
} from "../../../Assets/Styles/Style";
import { ValidationErrors } from "../../../Assets/Styles/Style";
import FormHandler from "../../../Components/Form/FormHandler";
import FormEntry from "../../../Components/Form/FormEntry";
import App from "../../../App";

export default class SetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.token = props.token;
    this.userinfo = props.userinfo;
    this.state = {
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      error: null
    };
  }

  render() {
    return (
      <FormContainer>
        <FormHandler 
          ref="form" 
          onSubmit={this.submit.bind(this)} 
          onValid={this.onValid.bind(this)} 
          onInvalid={this.onInvalid.bind(this)}>

          <FormEntry 
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            validations={{
              hasValue: true,
              minLength: 8
            }}
            validationErrors={{
              hasValue: ValidationErrors.required,
              minLength: "Your password must be at least 8 characters"
            }}
          />

          <FormEntry 
            label="Confirm Password"
            name="password-confirm"
            type="password"
            placeholder="Password"
            validations={{
              hasValue: true,
              equalsField: "password"
            }}
            validationErrors={{
              hasValue: ValidationErrors.required,
              equalsField: ValidationErrors.passwordMismatch
            }}
          />

          <SubmitButton disabled={this.state.submitting}>Submit</SubmitButton>

          {this.state.hasErrors ?
            <ErrorMessage>
              {this.state.submissionError ? "An issue occurred while submitting your request. Try again later." : "Please fix the errors above to continue"}
            </ErrorMessage>
          :null}

        </FormHandler>
      </FormContainer>
    );
  }

  getError = () => {
    if(this.state.hasErrors){
      if(this.state.error){
        return this.state.error;
      }
      else if(this.state.submissionError)
      {
        return "An issue occurred while submitting your request. Try again later.";
      }
      else{
        return "Please fix the errors above to continue";
      }
    }

    return "";
  }

  onValid(){
    this.setState({
      hasErrors: false
    });
  }

  onInvalid(){
    this.setState({
      hasErrors: true
    });
  }


  async submit(data) {
    try
    {
      this.setState({
        submitting: true
      });

      if(await App.Current.API.User.SetPassword(this.token, data)){
        App.Current.API.Authentication.SetUserData(this.userinfo);
        App.Current.LoginSuccessful();
        App.Current.Navigate("/");
      }
    }
    catch(e){
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }
}

const FormContainer = styled.div`
    width:100%;
`;

const SubmitButton = styled.button`
  ${ButtonStyle};
  ${BlueGradient}
  height:auto;
  font-size:${Themes.fontsize.h5};
  font-weight:${Themes.fontWeight.bold};
  margin:36px auto 0 auto;
  padding-top:12px;
  padding-bottom:12px;
  letter-spacing:1px;
  width:100%;
  @media ${Themes.breakpoints.md} {
      width:387px;
  }
`;