
import styled from "styled-components";
import Themes from "../../../Assets/Styles/Themes";
import { SmallTitleStyle, FlexHorizontalJustify } from "../../../Assets/Styles/Style";

export const PanelDiv = styled.div`
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  @media ${Themes.breakpoints.lg} {
    /* when collapse panel is opened, the padding needs to be different */
    padding: 30px 25px 10px 25px;
  }
  .grid-wrap {
    display: grid;
    @media ${Themes.breakpoints.lg} {
      grid-auto-flow: column;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .kb-filter-responsive {
    @media ${Themes.breakpoints.sm}{
      grid-template-columns: repeat(2, 1fr);
    }
    @media ${Themes.breakpoints.lg} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;
export const TitleDiv = styled.div`
  ${FlexHorizontalJustify};
  p {
    ${SmallTitleStyle};
    color: ${Themes.colors.blue};
    font-weight: ${Themes.fontWeight.semiBold};
    margin-bottom: 0;
    margin-left: 5px;
  }
`;
export const Body = styled.div`
  padding-top: 10px;
  border-top: 2px solid rgba(177, 198, 211, 0.2);
  margin-top: 10px;
  width: 100%;
  .kb-inputs {
    label {
      display: grid;
      grid-template-columns: 25px auto;
      margin-bottom: 14px;
      font-weight: ${Themes.fontWeight.regular};
      font-size: 16px !important;
      cursor: pointer;
        input {
          grid-row: 1 / 3;
          width: 20px;
          margin: 4px 2px 0 0!important;
        }
        .kb-inputs__count {
          margin-left: 8px;
          color: ${Themes.colors.blue};
          font-weight: ${Themes.fontWeight.bold};
        }
    }
  }
`;
