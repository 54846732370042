import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export default class MarkdownRenderer extends React.Component {
    render(){
        return (<MDStyle>
            <ReactMarkdown source={this.props.content}/>
        </MDStyle>)
    }
}

const MDStyle = styled.div`
    h1, h2, h3, h4, h5, h6 {
        margin-top: 10px;
        margin-bottom: 2px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;