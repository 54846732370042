import React, { Component } from "react";
import styled from "styled-components";
import FormHandler from "../../../../Components/Form/FormHandler";
import {
  SubmitButton,
  ValidationErrors,
  ErrorMessage,
  ContainerStyle
} from "../../../../Assets/Styles/Style";
import Themes from "../../../../Assets/Styles/Themes";
import FormEntry from "../../../../Components/Form/FormEntry";
import FormPicker from "../../../../Components/Form/FormPicker";
import FormFileUpload from "../../../../Components/Form/FormFileUpload";
import App from "../../../../App";
import SuccessModal from "../../../../Components/Modals/SuccessModal";
import FormMarkdown from "../../../../Components/Form/FormMarkdown";
import ArticleView from "../../../Article/component/ArticleView";
import { SplitNonEmpty, ScrollToTop } from "../../../../Utilities";
import ErrorScreen from "../../../Error";
import { isNullOrUndefined } from "util";
import FormChoice, {
  CheckChoice
} from "../../../../Components/Form/FormChoice";

export default class EditPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      error: null,
      type: null,
      visibility: [], // HR Sense by Default
      categories: [],
      topics: [],
      found: !this.getArticleId(),
      failed: false,
      existingThumb: null,
      existingAttachment: null
    };

    this.submissionError = null;

    this.newCategories = [];
    this.newTopics = [];

    if (this.found) {
      App.Current.SetPageName("Create KB Entry");
    }

    this.types = [
      {
        value: "blogpost",
        label: "Blog Post"
      },
      {
        value: "video",
        label: "Video"
      },
      {
        value: "ebook",
        label: "E-Book"
      },
      {
        value: "infographic",
        label: "Infographic"
      },
      {
        value: "template",
        label: "Template"
      },
      {
        value: "article",
        label: "Article"
      }
    ];

    this.getArticleInfo();
  }

  render() {
    return (
      <div>
        {this.state.found && !this.state.failed && (
          <Section>
            <SuccessModal show={this.state.success} onClose={this.successAck} />

            <h2>{this.state.id ? "Edit" : "Create"} Knowledgebase Entry</h2>
            <hr />
            <div>
              <FormHandler
                ref="form"
                onSubmit={this.submit}
                onValid={this.onValid}
                onInvalid={this.onInvalid}
              >
                <FormEntry
                  name="title"
                  label="Title"
                  characterLimit={80}
                  validations={{
                    hasValue: true,
                    maxLength: 80
                  }}
                  validationErrors={{
                    hasValue: ValidationErrors.required,
                    maxLength: "The Title must be under 80 characters"
                  }}
                  class="admin__create-kb-entry"
                />

                {!this.state.id && (
                  <FormPicker
                    label="Type"
                    name="type"
                    placeholder="Pick one"
                    onChange={this.postTypeChanged}
                    options={this.types}
                    validations="isExisty"
                    validationError={ValidationErrors.required}
                    class="admin__create-kb-entry"
                  />
                )}

                <FormPicker
                  name="categoryId"
                  label="Category"
                  placeholder="Pick one"
                  isEditable={true}
                  options={this.state.categories.map(cat => ({
                    value: cat.id,
                    label: cat.name
                  }))}
                  validations="isExisty"
                  validationError={ValidationErrors.selectOption}
                  onCreateOption={this.addCategory}
                  class="admin__create-kb-entry"
                />

                <FormPicker
                  name="topics"
                  label="Topics"
                  isEditable={true}
                  isMulti={true}
                  options={this.state.topics.map(top => ({
                    value: top.id,
                    label: top.name
                  }))}
                  onCreateOption={this.addTopic}
                  class="admin__create-kb-entry"
                />

                {/* Non-Video Data. */}
                {this.state.type && this.state.type !== "video" && (
                  <FormFileUpload
                    name="thumbnail"
                    label="Upload a Thumbnail"
                    preview={true}
                    existing={this.state.existingThumb}
                    fileTypes={[".png", ".jpg", ".jpeg"]}
                    maxFileSize={10485760}
                    validations={
                      !this.state.existingThumb ? { isExisty: true } : {}
                    }
                    validationErrors={{
                      isExisty: ValidationErrors.required
                    }}
                  />
                )}

                {App.Current.API.KnowledgeBase.IsAttachmentType(
                  this.state.type
                ) && (
                  <FormFileUpload
                    name="attachment"
                    label={`Upload the ${this.getTypeName()}`}
                    maxFileSize={10485760}
                    validations={
                      !this.state.existingAttachment ? { isExisty: true } : {}
                    }
                    validationErrors={{
                      isExisty: ValidationErrors.required
                    }}
                    preContent={
                      this.state.existingAttachment && (
                        <p style={{ marginBottom: "10px" }}>
                          File Uploaded:{" "}
                          <b>
                            {this.state.existingAttachment.filename}{" "}
                            <a
                              target="_blank"
                              href={App.Current.API.KnowledgeBase.GetAttachmentUrl(
                                this.state.id
                              )}
                            >
                              Open
                            </a>
                          </b>
                        </p>
                      )
                    }
                  />
                )}

                <FormMarkdown
                  name="content"
                  label="Content"
                  preview={this.getContentPreview}
                  validations={{
                    isExisty: true
                  }}
                  validationErrors={{
                    isExisty: ValidationErrors.required
                  }}
                />

                {/* Video Data. */}
                {this.state.type == "video" && (
                  <div>
                    <FormEntry
                      name="youTubeId"
                      label="YouTube ID"
                      validations="isExisty"
                      validationError={ValidationErrors.required}
                      class="admin__create-kb-entry"
                    />
                  </div>
                )}

                {/* Article Data. */}
                {this.state.type == "article" && (
                  <div>
                    <FormEntry
                      name="articleSource"
                      label="Source Website"
                      characterLimit={100}
                      validations={{
                        hasValue: true,
                        maxLength: 100
                      }}
                      validationErrors={{
                        hasValue: ValidationErrors.required,
                        maxLength:
                          "The Source Website must be under 100 characters"
                      }}
                      class="admin__create-kb-entry"
                    />

                    <FormEntry
                      name="articleUrl"
                      label="Article Url"
                      validations="isExisty"
                      validationError={ValidationErrors.required}
                      class="admin__create-kb-entry"
                    />
                  </div>
                )}

                {/* Blog Post Data. */}
                {this.isReadable() && (
                  <div>
                    <FormEntry
                      name="readtime"
                      label="Read Time (Minutes)"
                      type="number"
                      class="admin__create-kb-entry"
                    />
                  </div>
                )}

                {/* Post Visiblity (Default to only HR Sense) */}
                <FormChoice
                  name="visibility"
                  label="Visibility"
                  isMulti={true}
                  value={["showInHRS"]}
                >
                  <CheckChoice
                    name="showInHRS"
                    label="Show in HR Sense"
                    value={"showInHRS"}
                  />
                  <CheckChoice
                    name="showInWCB"
                    label="Show in White Collar Blue"
                    value={"showInWCB"}
                  />
                </FormChoice>

                <SubmitButton disabled={this.state.submitting}>
                  {this.state.id ? "Save" : "Create"}
                </SubmitButton>

                {this.getError() ? (
                  <ErrorMessage>{this.getError()}</ErrorMessage>
                ) : null}
              </FormHandler>
            </div>
          </Section>
        )}
        {this.state.failed && <ErrorScreen errorcode="404" />}
      </div>
    );
  }

  getTypeName() {
    const type = this.types.find(t => t.value == this.state.type);
    if (!isNullOrUndefined(type)) {
      return type.label;
    } else {
      return "";
    }
  }

  componentDidMount() {
    ScrollToTop();
  }

  getArticleId() {
    let id = null;

    // Look at the Address.
    const addressParts = SplitNonEmpty(window.location.pathname, "/");

    // Address has ID.
    if (addressParts.length == 4) {
      id = addressParts[3];
    }

    return id;
  }

  getArticleInfo() {
    const id = this.getArticleId();

    // Populate model with Step data if an ID exists.
    if (id) {
      App.Current.API.KnowledgeBase.GetEntry(id, true)
        .then(entry => {
          const visibility = ["showInHRS", "showInWCB"].filter(v => entry[v]);

          this.setState({
            id: id,
            found: true,
            type: entry.type,
            existingThumb: App.Current.API.KnowledgeBase.GetThumbUrl(id),
            existingAttachment: entry.attachment,
            visibility: visibility
          });

          console.log(entry);
          let data = { ...entry };
          data.visibility = visibility;
          data.topics = data.topics.map(t => t.id);
          this.refs.form.loadData(data);

          App.Current.SetPageName(`Edit - ${entry.title}`);
        })
        .catch(e => this.setState({ failed: true }));
    }
  }

  addCategory = category => {
    this.newCategories.push(category);

    this.setState(prevState => ({
      categories: [...prevState.categories, { id: category, name: category }]
    }));
  };

  addTopic = topic => {
    this.newTopics.push(topic);

    this.setState(prevState => ({
      topics: [...prevState.topics, { id: topic, name: topic }]
    }));
  };

  getContentPreview = text => {
    const formData = this.refs.form.getData();

    const article = {
      id: this.state.id,
      author: App.Current.CurrentUser,
      ...formData
    };

    article.content = text;
    if (article.topics) {
      article.topics = article.topics.map(
        id => this.state.topics.find(t => t.id == id) || { id: id, name: id } // existing topic or New Topic
      );
    }

    if (formData.thumbnail) {
      article.thumbUrl = URL.createObjectURL(formData.thumbnail);
    }

    if (formData.attachment) {
      article.attachmentUrl = URL.createObjectURL(formData.attachment);
    }

    return (
      <ArticleView article={article} hideAdmin={true} authenticated={true} />
    );
  };

  successAck = () => {
    this.setState({
      success: false
    });

    if (!this.state.id) {
      this.refs.form.reset();
    }
  };

  componentDidMount() {
    this.getCategorizations();
  }

  getCategorizations() {
    App.Current.API.Administration.GetCategorizationInfo().then(info =>
      this.setState(info)
    );
  }

  isReadable() {
    switch (this.state.type) {
      case "blogpost":
      case "article":
        return true;

      default:
        return false;
    }
  }

  postTypeChanged = type => {
    this.setState({
      type: type
    });
  };

  getError = () => {
    if (this.state.hasErrors) {
      if (this.state.error) {
        return this.state.error;
      } else if (this.state.submissionError) {
        return (
          this.submissionError ||
          "An issue occurred while submitting your request. Try again later."
        );
      } else {
        return "Please fix the errors above to create this post";
      }
    }

    return "";
  };

  onValid = () => {
    this.setState({
      hasErrors: false
    });
  };

  onInvalid = () => {
    this.setState({
      hasErrors: true
    });
  };

  // Submit Data.
  submit = async data => {
    try {
      this.setState({
        submitting: true
      });

      // Rewrite Visibility for Sites
      ["showInHRS", "showInWCB"].forEach(site => {
        data[site] = data.visibility.indexOf(site) !== -1;
      });
      delete data["visibility"];

      // Rewrite topics array for PHP.
      data["topic[]"] = data.topics;
      delete data["topics"];

      if (this.newCategories.length > 0) {
        data["newCategory[]"] = this.newCategories;
      }

      if (this.newTopics.length > 0) {
        data["newTopic[]"] = this.newTopics;
      }

      if (this.state.id) {
        await App.Current.API.Administration.UpdateKnowledgeBaseEntry(
          this.state.id,
          data
        );
        // Refresh Article info.
        this.getArticleInfo();
      } else {
        await App.Current.API.Administration.CreateKnowledgeBaseEntry(data);
      }

      // Clear New Things.
      this.newCategories = [];
      this.newTopics = [];
      this.getCategorizations();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false
      });

      this.submissionError = null;
    } catch (e) {
      console.log(e);
      if (e) {
        this.submissionError = e.message;
      } else {
        this.submissionError = null;
      }
      this.setState({
        success: false,
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  };
}

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  hr {
    margin-bottom: 50px;
    border-top: 1px solid #d5d5d5;
  }
`;
