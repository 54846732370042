import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/** Style **/
import styled, { ThemeProvider } from "styled-components";
import Themes from "../../../Assets/Styles/Themes";

import {
  SmallShadow,
  ShortLine,
  FlexVerticalCenter,
  FlexCenter,
  ButtonStyle,
  BigShadow
} from "../../../Assets/Styles/Style";
/** Image **/
import checkmarkImage from "../../../Assets/Images/checkmark.svg";
import BasicImage from "../../../Assets/Images/basic.svg";
import EssentialImage from "../../../Assets/Images/essential.svg";
import WorksImage from "../../../Assets/Images/works.svg";
import MostPopularImage from "../../../Assets/Images/mostPopular.svg";
import XImage from "../../../Assets/Images/x.svg";
/** components */
import Togglebtn from "../../../Components/Toggle/responsiveToggleBtn";
import DisplayHandler from "../../../Components/DisplayHandler";

class PricingPanel extends Component {
  constructor() {
    super();
    this.state = {
      show: [false, false, false]
    };
  }
  showHandler(num) {
    this.setState(prevState => {
      const newItems = [...prevState.show];
      newItems[num] = !newItems[num];
      return { show: newItems };
    });
  }
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Section>
          <Panel>
            <PanelHeader>
              <div>
                <img src={BasicImage} alt="The Basics" />
                <h4>The Basics</h4>
                <ShortLine />
                <p>Getting Started</p>
              </div>
            </PanelHeader>
            <PanelBody>
              <Description>
                <p>Membership fixed for 12 months</p>
                <p>
                  <b>10% discount if paid upfront</b>
                </p>
                <p className="businessKit-btn" onClick={this.props.click}>
                  <span>Choose</span><b>&nbsp;3 from 5 Business Kits</b>
                </p>
                <p>
                  Excludes Video blogs and 12 months personalised implementation
                </p>
              </Description>
              <YouWillGetDiv>
                <YouWillGetTitle>
                  <h5>You’ll Get</h5>
                  <Togglebtn click={() => this.showHandler(0)} show={this.state.show[0]}/>
                </YouWillGetTitle>
                <DisplayHandler view="desktop">
                  <ShortLine blue />
                  <YouWillGetDescription>
                    <Check />
                    <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                    <Check />
                    <P>
                      Full Audit report with Practical Recommendations and
                      Implementation Guide.
                    </P>
                    <Check />
                    <P>
                      Library of downloadable of over 200 policies, templates
                      and forms to customise.
                    </P>
                    <Check />
                    <P>
                      Membership to exclusive HR Sense Community – connecting
                      you to 500+ SMEs to linkin and discuss ideas
                    </P>
                    <NoCheck />
                    <P disable>Video Blogs</P>
                    <NoCheck />
                    <P disable>
                      Full 12 Month personalised Implementation by HR Sense
                      Professional
                    </P>
                    <Check />
                    <P>Ongoing email and chat support*</P>
                  </YouWillGetDescription>
                </DisplayHandler>
                {this.state.show[0] && (
                  <DisplayHandler view="mobile">
                    <ShortLine blue />
                    <YouWillGetDescription>
                      <Check />
                      <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                      <Check />
                      <P>
                        Full Audit report with Practical Recommendations and
                        Implementation Guide.
                      </P>
                      <Check />
                      <P>
                        Library of downloadable of over 200 policies, templates
                        and forms to customise.
                      </P>
                      <Check />
                      <P>
                        Membership to exclusive HR Sense Community – connecting
                        you to 500+ SMEs to linkin and discuss ideas
                      </P>
                      <NoCheck />
                      <P disable>Video Blogs</P>
                      <NoCheck />
                      <P disable>
                        Full 12 Month personalised Implementation by HR Sense
                        Professional
                      </P>
                      <Check />
                      <P>Ongoing email and chat support*</P>
                    </YouWillGetDescription>
                  </DisplayHandler>
                )}
              </YouWillGetDiv>
            </PanelBody>
          </Panel>
          <Panel>
            <PanelHeader>
              <div>
                <img src={EssentialImage} alt="The Essentials" />
                <h4>The Essentials</h4>
                <ShortLine />
                <p>Getting It Right</p>
              </div>
            </PanelHeader>
            <PanelBody>
              <Description>
                <p>Membership fixed for 12 months</p>
                <p>
                  <b>10% discount if paid upfront</b>
                </p>
                <p className="businessKit-btn" onClick={this.props.click}>
                  <span>Includes</span><b>&nbsp;all 5 Business Kits</b>
                </p>
                <p>Excludes Video Blogs</p>
              </Description>
              <YouWillGetDiv>
                <YouWillGetTitle>
                  <h5>You’ll Get</h5>
                  <Togglebtn click={() => this.showHandler(1)} show={this.state.show[1]}/>
                </YouWillGetTitle>
                <DisplayHandler view="desktop">
                  <ShortLine blue />
                  <YouWillGetDescription>
                    <Check />
                    <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                    <Check />
                    <P>
                      Full Audit report with Practical Recommendations and
                      Implementation Guide.
                    </P>
                    <Check />
                    <P>
                      Library of downloadable of over 200 policies, templates
                      and forms to customise.
                    </P>
                    <Check />
                    <P>
                      Membership to exclusive HR Sense Community – connecting
                      you to 500+ SMEs to linkin and discuss ideas
                    </P>
                    <Check />
                    <P>Video Blogs</P>
                    <NoCheck />
                    <P disable>
                      Full 12 Month personalised Implementation by HR Sense
                      Professional
                    </P>
                    <Check />
                    <P>Ongoing email and chat support*</P>
                  </YouWillGetDescription>
                </DisplayHandler>
                {this.state.show[1] && (
                  <DisplayHandler view="mobile">
                    <ShortLine blue />
                    <YouWillGetDescription>
                      <Check />
                      <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                      <Check />
                      <P>
                        Full Audit report with Practical Recommendations and
                        Implementation Guide.
                      </P>
                      <Check />
                      <P>
                        Library of downloadable of over 200 policies, templates
                        and forms to customise.
                      </P>
                      <Check />
                      <P>
                        Membership to exclusive HR Sense Community – connecting
                        you to 500+ SMEs to linkin and discuss ideas
                      </P>
                      <Check />
                      <P>Video Blogs</P>
                      <NoCheck />
                      <P disable>
                        Full 12 Month personalised Implementation by HR Sense
                        Professional
                      </P>
                      <Check />
                      <P>Ongoing email and chat support*</P>
                    </YouWillGetDescription>
                  </DisplayHandler>
                )}
              </YouWillGetDiv>
            </PanelBody>
          </Panel>
          <Panel>
            <Badge className="js-badge">
              <img src={MostPopularImage} alt="Most Popular" />
            </Badge>
            <PanelHeader>
              <div>
                <img src={WorksImage} alt="The Works" />
                <h4>The Works</h4>
                <ShortLine />
                <p>Peace of Mind!</p>
              </div>
            </PanelHeader>
            <PanelBody>
              <Description>
                <p>Membership fixed for 12 months</p>
                <p>
                  <b>10% discount if paid upfront</b>
                </p>
                <p className="businessKit-btn" onClick={this.props.click}>
                  <span>Includes</span><b>&nbsp;all 5 Business Kits</b>
                </p>
                <p>Receive the full range of services</p>
              </Description>
              <YouWillGetDiv>
                <YouWillGetTitle>
                  <h5>You’ll Get</h5>
                  <Togglebtn click={() => this.showHandler(2)} show={this.state.show[2]}/>
                </YouWillGetTitle>
                <DisplayHandler view="desktop">
                  <ShortLine blue />
                  <YouWillGetDescription>
                    <Check />
                    <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                    <Check />
                    <P>
                      Full Audit report with Practical Recommendations and
                      Implementation Guide.
                    </P>
                    <Check />
                    <P>
                      Library of downloadable of over 200 policies, templates
                      and forms to customise.
                    </P>
                    <Check />
                    <P>
                      Membership to exclusive HR Sense Community – connecting
                      you to 500+ SMEs to linkin and discuss ideas
                    </P>
                    <Check />
                    <P>Video Blogs</P>
                    <Check />
                    <P>
                      Full 12 Month personalised Implementation by HR Sense
                      Professional
                    </P>
                    <Check />
                    <P>Ongoing email and chat support*</P>
                  </YouWillGetDescription>
                </DisplayHandler>
                {this.state.show[2] && (
                  <DisplayHandler view="mobile">
                    <ShortLine blue />
                    <YouWillGetDescription>
                      <Check />
                      <P>HR Sense OnSite Healthcheck with HR Practitioner.</P>
                      <Check />
                      <P>
                        Full Audit report with Practical Recommendations and
                        Implementation Guide.
                      </P>
                      <Check />
                      <P>
                        Library of downloadable of over 200 policies, templates
                        and forms to customise.
                      </P>
                      <Check />
                      <P>
                        Membership to exclusive HR Sense Community – connecting
                        you to 500+ SMEs to linkin and discuss ideas
                      </P>
                      <Check />
                      <P>Video Blogs</P>
                      <Check />
                      <P>
                        Full 12 Month personalised Implementation by HR Sense
                        Professional
                      </P>
                      <Check />
                      <P>Ongoing email and chat support*</P>
                    </YouWillGetDescription>
                  </DisplayHandler>
                )}
              </YouWillGetDiv>
            </PanelBody>
          </Panel>
        </Section>
      </ThemeProvider>
    );
  }
}
PricingPanel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

const Section = styled.section`
  display: grid;
  width: 100%;
    @media ${Themes.breakpoints.md} {
  grid-template-columns: 1fr 1fr 1fr;
    }
  grid-gap: 11px;
`;
const Panel = styled.div`
  border-radius: 20px;
  ${SmallShadow};
  text-align: center;
  position: relative;
  z-index: 1;
`;
const PanelHeader = styled.div`
  ${FlexCenter};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${Themes.colors.lightBlue};
  color: white;
  font-weight: ${Themes.fontWeight.bold};
  div {
    height: 259px;
    ${FlexVerticalCenter};
    img {
      width: 84px;
      height: auto;
      margin: 0 auto 14.5px auto;
    }
    h4 {
      font-size: ${Themes.fontsize.h4};
      font-weight: ${Themes.fontWeight.bold};
    }
    p {
      font-size: ${Themes.fontsize.p1};
    }
  }
`;
const Badge = styled.div`
  position: absolute;
  top: -9px;
  left: -13px;
  z-index: 0;
  width: 142px;
  height: 142px;
`;
const PanelBody = styled.div``;
const Description = styled.div`
  margin: 2em;
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.semiBold};
  b {
    color: ${Themes.colors.lightBlue};
  }
  p {
    margin: 20px auto;
    max-width: 400px;
  }
  .businessKit-btn {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 1em;
    max-width: 300px;
    background: ${Themes.colors.veryLightBlueGrey};
    ${SmallShadow};
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    ${FlexCenter};
    &:hover {
      color: white;
      b {
        color: white;
      }
      background: ${Themes.colors.pink};
    }
  }
`;
const YouWillGetDiv = styled.div`
  padding: 0 31px 30px 39px;
    @media ${Themes.breakpoints.md} {
    margin-bottom: 10px;
  }
  text-align: left;
  h5 {
    font-size: ${Themes.fontsize.h5};
    font-weight: ${Themes.fontWeight.bold};
    color: ${Themes.colors.lightBlue};
    text-align: center;
  }
`;
const YouWillGetTitle = styled.div`
  ${FlexCenter};
  margin-top: 30px;
    @media ${Themes.breakpoints.md} {
    margin-top: 50px;
  }
`;
const YouWillGetDescription = styled.div`
  display: grid;
  grid-template-columns: 10% 80%;
  grid-column-gap: 17px;
  grid-row-gap: 23px;
  margin-top: 33px !important;
`;
const P = styled.p`
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.semiBold};
  color: ${props =>
    props.disable ? `${Themes.colors.lightGrey}` : `${Themes.colors.grey}`};
`;
const Check = styled.div`
    background-image:url(${checkmarkImage});
    margin:0 auto;
    width:26px;
    background-repeat: no-repeat;
`;
const NoCheck = styled.div`
    background-image:url(${XImage});
    margin:5px auto 0 auto;
    width:17px;
    height:16.19px;
`;
export default PricingPanel;
