import React, { Component } from "react";
import { Link } from "react-router-dom";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../Assets/Styles/Themes";
// import "!style-loader:./smallProfileNav.css";
// import "./smallProfileNav.css";
import {
  SmallShadow,
  FlexHorizontalCenter
} from "../../../../Assets/Styles/Style";

/** Images **/
import logoutIcon from "../../../../Assets/Images/admin/logout.svg";
import App from "../../../../App";

class SmallProfileNav extends Component {
  constructor(props) {
    super(props);

    const user = App.Current.CurrentUser;

    this.state = {
      name: `${user.firstname} ${user.lastname}`
    };
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <ThemeProvider theme={Themes}>
        <Nav>
          <MenuDiv>
            <ul>
              <li>
                <Username>{this.state.name}</Username>
              </li>
              {/*<li>
                <Link to="/admin">
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <Link to="/admin">
                  <span>Account Settings</span>
                </Link>
              </li>*/}
              <li onClick={this.logout}>
                <MenuIconDiv>
                  <MenuIcon src={logoutIcon} />
                </MenuIconDiv>
                <span>Logout</span>
              </li>
            </ul>
          </MenuDiv>
        </Nav>
      </ThemeProvider>
    );
  }

  logout = () => {
    App.Current.Navigate("/");
    App.Current.Logout();
  };
}
const Nav = styled.nav`
  ${SmallShadow};
  background-color: white;
  font-size: ${Themes.fontsize.p3};
  flex-direction: column;
  position: relative;
  width: 248px;
  height: auto;
  position: fixed; //don't delete
  top: 76px;
  right: 15px;
  @media ${Themes.breakpoints.lg} {
    right: 30px;
  }
  z-index: 200;
`;
const MenuDiv = styled.div`
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eceeef;
      padding: 12px 5% 12px 30px;
      a {
        color: ${Themes.colors.grey};
        text-decoration: none;
        display: flex;
        ${FlexHorizontalCenter};
      }
      &:hover {
        box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.02);
        a {
          color: ${Themes.colors.blue};
        }
      }
    }
    li:first-child {
      @media ${Themes.breakpoints.sm} {
        display: none;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
`;
const Username = styled.span`
  color: ${Themes.colors.blue};
  p {
    color: ${Themes.colors.grey};
    font-size: ${Themes.fontsize.p3};
  }
`;
const MenuIconDiv = styled.div`
  height: 28px;
  margin-right: 12px;
  ${FlexHorizontalCenter};
`;
const MenuIcon = styled.img`
  width: 18px;
`;
export default SmallProfileNav;
