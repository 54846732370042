import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import App from "../../../../App";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    App.Current.SetPageName("Admin - Dashboard");
  }

  render() {
    return (
      <Section>
        <h2>Dashboard</h2>
      </Section>
    );
  }
}

const Section = styled.section`
  min-height: 100vh;
`;
export default Dashboard;
