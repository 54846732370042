import React, { Component } from "react";
import { Modal } from "react-bootstrap";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  LabelModal,
  InputFieldModal,
  FlexVerticalCenter,
  Paragraph,
  ErrorMessage,
  ValidationErrors
} from "../../Assets/Styles/Style";
/** Image **/
import consultModal from "../../Assets/Images/consult.svg";
import FormHandler from "../Form/FormHandler";
import App from "../../App";
import FormEntry from "../Form/FormEntry";
import { ModalSubmitButton, ModalHeader, ModalContent, HighlightText, ModalBody } from "./styles";
import SuccessModal from "./SuccessModal";

export default class FreeConsultingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: false,
      submissionError: false,
      submitting: false,
      success: false
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
      <SuccessModal
        onClose={this.successAcknowledged.bind(this)}
        show={this.state.success}
      />

      <ModalContent>
        <ModalHeader closeButton>
          <div>
            <img src={consultModal} alt="consult" />
            <h5>Request your free consult</h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <Description>
            <HighlightText>Get one hour <b>FREE</b></HighlightText> with an experienced HR Sense team member to discuss your immediate
            business concerns
          </Description>

          <FormHandler 
            ref="form" 
            preLoad={App.Current.UserFormData}
            onSubmit={this.submit.bind(this)} 
            onValid={this.onValid.bind(this)} 
            onInvalid={this.onInvalid.bind(this)}>
            
            <FormEntry 
              label="First Name"
              name="firstname"
              placeholder="Required"
              validations="hasValue"
              validationError={ValidationErrors.required}
            />

            <FormEntry 
              label="Surname"
              name="surname"
              placeholder="Required"
              validations="hasValue"
              validationError={ValidationErrors.required}
            />

            <FormEntry
              label="Email"
              name="email"
              placeholder="Required"
              validations={{
                hasValue: true,
                isEmail: true
              }}
              validationErrors={{
                hasValue: ValidationErrors.required,
                isEmail: ValidationErrors.validEmailAddress
              }}
            />

            <FormEntry 
              label="Phone"
              name="phone"
              placeholder="Required"
              validations={{
                hasValue: true,
                isPhone: true
              }}
              validationErrors={{
                hasValue: ValidationErrors.required,
                isPhone: ValidationErrors.validPhoneNumber
              }}
            />

            <div className="flex-center">
              <ModalSubmitButton disabled={this.state.submitting}>Submit</ModalSubmitButton>
            </div>

            {this.state.hasErrors ?
                  <ErrorMessage className="flex-center">
                    {this.state.submissionError ? "An issue occurred while submitting your request. Try again later." : "Please fix the errors above to request your consult"}
                  </ErrorMessage>
                :null}

          </FormHandler>
        </ModalBody>
      </ModalContent>
    </Modal>
    );
  }

  onValid(){
    this.setState({
      hasErrors: false
    });
  }

  onInvalid(){
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    try
    {
      this.setState({
        submitting: true
      });

      await App.Current.API.Contact.RequestConsult(data);
      this.refs.form.reset();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false
      });
    }
    catch(e){
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }

  successAcknowledged(){
    this.setState({
      success: false
    });
  }
}

const Description = styled.div`
  ${Paragraph};
  font-size: ${Themes.fontsize.p1};
  b {
    font-weight: ${Themes.fontWeight.black};
  }
  text-align: center;

  @media ${Themes.breakpoints.lg} {
    font-size:${Themes.fontsize.h5};
  }

  margin-top: -35px;
  margin-bottom: 40px;
`;