import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import Themes from "../../../../Assets/Styles/Themes";

import { BigShadow, FlexCenter } from "../../../../Assets/Styles/Style";

class SliderCard extends Component {
  render() {
    let cardText;
    const textLength = this.props.text.length;
    if (textLength < 400) {
      cardText = (
        <CardText style={{ paddingLeft: 50, paddingRight: 50 }}>
          {this.props.text}
        </CardText>
      );
    } else if (textLength >= 400) {
      cardText = <CardText>{this.props.text}</CardText>;
    }
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <CardContainer>
            <CardProfile src={this.props.profile} alt={this.props.name} />
            <Card>
              <div>
                <CardName>{this.props.name}</CardName>
                <CardCompanyName>{this.props.companyName}</CardCompanyName>
              </div>
              {cardText}
            </Card>
          </CardContainer>
        </div>
      </ThemeProvider>
    );
  }
}
SliderCard.propTypes = {
  profile: PropTypes.string,
  name: PropTypes.string,
  companyName: PropTypes.string,
  text: PropTypes.string
};
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(-100px);
  max-width: 750px;
  margin: 0 auto;
    @media ${Themes.breakpoints.md} {
        width:700px;
        transform:translateY(-100px);
    }
  @media ${Themes.breakpoints.xl} {
        width:800px;
    }
`;
const Card = styled.div`
  min-height: 400px;
  ${FlexCenter};
  flex-direction: column;
  box-sizing: border-box;
  ${BigShadow};
  background-color: white;
  width: 100%;
  padding: 35px 5%;
  text-align: center;
    @media ${Themes.breakpoints.sm} {
    border-radius:25px;
  }
    @media ${Themes.breakpoints.lg} {
    height:470px;
  }
`;

const CardProfile = styled.img`
  width: 180px;
  height: 180px;
  padding: 0;
  border: 11px solid white;
  border-radius: 50%;
  z-index: 0;
  transform: translateY(100px);
    @media ${Themes.breakpoints.md} {
    width: 180px;
    height: 180px;
  }
`;
const CardName = styled.p`
  font-size: ${Themes.fontsize.p2};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.blue};
  margin: 80px auto 6px auto;
    @media ${Themes.breakpoints.md} {
    font-size: ${Themes.fontsize.p1};
  }
`;
const CardCompanyName = styled.p`
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.bold};
  color: ${Themes.colors.lightGrey};
  line-height: 20px;
  margin-bottom: 15px;
    @media ${Themes.breakpoints.md} {
    font-size: ${Themes.fontsize.p2};
  }
`;
const CardText = styled.p`
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.bold};
  line-height: 20px;
    @media ${Themes.breakpoints.lg} {
        font-size:${Themes.fontsize.p2}; 
        line-height:28px;
    }
`;
const CardBottom = styled.div`
  background-color: ${Themes.colors.veryLightGrey};
  height: 20px;
  margin: 0 auto;
  transform: translateY(-2px);
  opacity: 0.8;
  width: 100%;
    @media ${Themes.breakpoints.sm} {
        border-bottom-left-radius:50px;
        border-bottom-right-radius:50px;
        width:90%;
    }
`;
export default SliderCard;
