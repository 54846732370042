import { withFormsy } from "formsy-react";
import React from "react";

import { ErrorMessage } from "../../Assets/Styles/Style";

import {
  InputSection,
  InputContainer,
  PrimaryLabel,
  InputDiv,
  Limiter
} from "./FormStyles";

import {
  FormGroup,
  InputGroup,
  FormControl,
  Glyphicon,
  Button
} from "react-bootstrap";
import styled from "styled-components";

import Themes from "../../Assets/Styles/Themes";

class FormEntry extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const val = event.currentTarget.value;
    this.props.setValue(val);

    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }

  render() {
    return (
      <InputContainer className={this.props.class}>
        <InputSection
          hasLabel={this.props.label}
          className="formentry-inputSection"
        >
          {this.props.label ? <PrimaryLabel>{this.props.label}</PrimaryLabel> : null}
          <InputDiv>
            <SearchStyle>
              <FormGroup>
                <InputGroup>
                  <FormControl
                    name={this.props.name}
                    type="text"
                    onChange={this.changeValue}
                    value={this.props.getValue() || ""}
                    placeholder={this.props.placeholder || ""}
                  />
                  <InputGroup.Button>
                    <Button>
                      <Glyphicon glyph="search" />
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
            </SearchStyle>
          </InputDiv>
        </InputSection>
        {this.props.isFormSubmitted() && this.props.getErrorMessage() ? 
          <ErrorMessage>{this.props.isFormSubmitted() && this.props.getErrorMessage()}</ErrorMessage> : null
        }
      </InputContainer>
    );
  }
}

export default withFormsy(FormEntry);

const SearchStyle = styled.div`
  /* overrides bootstrap style */
  .form-group {
    margin-bottom: 0;
    font-size: 16px;
    .input-group {
      input {
        height:45px;
      }
    }
    .form-control{
      font-size: 16px;
    }
  }
  .btn-default {
    background-color: ${Themes.colors.blue};
    border: 1px solid ${Themes.colors.blue};
    width:45px;
    height:45px;
    font-size: 16px;
    &:hover, :active {
      background-color: ${Themes.colors.blue};
      box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
    }
  }
  .glyphicon {
    color: white;
  }
`;
