import React from "react";
import styled from "styled-components";
import Themes from "../Assets/Styles/Themes";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

export default class Counter extends React.Component {
    constructor(props){
        super(props);

        this.pageLoad = true;

        this.state = {
            animate: false
        };
    }

    render(){
        return (<Container>
            <VisibilitySensor 
                onChange={() => this.onVisible()} 
                scrollCheck={true}
                delayedCall={true}>
                <span>
                    <InternalCounter to={this.props.to} animate={this.state.animate} />
                </span>
            </VisibilitySensor>
        </Container>);
    }

    onVisible = () => {
        if(this.pageLoad){
            this.pageLoad = false;
            return;
        }

        this.setState((prevState) => {
            if(!prevState.animate){
                return {
                    animate: true
                };
            }

            return {};
        });
    }
}

const InternalCounter = (props) => {
    return props.to 
    ? props.animate
        ? <CountUp start={0} end={props.to} duration={1.5}/>
        : 0
    : "0";
}

const Container = styled.span`
  font-size: 70px;
  font-weight: ${Themes.fontWeight.extraLight};
  color: ${Themes.colors.blue};
  margin-right: 10px;
  @media ${Themes.breakpoints.xl} {
        font-size:90px;
    }
`;