import { withFormsy } from "formsy-react";
import React from "react";

import {
  ErrorMessage
} from "../../Assets/Styles/Style";

import {
  InputSection,
  InputContainer,
  InputField,
  InputTextArea,
  PrimaryLabel,
  InputDiv,
  Limiter
} from "./FormStyles";

class FormEntry extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  getCharacterCount(value){
    return value ? value.length : 0;
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const val = event.currentTarget.value;
    this.props.setValue(val);

    if(this.props.onChange){
      this.props.onChange(val);
    }
  }

  render() {
    const type = this.props.type || 'text';
    const value = this.props.getValue() || '';
    
    let charCount = null;
    if(this.props.characterLimit){
      charCount = this.getCharacterCount(value);
    }

    return (
      <InputContainer className={this.props.class}>
        <InputSection hasLabel={this.props.label} className="formentry-inputSection">
          <PrimaryLabel>{this.props.label}</PrimaryLabel>
          <InputDiv>
          {type != 'textarea' && 
            <InputField
              name={this.props.name}
              type={type}
              onChange={this.changeValue}
              value={value}
              placeholder={this.props.placeholder || ''}
            />}
          {type == 'textarea' &&
            <InputTextArea
              name={this.props.name}
              type={type}
              onChange={this.changeValue}
              value={value}
              placeholder={this.props.placeholder || ''}
              rows="5"
              data-gramm_editor="false"
            />}
          </InputDiv>
        </InputSection>
        {this.props.characterLimit && <Limiter overLimit={charCount > this.props.characterLimit}>
          {charCount} / {this.props.characterLimit}
        </Limiter>}
        {this.props.isFormSubmitted() && this.props.getErrorMessage() ? 
          <ErrorMessage>{this.props.isFormSubmitted() && this.props.getErrorMessage()}</ErrorMessage> : null
        }
      </InputContainer>
    );
  }
}

export default withFormsy(FormEntry);