import React, { Component } from "react";
import Section01 from "./section01";
import Section02 from "./section02";
import Section03 from "./section03";
import Section04 from "./section04";
import Section05 from "./section05";
import App from "../../App";
import { ScrollToComponent } from "../../Utilities";

class HomeScreen extends Component {
  constructor(props){
    super(props);

    this.state = {
      stats: {
        blogs: 0,
        eBooks: 0,
        videos: 0
      }
    };

    App.Current.SetPageName("Home");
  }

  render() { 
    return (
      <div>
        <Section01
          click={() =>
            ScrollToComponent(this.Section03, {
              offset: 0,
              align: "top",
              duration: 1000
            })
          }
        />
        <Section02 stats={this.state.stats}/>
        <Section03
          className="section03"
          ref={section => {
            this.Section03 = section;
          }}
        />
        <Section04 />
        <Section05 />
      </div>
    );
  }

  async componentDidMount(){
    if(window.location.hash === "#signup"){
      App.Current.RequestSignUp();
      window.location.hash = "#";
    }
    else if(window.location.hash === "#login"){
      App.Current.RequestLogin();
      window.location.hash = "#";
    }

    const filters = await App.Current.API.KnowledgeBase.GetFilters();
    const blogposts = filters.types.find(t => t.type == "blogpost");
    const eBooks = filters.types.find(t => t.type == "ebook");
    const videos = filters.types.find(t => t.type == "video");

    let stats = {
      blogs: blogposts ? parseInt(blogposts.count) : 0,
      eBooks: eBooks ? parseInt(eBooks.count) : 0,
      videos: videos ? parseInt(videos.count) : 0
    };

    this.setState({
      stats: stats
    });
  }
}

export default HomeScreen;
