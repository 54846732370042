import React, {Component} from "react";
import PropTypes from "prop-types";
/** Style **/
import styled, {ThemeProvider} from "styled-components";
import Themes from "../../../Assets/Styles/Themes";
/** Image **/
import {SmallShadow} from "../../../Assets/Styles/Style";
import linkedin from "../../../Assets/Images/linkedin-sm.svg";

class ProfilePanel extends Component {
  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Panel>
          <PanelImage src={this.props.imageUri} alt={this.props.name} />
          <PanelBody>
            <p>{this.props.name}</p>
            <PanelBodyBottom>
              <div>
                <p>{this.props.role}</p>
                {this.props.linkedin === null ? null : (
                  <a href={this.props.linkedin} target="_blank">
                    <img src={linkedin} alt="linkedin" />
                  </a>
                )}
              </div>
            </PanelBodyBottom>
          </PanelBody>
        </Panel>
      </ThemeProvider>
    );
  }
}
ProfilePanel.propTypes = {
  imageUri: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  linkedin: PropTypes.string
};

const Panel = styled.div`
  background: white;
  border-radius: 5px;
  ${SmallShadow};
  margin: 0 auto;
  width: 245px;
  height: 310px;
`;
const PanelImage = styled.img`
  width: 100%;
  height: 220px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
const PanelBody = styled.div`
  padding: 14px 18px;
  p {
    font-size: ${Themes.fontsize.p2};
    font-weight: ${Themes.fontWeight.bold};
  }
`;
const PanelBodyBottom = styled.div`
  height: 32px;
  margin-top: 9px;
  div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    p {
      color: ${Themes.colors.blue};
      font-size: 14px;
      line-height: 16px;
    }
    a {
      img {
        width: 15.6px;
        height: 15.6px;
      }
    }
  }
`;
export default ProfilePanel;
