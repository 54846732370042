import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FormTrueFalse from "../../../Components/Form/FormTrueFalse";
import FormEntry from "../../../Components/Form/FormEntry";
import { ValidationErrors } from "../../../Assets/Styles/Style";
import { WideInputs } from "../../HealthCheck";

export default class PotentialRisks extends Component {
  getTooltips() {
    return {
      NES: (
        <Tooltip id="tooltip">
          <p style={{ padding: 10, textAlign: "left" }}>
            The National Employment Standards (NES) are 10 minimum employment
            entitlements that have to be provided to all employees. The national
            minimum wage and the NES make up the minimum entitlements for
            employees in Australia.
          </p>
        </Tooltip>
      ),
      EBA: (
        <Tooltip id="tooltip">
          <p style={{ padding: 10, textAlign: "left" }}>
            Enterprise bargaining is wage and working conditions being
            negotiated at the level of the individual organisations. Once
            established, they are legally binding on employers and employees. An
            Enterprise Agreement (EA) consists of a collective industrial
            agreement between either an employer and a trade union acting on
            behalf of employees or an employer and employees acting for
            themselves.
          </p>
        </Tooltip>
      ),
      WHS: (
        <Tooltip id="tooltip">
          <p style={{ padding: 10, textAlign: "left" }}>
            The purpose and objective of work health and safety (WHS) law in
            Australia is to provide a legislative framework for employers and
            business operators to take steps to ensure the safety of people they
            employ and others affected by their business or undertaking.
          </p>
        </Tooltip>
      )
    };
  }

  render() {
    const tooltips = this.getTooltips();

    return (
      <WideInputs>
        <FormTrueFalse
          label={
            <div>
              <span>01.</span>
              <span>
                Have you issued compliant employment contracts to all employees?
              </span>
            </div>
          }
          name="compliantContracts"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>02.</span>
              <span>
                Are you confident with your knowledge of the&nbsp;
                <OverlayTrigger placement="top" overlay={tooltips.NES}>
                  <u>NES</u>
                </OverlayTrigger>
                ?
              </span>
            </div>
          }
          name="confidentInNES"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>03.</span>
              <span>
                Does your business have an{" "}
                <OverlayTrigger placement="top" overlay={tooltips.EBA}>
                  <u>EBA</u>
                </OverlayTrigger>
                ?
              </span>
            </div>
          }
          name="hasEBA"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>04.</span>
              <span>Does your culture inspire your employees?</span>
            </div>
          }
          name="inspiringCulture"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>05.</span>
              <span>Do you have a strong induction process in place?</span>
            </div>
          }
          name="strongInduction"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>06.</span>
              <span>
                Do You Have current Standard Operating Procedures in Place?
              </span>
            </div>
          }
          name="currentStandardProcedures"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>07.</span>
              <span>Do you know your rights in Unfair Dismissal Claims?</span>
            </div>
          }
          name="knowsUnfairDismissalRights"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>08.</span>
              <span>
                Would you be compliant with{" "}
                <OverlayTrigger placement="top" overlay={tooltips.WHS}>
                  <u>WHS</u>
                </OverlayTrigger>{" "}
                policies and Procedures?
              </span>
            </div>
          }
          name="compliantWithWHS"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>09.</span>
              <span>
                Are you aware of your employees rights under "General
                Protections" within the Fair Work Act?
              </span>
            </div>
          }
          name="knowsGeneralProtectionsRights"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>10.</span>
              <span>
                Are you aware of "Procedural Fairness" and how that applies to
                performance Management?
              </span>
            </div>
          }
          name="knowsProceduralFairness"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormTrueFalse
          label={
            <div>
              <span>11.</span>
              <span>
                Are you aware of the term "Adverse Action" and how that applies
                to you as Managers and Leaders of Talent?
              </span>
            </div>
          }
          name="knowsAdverseAction"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />

        <FormEntry
          label={
            <div>
              <span>12.</span>
              <span>What is your biggest concern currently?</span>
            </div>
          }
          name="biggestConcern"
          type="textarea"
          characterLimit="300"
          placeholder="Required"
          validations={{
            hasValue: true,
            maxLength: 300
          }}
          validationErrors={{
            hasValue: ValidationErrors.required,
            isEmail: "This field has a maximum of 300 characters only"
          }}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />
      </WideInputs>
    );
  }
}
