import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
/** Style **/
import styled, { css, ThemeProvider } from "styled-components";
import Themes from "../../Assets/Styles/Themes";
import {
  SmallShadow,
  BigShadow,
  FlexHorizontalJustify
} from "../../Assets/Styles/Style";
/** Image */
import likeImg from "../../Assets/Images/knowledgeBase/like.svg";
import commentImg from "../../Assets/Images/knowledgeBase/comment.svg";
import clockImg from "../../Assets/Images/knowledgeBase/clock.svg";
import videoCircleImg from "../../Assets/Images/knowledgeBase/video-circle.svg";
import newsArticleCircleImg from "../../Assets/Images/knowledgeBase/newsArticles-circle.svg";
import templateCircleImg from "../../Assets/Images/knowledgeBase/templates-circle.svg";
import ebookCircleImg from "../../Assets/Images/knowledgeBase/ebooks-circle.svg";
import BlogPostCircleImg from "../../Assets/Images/knowledgeBase/blogPost-circle.svg";
import infographicCircleImg from "../../Assets/Images/knowledgeBase/infographic-circle.svg";
import App from "../../App";
import MDPlainTextConverter from "../MDPlainTextConverter";

export default class KnowledgeBaseEntry extends Component {
  constructor(props) {
    super(props);
    this.entry = props.entry;
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Link to={`/knowledgebase/${this.entry.slug}`}>
          <Panel>
            <Thumbnail src={this.getThumb()} />
            <Circle src={this.getSymbol()} />
            <PanelBody>
              <h5>{this.entry.title}</h5>
              {/* <Author>{`${this.entry.author.firstname} ${this.entry.author.lastname}`}</Author> */}
              <MDPlainTextConverter content={this.entry.summary} />
            </PanelBody>
            <PanelBodyBottom>
              {this.entry.type != "article" && (
                <LikeAndComment>
                  {/*<Like>
                    <img src={likeImg} alt="like" />
                    <span>{0}</span>
                  </Like>
                   <Comment>
                  <img src={commentImg} alt="comment" />
                  <span>{0}</span>
                </Comment> */}
                </LikeAndComment>
              )}
              {this.entry.readtime && (
                <ReadTime>
                  <img src={clockImg} alt="read time" />
                  <span>{this.entry.readtime} min read</span>
                </ReadTime>
              )}
              {this.entry.type == "article" && this.entry.articleSource && (
                <p>{this.entry.articleSource}</p>
              )}
            </PanelBodyBottom>
          </Panel>
        </Link>
      </ThemeProvider>
    );
  }

  getThumb() {
    return App.Current.API.KnowledgeBase.GetThumbUrl(this.entry.slug);
  }

  getSymbol() {
    switch (this.entry.type) {
      case "video":
        return videoCircleImg;

      case "article":
        return newsArticleCircleImg;

      case "template":
        return templateCircleImg;

      case "infographic":
        return infographicCircleImg;

      case "ebook":
        return ebookCircleImg;

      case "blogpost":
        return BlogPostCircleImg;

      default:
        return null;
    }
  }
}

const Panel = styled.div`
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  ${SmallShadow};
  position: relative;
  width: 100%;
  height: 410px;
`;
const Thumbnail = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;
const Circle = styled.img`
  width: 55px;
  position: absolute;
  left: 10px;
  top: 157px;
`;
const PanelBody = styled.div`
  padding: 36px 19px 18px;
  height: 180px;
  color: ${Themes.colors.black};
  p {
    /* summary */
    font-size: ${Themes.fontsize.p3};
    color: ${Themes.colors.grey};
    font-family: "Open Sans", sans-serif;
    margin-top: 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  h5 {
    font-weight: ${Themes.fontWeight.bold};
    line-height: unset;
  }
`;
const PanelBodyBottom = styled.div`
  height: 50px;
  position: absolute;
  bottom: 0;
  position: relative;
  ${FlexHorizontalJustify};
  p {
    font-weight: ${Themes.fontWeight.bold};
    font-size: ${Themes.fontsize.p4};
    color: ${Themes.colors.darkGrey};
    float: right;
    position: absolute;
    bottom: 20px;
    right: 19px;
  }
`;

const LikeAndComment = styled.div`
  position: absolute;
  bottom: 20px;
  left: 19px;
  font-size: ${Themes.fontsize.p3};
  font-family: "Open Sans", sans-serif;
  img {
    width: 16px;
    height: auto;
    margin-right: 4px;
  }
`;
const Like = styled.span`
  margin-right: 18px;
  span {
    color: ${Themes.colors.darkGrey} !important;
  }
`;
const Comment = styled.span``;
const ReadTime = styled.div`
  font-weight: ${Themes.fontWeight.bold};
  font-size: ${Themes.fontsize.p4};
  color: ${Themes.colors.darkGrey};
  img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }
  position: absolute;
  bottom: 20px;
  right: 19px;
`;
const Author = styled.p`
  font-size: 10px;
  font-weight: bold;
  color: ${Themes.colors.blue};
`;
