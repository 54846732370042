import React, { Component } from "react";

import FormChoice, { CheckChoice } from "../../../Components/Form/FormChoice";
import ToggleBtn from "../../../Components/Toggle/responsiveToggleBtn";

/** Style **/
import { PanelDiv, TitleDiv, Body } from "./styles";
import DisplayHandler from "../../../Components/DisplayHandler";

export default class TopicsPanel extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }
  showHandler = () => {
    this.setState(prevState => {
      return { show: !prevState.show };
    });
  };

  render() {
    return (
      <div>
        <PanelDiv>
          <TitleDiv>
            <p>Topics</p>
            <ToggleBtn click={this.showHandler} show={this.state.show}/>
          </TitleDiv>
          {this.state.show && (
            <DisplayHandler view="mobile">
              <CollapseContent topics={this.props.topics} extraStyle="kb-filter-responsive" />
            </DisplayHandler>
          )}
          <DisplayHandler view="desktop">
            <CollapseContent topics={this.props.topics} />
          </DisplayHandler>
        </PanelDiv>
      </div>
    );
  }
}

class CollapseContent extends Component {
  render() {
    const numRows = Math.ceil(this.props.topics.length / 3);
    const style = { gridTemplateRows: `repeat(${numRows}, 1fr)` };
    const extraStyle = this.props.extraStyle;

    return (
      <Body>
        <FormChoice
          name="topic[]"
          value={[]}
          isMulti={true}
          inputDivClassName={`grid-wrap ${extraStyle}`}
          style={style}
        >
          {this.props.topics &&
            this.props.topics.map(topic => (
              <CheckChoice
                key={topic.id}
                value={topic.id}
                label={topic.name}
                className="kb-inputs"
                afterContent={
                  <span className="kb-inputs__count">{topic.count}</span>
                }
              />
            ))}
        </FormChoice>
      </Body>
    );
  }
}
