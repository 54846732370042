export const GeneralHR = [
    "Annual Reviews",
    "Detailed Employment Contracts",
    "Development Planning and Training Needs Assessment,",
    "Change Management Support",
    "Employee Assistance Programs",
    "Employee Counselling",
    "Employee Satisfaction Surveys",
    "Exit Interviews",
    "HR Information Systems",
    "HR Metrics and Reporting",
    "HR Training",
    "Implementation of Key Performance Measures Matched to Desired Outcomes,",
    "Measures",
    "Incentive Programs",
    "Job Description Development",
    "Leadership Development",
    "Performance Management",
    "Policies & Procedures",
    "System Operating Process Manuals",
    "Salary and Commission Design and Implementation",
    "Terminations"
]

export const Recruitment = [
    "Onboarding & Induction Programs", 
    "Outplacement Services", 
    "Recruitment", 
    "Reference Checks", 
    "Talent Management"
]

export const Fairwork = [
    "Discrimination, Harassment and Bullying Claims", 
    "Diversity/Affirmative Action", 
    "Fair Work Act Interpretation and Application", 
    "Industrial Relations", 
    "Investigations", 
    "Legal Compliance", 
    "Legal Representation at Fair Work Commission Hearings", 
    "Unfair Dismissal Allegations"
]

export const Safety = [
    "Claims Management", 
    "Injury & Near Miss Reporting Compliance", 
    "Injury Management", 
    "Return to Work", 
    "Risk Assessments", 
    "Tool Box Talks"
]

export const Accounts = [
    "Accounts Functions", 
    "Accounts Payable", 
    "Accounts Receivable", 
    "Benefits Administration Reporting", 
    "Debtors",
    "Payroll"
]