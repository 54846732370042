import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import {ErrorMessage, ValidationErrors, FlexCenter} from "../../Assets/Styles/Style";

/** Image **/
import lockModal from "../../Assets/Images/lock_modal.svg";
import {
  ModalContent,
  ModalHeader,
  ModalSubmitButton,
  ModalBody,
  ModalSmallLink,
  ButtonRow
} from "./styles";
import FormEntry from "../Form/FormEntry";
import FormHandler from "../Form/FormHandler";
import App from "../../App";

export default class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      show: false,
      error: null
    };

    App.Current.OnLoginRequested(() => {
      this.setState({
        show: true
      });
    });
  }

  hide = () => {
    this.setState({
      show: false,
      error: null,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      forgotPassword: false,
      forgotPasswordSubmitted: false,
      submitted: false
    });
  };

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.hide}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg">
        <ModalContent className="log-in-modal">
          <ModalHeader closeButton>
            <div>
              <img src={lockModal} alt="login" />
              <h5>{!this.state.forgotPassword ? "LOGIN" : "FORGOT PASSWORD"}</h5>
            </div>
          </ModalHeader>
          <ModalBody className="log-in-modal__body">
            {!this.state.forgotPasswordSubmitted && (
              <FormHandler
                ref="form"
                onSubmit={this.submit.bind(this)}
                onValid={this.onValid.bind(this)}
                onInvalid={this.onInvalid.bind(this)}>
                <FormEntry
                  label="Email Address"
                  name="emailaddress"
                  placeholder="Email Address"
                  validations={{
                    hasValue: true,
                    isEmail: true
                  }}
                  validationErrors={{
                    hasValue: ValidationErrors.required,
                    isEmail: ValidationErrors.validEmailAddress
                  }}
                  className="log-in-modal__form-entry"
                />

                {!this.state.forgotPassword && (
                  <div>
                    <FormEntry
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      validations="hasValue"
                      validationError={ValidationErrors.required}
                      className="log-in-modal__form-entry"
                    />

                    <ButtonRow>
                      <ModalSubmitButton disabled={this.state.submitting}>
                        Log in
                      </ModalSubmitButton>
                      <ModalSubmitButton
                        type="button"
                        onClick={this.signUp}
                        disabled={this.state.submitting}
                        grey>
                        Sign Up
                      </ModalSubmitButton>
                    </ButtonRow>
                    <ModalSmallLink>
                      <a href="#" onClick={this.showForgotPassword}>
                        <p>Forgot Password?</p>
                      </a>
                    </ModalSmallLink>
                  </div>
                )}

                {this.state.forgotPassword && (
                  <div>
                    <ButtonRow>
                      <ModalSubmitButton disabled={this.state.submitting}>
                        Submit
                      </ModalSubmitButton>
                      <ModalSubmitButton grey>
                        <a
                          href="#"
                          onClick={this.backToLogin}
                          style={{color: "white"}}>
                          Login instead
                        </a>
                      </ModalSubmitButton>
                    </ButtonRow>
                  </div>
                )}

                {this.getError() ? (
                  <ErrorMessage className="flex-center">
                    {this.getError()}
                  </ErrorMessage>
                ) : null}
              </FormHandler>
            )}

            {this.state.forgotPasswordSubmitted && (
              <div className="flex-center-column">
                <p className="flex-center-column forgot-pw-submitted__message modal-text">
                  <b>Success!</b>
                  <span>
                    If an account matches the Email address you provided, you will
                    get an Email soon with a Link to recover your account.
                  </span>
                </p>
                <ModalSubmitButton className="flex-center">
                  <a href="#" onClick={this.backToLogin} style={{color: "white"}}>
                    Login instead
                  </a>
                </ModalSubmitButton>
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  showForgotPassword = () => {
    this.setState({
      forgotPassword: true
    });
  };

  backToLogin = () => {
    this.setState({
      forgotPassword: false,
      forgotPasswordSubmitted: false
    });
  };

  getError = () => {
    if (this.state.hasErrors) {
      if (this.state.error) {
        return this.state.error;
      } else {
        return "An issue occurred while submitting your request. Try again later.";
      }
    }

    return "";
  };

  signUp = () => {
    this.setState({
      show: false
    });

    App.Current.RequestSignUp();
  };

  onValid() {
    this.setState({
      hasErrors: false
    });
  }

  onInvalid() {
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    let error = null;

    try {
      this.setState({
        submitting: true
      });

      if (this.state.forgotPassword) {
        await App.Current.API.User.ForgotPassword(data);
        this.refs.form.reset();

        this.setState({
          forgotPasswordSubmitted: true
        });
        return;
      } else if (
        await App.Current.API.Authentication.login(data.emailaddress, data.password)
      ) {
        this.refs.form.reset();

        this.setState({
          success: true,
          hasErrors: false,
          submissionError: false,
          submitting: false,
          show: false,
          error: null
        });

        App.Current.LoginSuccessful();
        return;
      }
    } catch (e) {
      error = e.issue;
    }

    this.setState({
      hasErrors: true,
      submissionError: true,
      submitting: false,
      error: error
    });
  }
}
