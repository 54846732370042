import React, {Component} from "react";
import App from "../../../App";

import styled, {ThemeProvider} from "styled-components";

import Themes from "../../../Assets/Styles/Themes";
import {
  FlexCenter,
  FlexVerticalCenter,
  FlexHorizontalJustify,
  KnowledgeBaseParagraph,
  Thumbnail,
  SmallShadow,
  BigShadow,
  SubmitButton,
  ClearBoth
} from "../../../Assets/Styles/Style";

import {Grid, Col, Row} from "react-bootstrap";
/** Image */
import linkedin from "../../../Assets/Images/knowledgeBase/linkedin-sm.svg";
import email from "../../../Assets/Images/knowledgeBase/envelope.svg";
import KnowledgeBaseEntry from "../../../Components/KnowledgeBase/KnowledgeBaseEntry";
import KBLoginScreen from "./KBLoginScreen";
import MarkdownRenderer from "../../../Components/MarkdownRenderer";
import KBRoundButton from "../../../Components/Button/KBRoundButton";
import DownloadIcon from "../../../Assets/Images/knowledgeBase/download.svg";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  WorkplaceShareButton,
  EmailShareButton
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  WorkplaceIcon,
  EmailIcon
} from "react-share";

export default class ArticleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showShareOptions: false
    };
  }

  render() {
    if (!this.props.article) return null;
    let article = this.props.article;
    let author = article.author;
    let authenticated = this.props.authenticated || false;
    let admin =
      authenticated && !this.props.hideAdmin && App.Current.CurrentUser.admin;

    // Get Thumb from Proper location if exists, otherwise from thumbUrl.
    const thumb = article.id
      ? App.Current.API.KnowledgeBase.GetThumbUrl(article.id)
      : article.thumbUrl;

    const attachmentUrl = article.id
      ? App.Current.API.KnowledgeBase.GetAttachmentUrl(article.id)
      : article.attachmentUrl;

    /*     const attachmentName = article.attachment
      ? article.attachment.filename
      : "attachment"; */

    // Get Article Url for Sharing.
    let url = `http://${window.location.host}/knowledgebase`;
    if (article.id) {
      url += `/${article.slug}`;
    }

    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Grid>
            <Row>
              <StyledColLeft xs={12} lg={9}>
                <Header>
                  <h1>{article.title}</h1>
                  <HeaderBottom>
                    {author && (
                      <AuthorDiv>
                        <span>by </span>
                        <Author>
                          {author.firstname} {author.lastname}
                        </Author>
                        {author.linkedInId && (
                          <a
                            href={`https://www.linkedin.com/in/${author.linkedInId}/`}
                            target="_blank">
                            <LinkedinImg src={linkedin} alt="linkedin" />
                          </a>
                        )}
                        <a href={`mailto:${author.emailaddress}`}>
                          <EmailImg src={email} alt="email" />
                        </a>
                      </AuthorDiv>
                    )}
                    {article.topics && (
                      <Tagdiv>
                        {article.topics.map(topic => (
                          <TagSpan key={topic.id}>{topic.name}</TagSpan>
                        ))}
                      </Tagdiv>
                    )}
                  </HeaderBottom>
                </Header>
                <Body>
                  <ArticleImgDiv>
                    {article.youTubeId && (
                      <div>
                        <iframe
                          type="text/html"
                          width="640"
                          height="360"
                          src={`https://www.youtube.com/embed/${article.youTubeId}`}
                          frameBorder="0"
                        />
                      </div>
                    )}
                    {!article.youTubeId && (
                      <Thumbnail
                        thumb={thumb}
                        blur={!authenticated}
                        absolute={true}
                      />
                    )}
                    {!authenticated && <KBLoginScreen />}
                  </ArticleImgDiv>

                  <DescriptionDiv>
                    <MarkdownRenderer
                      content={authenticated ? article.content : article.summary}
                    />
                  </DescriptionDiv>

                  <Footer>
                    {authenticated && article.attachment && (
                      <DownloadDiv>
                        <div>
                          <b>Click the button below to download this content</b>
                        </div>
                        <a target="_blank" href={attachmentUrl}>
                          <SubmitButton>
                            <img src={DownloadIcon} alt="download" />
                            Download
                          </SubmitButton>
                        </a>
                      </DownloadDiv>
                    )}

                    <FavAndShareDiv>
                      {admin && (
                        <KBRoundButton content="Edit" onClick={this.toEdit} />
                      )}
                      {/*authenticated && <KBRoundButton content="Favourite" hollow />*/}
                      <EmailShareButton url={url}>
                        <EmailIcon size={35} round={true} />
                      </EmailShareButton>
                      <LinkedinShareButton url={url}>
                        <LinkedinIcon size={35} round={true} />
                      </LinkedinShareButton>
                      <TwitterShareButton url={url}>
                        <TwitterIcon size={35} round={true} />
                      </TwitterShareButton>
                      <FacebookShareButton url={url}>
                        <FacebookIcon size={35} round={true} />
                      </FacebookShareButton>
                      <WorkplaceShareButton url={url}>
                        <WorkplaceIcon size={35} round={true} />
                      </WorkplaceShareButton>
                      <RedditShareButton url={url}>
                        <RedditIcon size={35} round={true} />
                      </RedditShareButton>
                    </FavAndShareDiv>
                  </Footer>
                </Body>

                <ClearBoth />
              </StyledColLeft>
              {article.related && article.related.length > 0 && (
                <StyledCol xs={12} lg={3}>
                  <Aside>
                    <AsideHeader>
                      <div className="subtitle-with-line--title">
                        Related Articles
                      </div>
                      <div className="subtitle-with-line--divider" />
                    </AsideHeader>
                    <AsideBody>
                      {article.related.map(entry => (
                        <KnowledgeBaseEntry key={entry.id} entry={entry} />
                      ))}
                    </AsideBody>
                  </Aside>
                </StyledCol>
              )}
            </Row>
          </Grid>
        </div>
      </ThemeProvider>
    );
  }

  toEdit = () => {
    App.Current.Navigate(`/admin/kb/edit/${this.props.article.id}`);
  };

  toggleShare = () => {
    console.log("Toggle");
    this.setState(prevState => ({
      showShareOptions: !prevState.showShareOptions
    }));
  };
}

const StyledCol = styled(Col)`
  padding: 0 20px;
  @media ${Themes.breakpoints.md} {
    padding: 0;
  }
`;
const StyledColLeft = styled(Col)`
  padding: 0 20px;
  @media ${Themes.breakpoints.lg} {
    padding: 0 40px 0 0 !important;
  }
`;
const Header = styled.div`
  h1 {
    color: ${Themes.colors.black};
    font-weight: ${Themes.fontWeight.bold} !important;
    font-size: ${Themes.fontsize.h3};
    line-height: 1.4em;
  }

  @media ${Themes.breakpoints.md} {
    h1 {
      font-size: ${Themes.fontsize.h2};
      margin-bottom: 10px;
    }
  }
`;
const HeaderBottom = styled.div`
  margin-top: 20px;
  font-weight: ${Themes.fontWeight.bold};
  font-size: ${Themes.fontsize.p2};
  color: ${Themes.colors.lightBlue};
  @media ${Themes.breakpoints.md} {
    ${FlexHorizontalJustify};
    margin-top: 40px;
  }
`;
const AuthorDiv = styled.div`
  font-weight: ${Themes.fontWeight.bold};
  font-size: ${Themes.fontsize.p1};
  margin-bottom: 10px;
  @media ${Themes.breakpoints.md} {
    margin-bottom: 0px;
  }
`;
const LinkedinImg = styled.img`
  width: 16px;
  margin-right: 5px;
`;
const EmailImg = styled.img`
  height: 16px;
`;
const Author = styled.span`
  margin-right: 12px;
  color: ${Themes.colors.darkGrey};
`;
const Tagdiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TagSpan = styled.span`
  border-radius: 50px;
  ${FlexCenter};
  border: 1px solid ${Themes.colors.lightBlue};
  box-shadow: none;
  color: ${Themes.colors.lightBlue};
  font-size: 13px;
  font-weight: ${Themes.fontWeight.semiBold};
  padding: 7px 10px;
  width: auto;
  margin-right: 5px;
  margin-bottom: 5px;
  @media ${Themes.breakpoints.md} {
    margin-bottom: 0px;
  }
`;
const Body = styled.div`
  margin-top: 20px;
  @media ${Themes.breakpoints.lg} {
    margin-top: 25px;
  }
`;
const ArticleImgDiv = styled.div`
  width: 100%;
  img,
  div {
    width: 100%;
  }
  /* make video responsive */
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const DescriptionDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;
  @media ${Themes.breakpoints.lg} {
    margin-top: 50px;
    margin-bottom: 60px;
  }
  p,
  li {
    ${KnowledgeBaseParagraph};
  }
`;
const Footer = styled.div`
  /* display: flex;
 align-items: flex-end;
 justify-content: space-between; */
  @media ${Themes.breakpoints.md} {
    position: relative;
  }
`;
const DownloadDiv = styled.div`
  width: 100%;
  @media ${Themes.breakpoints.md} {
    width: 500px;
  }
  border-radius: 20px;
  ${BigShadow};
  padding: 30px 20px;
  ${FlexVerticalCenter};
  div {
    text-align: center;
    b {
      color: ${Themes.colors.grey};
      font-size: 18px;
    }
  }
  img {
    width: 22px;
    margin-right: 12px;
    color: white;
  }
  button {
    width: 100%;
    max-width: 315px;
    ${BigShadow};
    margin-top: 15px;
  }
`;
const FavAndShareDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  & > * {
    margin-left: 5px;
  }
  @media ${Themes.breakpoints.md} {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
/** Aside */
const Aside = styled.aside`
  margin-top: 50px;

  @media ${Themes.breakpoints.lg} {
    margin-top: 140px;
    width: 300px;
  }
`;
const AsideHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2em 0;
  .subtitle-with-line--title {
    flex-grow: 0;
    color: #026951;
    font-weight: 700;
    margin-right: 0.5em;
    color: ${Themes.colors.black};
    font-weight: ${Themes.fontWeight.bold};
    font-size: ${Themes.fontsize.h5};
  }
  .subtitle-with-line--divider {
    flex-grow: 1;
    height: 2px;
    background-color: ${Themes.colors.lightGrey};
  }
  margin-bottom: 30px;
`;
const AsideBody = styled.div`
  display: grid;
  grid-gap: 20px;
  @media ${Themes.breakpoints.sm} {
    grid-template-columns: auto auto;
  }
  @media ${Themes.breakpoints.md} {
    grid-template-columns: auto auto auto;
  }
  @media ${Themes.breakpoints.lg} {
    grid-template-columns: auto;
  }
`;
