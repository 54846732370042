import React, { Component } from "react";
import FormTrueFalse from "../../../Components/Form/FormTrueFalse";
import FormChoice, { RadioChoice } from "../../../Components/Form/FormChoice";
import { ValidationErrors } from "../../../Assets/Styles/Style";
import { WideInputs } from "../../HealthCheck";

export default class AboutYourBusiness extends Component {
  render() {
    return (
      <WideInputs>
        <FormChoice
          horizontal={true}
          label={
            <div>
              <span>01.</span>
              <span>How many people are in your business?</span>
            </div>
          }
          name="businessSize"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        >
          <RadioChoice label={"1\u00A0-\u00A05"} value="1-5" />
          <RadioChoice label={"5\u00A0-\u00A020"} value="5-20" />
          <RadioChoice label="20+" value="20+" />
        </FormChoice>

        <FormTrueFalse
          label={
            <div>
              <span>02.</span>
              <span>Do you have an HR Department?</span>
            </div>
          }
          name="hasHR"
          trueLabel="YES"
          falseLabel="NO"
          validations="isExisty"
          validationError={ValidationErrors.selectOption}
          class="healthcheck-form__input-container"
          inputDivClassName="healthcheck-form__input"
        />
      </WideInputs>
    );
  }
}
