import React, { Component } from "react";
/** Style */
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../../../Assets/Styles/Themes";
import { FlexCenter } from "../../../../Assets/Styles/Style";
/** Component */
import DrawerToggleButton from "../SideNav/BurgerToggleButton";
import ProfileToggleButton from "../RightSideNav/ProfileToggleButton";
import ArrowToggle from "./components/ArrowToggle";
import App from "../../../../App";

/** Image */

class Toolbar extends Component {
  constructor(props){
    super(props);
    
    const user = App.Current.CurrentUser;

    this.state = {
      name: `${user.firstname} ${user.lastname}`
    };
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <Header>
          <HeaderContainer>
            <DrawerToggleButton click={this.props.drawerClickHandler} />
            <Title></Title>
            <HeaderLeft>
              <Profile>
                <p>{this.state.name}</p>
                <ArrowToggle
                  className="arrow-style"
                  click={this.props.smallProfileNavToggleClickHandler}
                />
              </Profile>
            </HeaderLeft>
          </HeaderContainer>
        </Header>
      </ThemeProvider>
    );
  }
}

const Header = styled.div`
  // position: absolute;
  // top: 120px;
  // left: 0;
  ${FlexCenter};
  width: 100%;
  height: 60px;
  background-color: white;
  padding: 0 5%;
  border-bottom: 1px solid #D5D5D5;
    @media ${Themes.breakpoints.sm} {
    height: 70px;
    padding: 0 0 0 5%;
  }
`;
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeaderLeft = styled.div`
  display: flex;
  height: 100%;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${Themes.colors.grey};
    @media ${Themes.breakpoints.sm} {
    font-size: 30px;
  }
`;
const BellDiv = styled.div`
  height: 100%;
  display: none;
    @media ${Themes.breakpoints.sm} {
    ${FlexCenter};
    padding:0 25px;
    border-left: 1px solid ${Themes.colors.lightGrey};
  }
  img {
    width: 25px;
  }
`;
const Profile = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
@media ${Themes.breakpoints.sm} {
  display: flex;
  padding:0 15px;
  border-left: 1px solid #D5D5D5;
  .arrow-style {
    margin-left:10px;
  }
}
@media ${Themes.breakpoints.lg} {
    padding:0 40px;
}
p {
  font-size: ${Themes.fontsize.p3};
  font-weight: ${Themes.fontWeight.bold};
  padding: 0 0 0 15px;
  display: none;
  @media ${Themes.breakpoints.lg} {
    display: block;
  }
}
`;
export default Toolbar;
