import React, { Component } from 'react';
import Themes from "../../../../Assets/Styles/Themes";
// import './ProfileToggleButton.css';
import styled, { ThemeProvider } from 'styled-components';

import Grant from '../../../../Assets/Images/Grant.jpg';

class ProfileToggleButton extends Component {
    render(){
        return(
            <ThemeProvider theme={Themes}>
                <Button onClick={this.props.click} />
            </ThemeProvider>
        )
    }
}

const Button = styled.button`
    width:38px;
    height:38px;
    background-image: url(${Grant});
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
    border-radius: 50px;
    border:none;
`

export default ProfileToggleButton;