import React, { Component } from "react";
import Fade from "react-reveal/Fade";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  ContainerStyle,
  Line,
  ShortLine,
  FlexVerticalCenter,
  PricingTableCommonStyle,
  SmallShadow,
  Paragraph,
  ClearBoth
} from "../../Assets/Styles/Style";
/** Component */
import RequestButton from "../../Components/Button/RequestButton";
import TogglePanel from "./component/TogglePanel";
/** Image **/
import Out from "../../Assets/Images/outsourcing-white.svg";
import HR from "../../Assets/Images/HR-white.svg";
import Coaching from "../../Assets/Images/coaching.svg";
/** Data */
import {
  ToggleList01,
  ToggleList02,
  ToggleList03,
  ToggleList04
} from "./data/ToggleLists";

export default class Section05 extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
        <div>
          <Section>
            <Container>
              <ScrollTarget id="outsourcing"/>
              <Fade bottom>
                <TextDiv>
                  <Text>
                    Depending on what your needs are, you can engage with the HR
                    Sense team on an ongoing basis through our outsourced
                    option, or through a project based approach utilising the HR
                    Consulting offering.
                  </Text>
                  <Line />
                </TextDiv>
              </Fade>
              {/* 1.Outsourcing Table Start */}
              <ContentsContainer>
                <BlueDiv>
                  <div>
                    <img src={Out} alt="outsourcing" />
                    <h3>Outsourcing</h3>
                  </div>
                  <ShortLine />
                  <p>
                    Outsource Your Non-Core Administration & Accounts Functions
                  </p>
                </BlueDiv>
                <Contents>
                  <b>
                    Virtually any HR function can be outsourced. <br />
                    <br />
                    Non-core business activities can sometimes take our eye off
                    our main business purpose and so in many occasions it is
                    more productive and cost efficient to Outsource. When used
                    properly, outsourcing is an effective strategy to reduce
                    operational costs and in some instances can provide a
                    business with a competitive edge over rivals.
                  </b>
                  <Margin />
                  <h5>Why outsource your HR?</h5>
                  <p>
                    HR outsourcing is a hot topic with countless organisations
                    prospering from taking this direction.
                    <br />
                    <br />
                    Outsourcing with HR Sense enables you to be more productive
                    and strategic by providing you with your own virtual HR
                    team, thus helping managers become confident and supported
                    in managing their people-related issues.
                  </p>
                  <Margin />
                  <TogglePanel
                    title="The most commonly outsourced HR activities are"
                    listName={ToggleList01}
                  />
                  <TogglePanel
                    title="Some of the most common reasons that company’s outsource include:"
                    listName={ToggleList02}
                  />
                </Contents>
                <ButtonDiv onClick={this.props.requestACall("Outsourcing")}>
                  <RequestButton title="Request a call for Outsourcing"/>
                </ButtonDiv>
              </ContentsContainer>

              {/* 1. Outsourcing Table End */}
              {/* 2. HR Consulting Table Start */}
              <ScrollTarget id="hr" style={{ height: 10 }} />
              <ContentsContainer>
                <BlueDiv>
                  <div>
                    <img src={HR} alt="hr" />
                    <h3>HR Consulting</h3>
                    <p>Hourly Hire</p>
                  </div>
                  <ShortLine />
                  <p>
                    We are available to support you on any HR Initiatives by the
                    hour
                  </p>
                </BlueDiv>
                <Contents>
                  <b>
                    We work collaboratively with our clients to seek and
                    understand your business, it’s drivers, it’s culture and
                    it’s objectives. This is where we can support you by
                    tailoring our services specific to your business needs.
                    Whatever stage your internal HR function is currently at HR
                    Sense can help streamline your processes by offering our
                    customised HR Consulting service tailored to your specific
                    need.
                  </b>
                  <Margin />
                  <TogglePanel
                    title="HR Sense offers a range of flexible HR consulting Price options"
                    listName={ToggleList03}
                  />
                  <Margin />
                  <b>
                    HR Sense can customise an HR consulting service that
                    supports both your budget and your company’s HR needs. With
                    a group of experienced HR Practitioners, we are in a great
                    position to support you across the landscape in all things
                    HR. As the HR Specialist – HR Sense is able to provide your
                    organisation with a tailored HR Solution. Our expert
                    practitioners are available short term, long term, on-demand
                    or on an outsourced basis. Our HR Practitioners offer sound
                    advice and practical HR solutions to a broad range of the
                    challenges most company’s face in day to day activities.
                  </b>
                  <Margin />
                  <TogglePanel
                    title="Some examples of challenges we support incorporate"
                    listName={ToggleList04}
                  />
                  <Margin />
                  <h5>Audits</h5>
                  <p>
                    Where an organisation is seeking to assess their own systems
                    internally we can provide independent business audits
                    targeting key areas of the business.
                    <br />
                    <br />
                    We will assist your procedural deficiencies and areas for
                    improvement. HR Audits can be used to develop action or
                    business plans based on the recommendations
                    <br />
                    <br />
                    contained in the report. When used correctly and regularly,
                    an audit can foster a climate of continuous improvement and
                    help the organisations HR function to mature
                  </p>
                </Contents>
                <ButtonDiv onClick={this.props.requestACall("HR Consulting")}>
                  <RequestButton title="Request a call for HR Consulting"/>
                </ButtonDiv>
              </ContentsContainer>
              {/* 2. HR Consulting Table End */}
              {/* 3. Coaching Table Start */}
              <ContentsContainer>
                <BlueDiv>
                  <div>
                    <img
                      src={Coaching}
                      alt="coaching"
                      style={{ marginBottom: 7 }}
                    />
                    <h3>Coaching</h3>
                  </div>
                </BlueDiv>
                <Contents>
                  Being a Manager or Leader is not just about delegating work,
                  overseeing performance and getting the job done. Today’s
                  successful Managers and Leaders need to be counsellors,
                  negotiators, conflict resolvers, in a nutshell they need to
                  care about their talents career, their overall growth and
                  about them as a person. They inspire, guide and empower their
                  talent.
                  <br />
                  <br />
                  Successful pragmatic Managers and Leaders understand that
                  investing time in developing their talent creates a winning
                  team and builds a strong culture. How does it save money and
                  time? Well it lowers staff turnover saving money on
                  recruitment and training and retains you company knowledgebase
                  within your existing team, saving on time for new people to be
                  truly up to speed.
                  <br />
                  <br />
                  The secret to be a successful pragmatic Manager or Leader is
                  Coaching. Coaching is the difference between telling someone
                  what to do and how to do it and guiding the person through
                  inclusion and empowerment to take ownership and invest
                  themselves in their achievements.
                  <br />
                  <br />
                  So what is Coaching and isn’t that just mentoring spelt
                  different.
                  <br />
                  <br />
                  In the business world, the terms “coaching” and “mentoring”
                  are often used synonymously. But that’s not always the case
                  there is a significant difference between the two.
                  <br />
                  <br />
                  When you hear employees talk about wanting a manager who is a
                  mentor, they’re essentially talking about wanting a manager
                  who is a coach. They want a manager who cares about their
                  development and who challenges them to grow and perform to
                  their best and to function as self-sufficiently as possible.
                  <br />
                  <br />
                  Coaching is a form of development in which a person supports a
                  learner, client or co-worker in achieving a specific personal
                  or professional goal by providing training, guidance giving
                  performance feedback, delegating, motivating employee
                  performance, and so on..
                  <br />
                  <br />
                  Mentoring is a relationship in which a more experienced or
                  more knowledgeable person helps to guide a less experienced or
                  less knowledgeable person. The mentor may be older or younger
                  than the person being mentored, but he or she must have a
                  certain area of expertise. It is a learning and development
                  partnership between someone with vast experience and someone
                  who wants to learn. Mentoring is a significant part of
                  coaching and the set that focuses on guiding employees to do
                  for themselves. Mentoring promotes self-development and
                  self-sufficiency.
                  <br />
                  <br />
                  <b>
                    So Call us today – many great people are backed by Great
                    Coaches
                  </b>
                </Contents>
                <ButtonDiv onClick={this.props.requestACall("Coaching")}>
                  <RequestButton title="Request a call for Coaching"/>
                </ButtonDiv>
              </ContentsContainer>
              {/* 4. Coaching Table End */}
            </Container>
          </Section>
        </div>
      </ThemeProvider>
    );
  }
}
const Section = styled.section`
  background-color: ${Themes.colors.veryLightBlueGrey};
`;
const ButtonDiv = styled.div`
  margin:0 1em;
    @media ${Themes.breakpoints.md} {
   margin: 2em 0 0 0;
  }
`
const ScrollTarget = styled.div`
  height: 20px;
    @media ${Themes.breakpoints.md} {
   height: 47px;
  }
      @media ${Themes.breakpoints.lg} {
   height: 87px;
  }
`;
const TextDiv = styled.div`
  ${FlexVerticalCenter};
  padding: 0 5%;
`;
const Text = styled.p`
  max-width: 1012px;
  margin: 0 auto;
  font-size: ${Themes.fontsize.p2};
  text-align: center;
  font-weight: ${Themes.fontWeight.semiBold};
    @media ${Themes.breakpoints.lg} {
        margin:0 auto;
        line-height:35px;
        font-size:${Themes.fontsize.h4};
    }
`;
const Container = styled.div`
  ${ContainerStyle};
  padding: 0px 3% 47px 3%;
    @media ${Themes.breakpoints.sm} {
    padding:0px 3% 76px 3%;
  }
      @media ${Themes.breakpoints.sm} {
    padding:0px 8% 76px 8%;
  }
  @media ${Themes.breakpoints.xl} {
    padding:0px 0% 76px 0%;
  }
`;
const ContentsContainer = styled.div`
  margin: 10px auto;
  padding-bottom: 30px !important;
    @media ${Themes.breakpoints.sm} {
    margin: 10px auto 30px auto;
  }
    @media ${Themes.breakpoints.md} {
    margin:80px auto 40px auto;
    padding: 2em 2em 2em 320px;
  }
  background: white;
  ${SmallShadow};
  border-radius: 25px;
  ${Paragraph};
  position: relative;
`;
const BlueDiv = styled.div`
  ${PricingTableCommonStyle};
  ${FlexVerticalCenter};
  width: 100%;
  padding: 30px;
  text-align: center;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
    @media ${Themes.breakpoints.md} {
    padding: 40px;
    width: 320px;
    border-radius:25px !important;
  }
  div {
    ${FlexVerticalCenter};
    img {
      width: 62px;
      margin: 0 auto;
    }
    p {
      margin-bottom: 0;
      font-size: ${Themes.fontsize.p2};
      font-weight: ${Themes.fontWeight.extraLight};
    }
  }
  p {
    font-size: ${Themes.fontsize.p2};
    font-weight: ${Themes.fontWeight.medium};
  }
    @media ${Themes.breakpoints.md} {
    position:absolute;
    top:-30px;
    left:-30px;
  }
    @media ${Themes.breakpoints.lg} {
    top:-40px;
    left:-40px;
  }
`;
const Contents = styled.div`
  ${Paragraph};
  b {
    font-weight: ${Themes.fontWeight.semiBold};
  }
  h5 {
    font-weight: ${Themes.fontWeight.bold};
    color: ${Themes.colors.lightBlue};
    margin-bottom: 10px;
  }
  padding: 1.5em 1em;
    @media ${Themes.breakpoints.md} {
    padding: 0;
  }
`;
const Margin = styled.div`
  height: 1.5em;
    @media ${Themes.breakpoints.md} {
      height: 2em;
    }
`;
