import React, { Component } from "react";
import { Modal } from "react-bootstrap";
/** Style **/
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
import {
  FlexVerticalCenter,
  ErrorMessage,
  ValidationErrors
} from "../../Assets/Styles/Style";
/** Image **/
import membershipModal from "../../Assets/Images/membership.svg";
import HSRolePicker from "../Form/HSRolePicker";
import FormEntry from "../Form/FormEntry";
import FormHandler from "../Form/FormHandler";
import App from "../../App";
import { ModalSubmitButton, ModalContent, ModalHeader, HighlightText, ModalBody } from "./styles";
import SuccessModal from "./SuccessModal";
/** Component */

export default class RequestACallModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      hasErrors: false,
      submissionError: false,
      submitting: false,
      show: false
    };
  }

  render() {
    return (
      <ThemeProvider theme={Themes}>
          <Modal
            show={this.state.show}
            onHide={this.onClose}
            bsSize="large"
            aria-labelledby="contained-modal-title-lg">

          <SuccessModal
            onClose={this.successAcknowledged.bind(this)}
            show={this.state.success}
          />

          <ModalContent>
            <ModalHeader closeButton>
              <div>
                <img src={membershipModal} alt="membership" />
                <h5>
                  <HighlightText>Request a call</HighlightText> for more information on our <b style={{fontWeight: "bolder"}}>{this.props.service}</b> Services
                </h5>
              </div>
            </ModalHeader>
            <ModalBody>
              <FormHandler 
                ref="form"
                preLoad={App.Current.UserFormData}
                onSubmit={this.submit.bind(this)} 
                onValid={this.onValid.bind(this)} 
                onInvalid={this.onInvalid.bind(this)}>

                <FormEntry 
                  label="First Name"
                  name="firstname"
                  placeholder="Required"
                  validations="hasValue"
                  validationError={ValidationErrors.required}
                />

                <FormEntry 
                  label="Surname"
                  name="surname"
                  placeholder="Required"
                  validations="hasValue"
                  validationError={ValidationErrors.required}
                />

                <FormEntry
                  label="Email"
                  name="email"
                  placeholder="Required"
                  validations={{
                    hasValue: true,
                    isEmail: true
                  }}
                  validationErrors={{
                    hasValue: ValidationErrors.required,
                    isEmail: ValidationErrors.validEmailAddress
                  }}
                />

                <FormEntry 
                  label="Phone"
                  name="phone"
                  placeholder="Required"
                  validations={{
                    hasValue: true,
                    isPhone: true
                  }}
                  validationErrors={{
                    hasValue: ValidationErrors.required,
                    isPhone: ValidationErrors.validPhoneNumber
                  }}
                />

                <FormEntry 
                  label="Company"
                  name="company"
                  placeholder="Required"
                  validations="hasValue"
                  validationError={ValidationErrors.required}
                />

                <HSRolePicker />

                <div className="flex-center">
                  <ModalSubmitButton disabled={this.state.submitting}>Request a Call</ModalSubmitButton>
                </div>

                {this.state.hasErrors ?
                  <ErrorMessage>
                    {this.state.submissionError ? "An issue occurred while submitting your request. Try again later." : "Please fix the errors above to request a call"}
                  </ErrorMessage>
                :null}
                
              </FormHandler>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ThemeProvider>
    );
  }

  Show = () => {
    this.setState({
      show: true
    });
  }

  onClose = () => {
    this.setState({
      show: false
    });
  }

  onValid(){
    this.setState({
      hasErrors: false
    });
  }

  onInvalid(){
    this.setState({
      hasErrors: true
    });
  }

  async submit(data) {
    try
    {
      this.setState({
        submitting: true
      });

      data["service"] = this.props.service;
      await App.Current.API.Contact.RequestCall(data);
      this.refs.form.reset();

      this.setState({
        success: true,
        hasErrors: false,
        submissionError: false,
        submitting: false
      });
    }
    catch(e){
      this.setState({
        hasErrors: true,
        submissionError: true,
        submitting: false
      });
    }
  }

  successAcknowledged(){
    this.setState({
      success: false
    });
  }
}