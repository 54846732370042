import ServiceBase from "./ServiceBase";
import APIState from "../APIState";

/**
 * Administration Service for the Application.
 * */
export default class AdminService extends ServiceBase {
  /**
    * Creates the Administration Service
    * 
    * @param {APIState} state State Object
    */
  constructor(state) {
    super(state);
  }

  async GetCategorizationInfo(){
    const req = this.CreateRequest(`/admin/categorizationinfo`);
    const response = await this.Send(req);
    return response.json();
  }

  async CreateKnowledgeBaseEntry(data){
    const req = this.CreateFormRequest(`/admin/knowledgebase/add`, data);
    const response = await this.Send(req);
    return response.ok;
  }

  async UpdateKnowledgeBaseEntry(id, data){
    const req = this.CreateFormRequest(`/admin/knowledgebase/${id}`, data);
    const response = await this.Send(req);
    return response.ok;
  }
}