import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
/** Style */
import styled, { ThemeProvider } from "styled-components";

import Themes from "../../Assets/Styles/Themes";
// import adminStyles from "./AdminNavMenu.css";
/** Component */
import Toolbar from "./component/Toolbar/Toolbar";
import SideNav from "./component/SideNav/SideNav";
import Backdrop from "./component/Backdrop/Backdrop";
import RightSideDrawer from "./component/RightSideNav/rightSideDrawer";
import Dashboard from "./scene/Dashboard";
import SmallProfileNav from "./component/SmallProfileNav/smallProfileNav";
/** Image */

class AdminNavMenu extends Component {
  state = {
    sideDrawerOpen: false,
    rightSideDrawerOpen: false,
    smallProfileNavShow: false
  };
  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  rightDrawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { rightSideDrawerOpen: !prevState.rightSideDrawerOpen };
    });
  };
  smallProfileNavToggleClickHandler = () => {
    this.setState(prevState => {
      return { smallProfileNavShow: !prevState.smallProfileNavShow };
    });
  };
  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false, rightSideDrawerOpen: false });
  };
  render() {
    let backdrop;
    let sectionClasses = ""; //if show, push main to right
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
      sectionClasses = "openLeft" //if show, push main to right
    } else if (this.state.rightSideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
      sectionClasses = "openRight" //if show, push main to right
    }
    return (
      <ThemeProvider theme={Themes}>
        <Container>
          <SideNav show={this.state.sideDrawerOpen} />
          <RightSideDrawer show={this.state.rightSideDrawerOpen} />
          {backdrop}
          <SmallProfileNav show={this.state.smallProfileNavShow} />
          <Section className={sectionClasses}>
            <Toolbar
              drawerClickHandler={this.drawerToggleClickHandler}
              rightDrawerClickHandler={this.rightDrawerToggleClickHandler}
              smallProfileNavToggleClickHandler={
                this.smallProfileNavToggleClickHandler
              }
            />
            <Main>
              {this.props.children}                                                                                                                                                                                                                                                                                                                                                                                                                                              
            </Main>
          </Section>
        </Container>
      </ThemeProvider>
      //   <div style={{ height: "100%" }}>
      //   <SideNav show={this.state.sideDrawerOpen} />
      //   <RightSideDrawer show={this.state.rightSideDrawerOpen} />
      //   {backdrop}
      //   <section className={sectionClasses}>
      //     <Toolbar
      //       drawerClickHandler={this.drawerToggleClickHandler}
      //       rightDrawerClickHandler={this.rightDrawerToggleClickHandler}
      //       smallProfileNavToggleClickHandler={this.smallProfileNavToggleClickHandler}
      //       />
      //     <SmallProfileNav show={this.state.smallProfileNavShow}/>
      //     <main className="main"><Dashboard /></main>
      //   </section>
      // </div>
    );
  }
}

const Container = styled.div`
  height: 100%;
  .openLeft {
    transform: translateX(248px);
  }
  .openRight {
    transform: translateX(-248px);
      @media ${Themes.breakpoints.sm} {
    transform: translateX(0);
  }
  }
`;
const Section = styled.section`
  transform: translateX(0px);
  transition: transform 0.3s;
  background-color: ${Themes.colors.veryLightBlueGrey};
    @media ${Themes.breakpoints.sm} {
    margin-left: 248px; 
  }
`;
const Main = styled.main`
  /* width: 100%; */
  padding: 5%;
    @media ${Themes.breakpoints.sm} {
    /* padding: 50px 5%; */
  }
`;
export default withRouter(AdminNavMenu);
